import { ChevronRight } from "lucide-react";

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import { SidebarMenuButton, SidebarMenuSub } from "src/@/components/ui/sidebar";
import { NavMenuSubItems } from "./nav-menu-sub-items";
import { isUrlActive, hasActiveNestedRoute } from "../utils";
import { NavButtonContent } from "./nav-button-content";
import { RouteItem } from "../../types";
import { NavButton } from "#navigation/sidebar/components/nav-button";
import { useMemo, useState, useEffect } from "react";
import { cn } from "src/@/lib/utils";

function useHasActiveSubItem(items: RouteItem[] | undefined, pathname: string) {
    return useMemo(
        () => hasActiveNestedRoute(items, pathname),
        [items, pathname],
    );
}

interface TopLevelNavItemProps {
    item: RouteItem;
    pathname: string;
}

export function TopLevelNavItem({ item, pathname }: TopLevelNavItemProps) {
    const hasActiveSubItem = useHasActiveSubItem(item.items, pathname);
    const [isSubOpen, setIsSubOpen] = useState(hasActiveSubItem);
    const isActive = isUrlActive(pathname, item.url || "");

    useEffect(() => {
        if (hasActiveSubItem) {
            setIsSubOpen(true);
        }
    }, [hasActiveSubItem]);

    if (!item.items) {
        return <NavButton item={item} />;
    }

    return (
        <Collapsible
            open={isSubOpen}
            onOpenChange={setIsSubOpen}
            className={cn(
                "group/menu-item w-full",
                isActive && "bg-accent/50 rounded-md",
            )}
        >
            <CollapsibleTrigger asChild>
                <SidebarMenuButton
                    tooltip={item.title}
                    className={cn(isActive && "text-primary font-medium")}
                >
                    <NavButtonContent item={item} />
                    <ChevronRight
                        className={cn(
                            "ml-auto size-4 shrink-0 transition-transform duration-200 group-data-[state=open]/menu-item:rotate-90",
                            isActive ? "text-primary" : "text-muted-foreground",
                        )}
                    />
                </SidebarMenuButton>
            </CollapsibleTrigger>
            <CollapsibleContent className="relative overflow-hidden">
                <SidebarMenuSub>
                    <NavMenuSubItems items={item.items} />
                </SidebarMenuSub>
            </CollapsibleContent>
        </Collapsible>
    );
}
