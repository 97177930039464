import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import config from "#config";
import { getUser } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";

export function useIdentify() {
    const user = useSelector(getUser);

    useEffect(() => {
        initSegmentAnalytics();
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }

        Sentry.configureScope((scope) => {
            scope.setUser({
                email: user.email,
                id: user.uid || "",
                uid: user.uid,
                name: user.name,
            });
        });

        window.Intercom("boot", {
            app_id: "z0np13lu",
            email: user.email,
            user_id: user.uid,
            user_hash: user.intercomHash,
            name: user.name,
            "Restaurant Dashboard User": true,
            hide_default_launcher: true,
        });
        if (!user.intercomHash) console.warn("No intercom hash found for user");
        try {
            window.analytics?.identify(user.uid || "", {
                email: user.email,
                id: user._id,
                uid: user.uid,
                name: user.name,
            });
        } catch (e) {
            logAndSendError("Error identifying user with Segment" + e);
        }
    }, [user?.uid]);
}

// the majority of this method was copy and pasted from segment
// documentation; it was moved from its normal place in the public
// html because of the added flexibility of using a custom
// and independent development data pipeline
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet
function initSegmentAnalytics() {
    try {
        const API_KEY = config.PRODUCTION_SEGMENT_API_KEY;

        const analytics: any = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
            if (analytics.invoked)
                window.console &&
                    logAndSendError &&
                    logAndSendError("Segment snippet included twice.");
            else {
                analytics.invoked = !0;
                analytics.methods = [
                    "trackSubmit",
                    "trackClick",
                    "trackLink",
                    "trackForm",
                    "pageview",
                    "identify",
                    "reset",
                    "group",
                    "track",
                    "ready",
                    "alias",
                    "debug",
                    "page",
                    "once",
                    "off",
                    "on",
                ];
                analytics.factory = function (t: any) {
                    return function () {
                        const e = Array.prototype.slice.call(arguments);
                        e.unshift(t);
                        analytics.push(e);
                        return analytics;
                    };
                };
                for (let t = 0; t < analytics.methods.length; t++) {
                    const e = analytics.methods[t];
                    analytics[e] = analytics.factory(e);
                }
                analytics.load = function (t: any, e: any) {
                    const n = document.createElement("script");
                    n.type = "text/javascript";
                    n.async = !0;
                    n.src =
                        "https://cdn.segment.com/analytics.js/v1/" +
                        t +
                        "/analytics.min.js";
                    const a = document.getElementsByTagName("script")[0];
                    a.parentNode?.insertBefore(n, a);
                    analytics._loadOptions = e;
                };
                analytics.SNIPPET_VERSION = "4.1.0";
                analytics.load(API_KEY);
                analytics.page();
            }
    } catch (e) {
        logAndSendError("Error initializing Segment" + e);
    }
}
