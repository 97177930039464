import { getActiveStore } from "@snackpass/accounting";
import {
    PaymentProvider,
    PurchaseReportData,
    PurchaseReportTransactionSource,
    PurchaseSummaryData,
    ThirdPartySource,
    FulfillmentTypeEnum,
} from "@snackpass/snackpass-types";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { Moment } from "moment-timezone";
import { client } from "src/api/rest/client";
import { getUserTeamPermission } from "src/redux/selectors/user";
import { ErrorWithCause } from "src/utils/errors";
import { withoutTime } from "src/utils/without-time";
import { getPaymentMethods, PaymentDefinition } from "#payment-type-picker/lib";

type CombinedPurchaseReportData = {
    rows?: PurchaseReportData[];
    days: PurchaseSummaryData[];
    payouts?: PurchaseSummaryData[];
    weeks: PurchaseSummaryData[];
    months: PurchaseSummaryData[];
    total: PurchaseSummaryData;
};

type PurchaseReportParams = {
    storeId: string;
    since: Date | null;
    until: Date | null;
    timezone?: string;
    includeActualPayout: boolean;
    channels?: PurchaseReportTransactionSource[];
    providers?: ThirdPartySource[];
    paymentMethods?: PaymentProvider[];
    fulfillments?: FulfillmentTypeEnum[];
    noRows?: boolean;
    onlyRows?: boolean;
    onlyCatering?: boolean;
    includePurchase?: boolean;
};

export const MAX_DATE_RANGE = 10;

const fetchPurchaseReport = async (
    params: PurchaseReportParams,
): Promise<CombinedPurchaseReportData> => {
    if (params.since && params.until) {
        const daysDiff = differenceInDays(params.until, params.since);
        if (daysDiff > MAX_DATE_RANGE) {
            throw new Error(
                `Please use Essentials Report for date ranges longer than ${MAX_DATE_RANGE} days`,
            );
        }
    }

    return client
        .get<CombinedPurchaseReportData>(
            `/reports/store/${params.storeId}/combined-purchase-report`,
            { params },
        )
        .then((res) => res.data)
        .catch((cause) => {
            throw new ErrorWithCause(
                "Failed to fetch combined purchase report data",
                cause,
            );
        });
};

export const usePurchaseReport = (
    startDate: Moment,
    endDate: Moment,
    options: {
        channels?: PurchaseReportTransactionSource[];
        providers?: ThirdPartySource[];
        paymentMethods?: PaymentDefinition[];
        fulfillments?: FulfillmentTypeEnum[];
        noRows?: boolean;
        onlyRows?: boolean;
        refunded?: boolean;
        includePurchase?: boolean;
    } = {},
) => {
    const store = useSelector(getActiveStore);
    const includeActualPayout = useSelector(getUserTeamPermission);
    const params = useMemo(
        () => ({
            storeId: store?._id ?? "not-set",
            since: withoutTime(startDate.startOf("day").toDate()),
            until: withoutTime(endDate.endOf("day").toDate()),
            includeActualPayout,
            transactionSources: options.channels,
            transactionChannels: options.providers,
            paymentProviders: getPaymentMethods(options.paymentMethods ?? []),
            fulfillments: options.fulfillments,
            noRows: options.noRows,
            onlyRows: options.onlyRows,
            includePurchase: options.includePurchase,
            refunded: options.refunded,
        }),
        [
            store?._id,
            startDate,
            endDate,
            includeActualPayout,
            options.channels,
            options.providers,
            options.paymentMethods,
            options.fulfillments,
            options.noRows,
            options.onlyRows,
            options.includePurchase,
            options.refunded,
        ],
    );
    return useQuery({
        queryKey: ["purchaseReport", params],
        queryFn: async () => fetchPurchaseReport(params),
        retry: false,
    });
};
