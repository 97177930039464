import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { UserInvite } from "src/core/types";
import { Routes } from "#navigation/routes";

import { getActiveStore, getActiveStoreId, getUser } from "src/redux/selectors";

/**
 * Now that different report submenus have their own permissions
 * we need to be careful about which path we send a user to when they first click on
 * 'reports' because if we send them to a path they don't have access to they won't get to see
 * the whole menu and will be unable to choose any others
 *
 * @param hasMultipleAdminStores
 * @returns Which of the many reports routes to choose
 */
export const useDefaultReportsRoute = (hasMultipleAdminStores: boolean) => {
    const user = useSelector(getUser);
    const activeStore = useSelector(getActiveStore);

    const hasPermission = useCallback(
        (storeIds: string[] | undefined): boolean => {
            if (!storeIds || !storeIds.length || !activeStore?._id) {
                return false;
            }
            return storeIds.includes(activeStore?._id ?? "");
        },
        [activeStore?._id],
    );

    // setup our default
    let route = Routes.ReportsSalesSummary;

    if (!activeStore?._id) return route;
    if (user?.snackpassPermissions.isSnackpassEmployee) return route;
    if (!user?.permissions) return route;

    // order is important! We walk through reports from bottom to top to select
    // the top most report they can see based on their permissions

    if (hasPermission(user.permissions.hasReportsGiftCards)) {
        route = Routes.ReportsGiftCardPurchases;
    }
    if (hasPermission(user.permissions.hasReportsPromotions)) {
        route = Routes.ReportsPromotions;
    }
    if (hasPermission(user.permissions.hasReportsCustomers)) {
        route = Routes.ReportsCustomerDirectoryInsights;
    }
    if (hasPermission(user.permissions.hasReportsMenu)) {
        route = Routes.ReportsMenuItemInsights;
    }
    if (hasPermission(user.permissions.hasSalesReports)) {
        route = Routes.ReportsSalesSummary;
    }
    if (
        hasPermission(user.permissions.hasReportsLocations) &&
        hasMultipleAdminStores
    ) {
        route = Routes.ReportsSalesSummary;
    }

    return route;
};

export const useInvites = () => {
    const activeStoreId = useSelector(getActiveStoreId);
    const [pendingInvites, setPendingInvites] = useState<UserInvite[]>([]);

    const fetchInvites = async (storeId?: string) => {
        if (!storeId) {
            return;
        }
        try {
            const invites = await api.adminUserInvite.getInvites(storeId);
            setPendingInvites(invites.data.invites);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        void fetchInvites(activeStoreId);
    }, [activeStoreId]);

    return {
        pendingInvites,
    };
};
