import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import api from "src/api/rest";

import { firebaseAuth } from "#app/firebase";
import { useAppDispatch } from "src/redux/hooks";
import { clearUser, setUser } from "src/redux/slices";
import { clearPersistedActiveStoreId } from "#hooks/initial/use-persist-store-id";

// signinPreload speeds up loading by ~250ms
// so we fetch twice. one at preload and one here
export function useFirebaseAuth(): { isInitialized: boolean } {
    const dispatch = useAppDispatch();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (updatedUser) => {
                if (!updatedUser) {
                    dispatch(clearUser());
                    clearPersistedActiveStoreId();
                    setIsInitialized(true);
                    return;
                }
                try {
                    // This is needed because the user token on the axios
                    // client doesn't get applied quick enough
                    const idToken = await updatedUser?.getIdToken();
                    const response = await api.users.getMe(idToken);
                    const { user, hash } = response.data;
                    dispatch(setUser({ ...user, intercomHash: hash }));
                    setIsInitialized(true);
                } catch (e) {
                    console.log("Error fetching user: ", e);
                    setIsInitialized(true);
                    throw e;
                }
            },
        );
        return unsubscribe;
    }, [dispatch]);

    return { isInitialized };
}
