import { Cell, Legend, Pie, PieChart } from "recharts";
import { ChartContainer, ChartTooltip } from "src/@/components/ui/chart";
import { ChartCard } from "./chart-card";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { Routes } from "#navigation/routes";
import { CategoryData } from "#dashboard/hooks/use-query-top-items";

interface CategoryPieChartProps {
    categoryData: CategoryData[];
    isLoading: boolean;
}

const CATEGORY_COLORS = [
    "#60A5FA", // blue
    "#F59E0B", // yellow
    "#8B5CF6", // purple
    "#F97316", // orange
    "#10B981", // green
];

const chartConfig = {
    total: {
        label: "Category Revenue",
        color: "hsl(var(--primary))",
    },
} as const;

const MAX_CHARS_IN_CATEGORY_NAME = 10;
// Add this function before the CategoryPieChart component
const shortenCategoryName = (name: string): string => {
    return name.length > MAX_CHARS_IN_CATEGORY_NAME
        ? name.substring(0, MAX_CHARS_IN_CATEGORY_NAME) + ".."
        : name;
};

const MAX_CATEGORIES_IN_LEGEND_FULL_NAME = 6;

export const CategoryPieChart = ({
    categoryData,
    isLoading,
}: CategoryPieChartProps) => {
    const { currentPeriodStartEnd } = useDashboardContext();

    const startDate = currentPeriodStartEnd?.startDate?.format("MM/DD/YY");
    const endDate = currentPeriodStartEnd?.endDate?.format("MM/DD/YY");

    // Calculate total revenue and percentages
    const total = categoryData.reduce((sum, item) => sum + item.value, 0);
    const dataWithPercentages = categoryData.map((item, index) => ({
        ...item,
        percent: ((item.value / total) * 100).toFixed(1),
        index,
    }));

    return (
        <ChartCard
            title="Sales by Category"
            description={
                startDate && endDate ? `${startDate} - ${endDate}` : undefined
            }
            to={Routes.ReportsMenuItemInsights}
            isLoading={isLoading}
        >
            <ChartContainer config={chartConfig}>
                <PieChart
                    margin={{
                        top: 0,
                        right: 0,
                        bottom: 30, // Added margin for legend
                        left: 0,
                    }}
                >
                    <Pie
                        data={dataWithPercentages}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={2}
                    >
                        {dataWithPercentages.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    CATEGORY_COLORS[
                                        index % CATEGORY_COLORS.length
                                    ]
                                }
                            />
                        ))}
                    </Pie>
                    <Legend
                        verticalAlign="bottom"
                        align="center"
                        layout="horizontal"
                        formatter={(value, entry: any) => {
                            const item = dataWithPercentages.find(
                                (d) => d.name === value,
                            );
                            if (
                                item?.index &&
                                item.index > MAX_CATEGORIES_IN_LEGEND_FULL_NAME
                            ) {
                                return value.substring(0, 5) + ".";
                            }
                            return `${shortenCategoryName(value)} (${item?.percent}%)`;
                        }}
                    />
                    <ChartTooltip
                        content={({ active, payload }) => {
                            if (!active || !payload?.length) return null;
                            const data = payload[0].payload;
                            return (
                                <div className="space-y-2 rounded-lg border bg-white p-2 shadow-md">
                                    <div className="font-medium">
                                        {data.name}
                                    </div>
                                    <div className="text-muted-foreground">
                                        $
                                        {data.value.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}{" "}
                                        revenue
                                    </div>
                                    <div className="text-muted-foreground">
                                        {data.percent}% of total
                                    </div>
                                </div>
                            );
                        }}
                    />
                </PieChart>
            </ChartContainer>
        </ChartCard>
    );
};
