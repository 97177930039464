import { Routes } from "#navigation/routes";
import {
    MessageSquare,
    Tag,
    Store,
    Video,
    Globe,
    BarChart2,
    Users,
    HelpCircle,
    Pause,
    Clock,
    DollarSign,
    Award,
    Printer,
} from "lucide-react";

export enum QuickShortcutIcon {
    Campaign = "campaign",
    Promo = "promo",
    Menu = "menu",
    OnlineOrdering = "online-ordering",
    Website = "website",
    MenuInsights = "menu-insights",
    TeamMembers = "team-members",
    Support = "support",
    PauseOrders = "pause-orders",
    SpecialHours = "special-hours",
    Deposits = "deposits",
    Loyalty = "loyalty",
    PrepStations = "prep-stations",
}

export const iconComponents = {
    [QuickShortcutIcon.Campaign]: MessageSquare,
    [QuickShortcutIcon.Promo]: Tag,
    [QuickShortcutIcon.Menu]: Store,
    [QuickShortcutIcon.OnlineOrdering]: Video,
    [QuickShortcutIcon.Website]: Globe,
    [QuickShortcutIcon.MenuInsights]: BarChart2,
    [QuickShortcutIcon.TeamMembers]: Users,
    [QuickShortcutIcon.Support]: HelpCircle,
    [QuickShortcutIcon.PauseOrders]: Pause,
    [QuickShortcutIcon.SpecialHours]: Clock,
    [QuickShortcutIcon.Deposits]: DollarSign,
    [QuickShortcutIcon.Loyalty]: Award,
    [QuickShortcutIcon.PrepStations]: Printer,
};

export interface QuickShortcutData {
    id: string;
    title: string;
    link: string;
    icon: QuickShortcutIcon;
}

export const quickShortcuts: QuickShortcutData[] = [
    {
        id: "invite-admin",
        title: "Invite Team Members",
        link: "/users",
        icon: QuickShortcutIcon.TeamMembers,
    },
    {
        id: "support-center",
        title: "Support Center",
        link: "https://support.snackpass.co/en/",
        icon: QuickShortcutIcon.Support,
    },
    {
        id: "loyalty-rewards",
        title: "Set Up Loyalty",
        link: Routes.Promotion,
        icon: QuickShortcutIcon.Loyalty,
    },
    {
        id: "kiosk-splash",
        title: "Kiosk Splash Video",
        link: Routes.SettingsBrandAndAppearance,
        icon: QuickShortcutIcon.OnlineOrdering,
    },
    {
        id: "create-promo",
        title: "Create a Promo",
        link: Routes.Promotion,
        icon: QuickShortcutIcon.Promo,
    },
    {
        id: "pause-store",
        title: "Pause Online Orders",
        link: Routes.BetaOrders,
        icon: QuickShortcutIcon.PauseOrders,
    },
    {
        id: "special-hours",
        title: "Add Special Hours",
        link: Routes.SettingsBusinessInfo,
        icon: QuickShortcutIcon.SpecialHours,
    },
    {
        id: "deposits",
        title: "View Deposits",
        link: Routes.FinancialReportsPayoutHistory,
        icon: QuickShortcutIcon.Deposits,
    },
    {
        id: "menu-insights",
        title: "Menu Insights",
        link: Routes.ReportsMenuItemInsights,
        icon: QuickShortcutIcon.MenuInsights,
    },
    {
        id: "prep-stations",
        title: "Create Prep Stations",
        link: Routes.PrepStations,
        icon: QuickShortcutIcon.PrepStations,
    },
];
