import { signInWithCustomToken } from "firebase/auth";
import api from "src/api/rest";
import { firebaseAuth } from "#app/firebase";
import { setMfaPrecheck } from "src/redux/slices";
import { store } from "src/redux/store";

// captured on page load
const query = new URLSearchParams(window.location.search);
const authToken = query.get("authToken");

/**
 * This function is called on page load to sign in the
 * user if an `authToken` is present in the querystring.
 * Currently, this is used by the partner app.
 *
 * It also calls into mfa verification early to bypass
 * multiple loading screens.
 */
// sign in preload speeds up loading by ~250ms
export async function signinPreload() {
    if (authToken) {
        await signInWithCustomToken(firebaseAuth, authToken);
    }

    try {
        await api.users.checkVerification();
        store.dispatch(setMfaPrecheck(true));
        return true;
    } catch (error) {
        // if the user is not verified, we will show the sign in screen
    }
    return false;
}
