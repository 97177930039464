import { useEffect } from "react";

import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import { SegmentEvents } from "#utils/segment";

export const WhatsNew = () => {
    const trackEvent = useTrackEvent();

    useEffect(() => {
        trackEvent(SegmentEvents.VISITED_WHATSNEW_SCREEN);
    }, []);

    /* We are using -48px here to subtract the height of the top nav bar. We cannot use h-full in this instance, because the height of
       the content div is not fixed. */
    return (
        <iframe
            className="flex h-screen w-full border-none"
            src="https://www.snackpass.co/updates"
            title="what's New"
        />
    );
};
