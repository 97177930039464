import * as React from "react";
import { twMerge } from "tailwind-merge";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, ...props }, ref) => (
        <input
            type={type}
            className={twMerge(
                "flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-small shadow-sm transition-colors file:border-0 file:bg-transparent file:text-small file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            ref={ref}
            {...props}
        />
    ),
);
Input.displayName = "Input";

// This component wraps the Input in a className relative to pull off absolute positioning. Because this
// may not always be desired its a separate component.
const CharacterCounterInput = React.forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => (
        <div className="relative">
            <Input {...props} ref={ref} />
            <div className="pointer-events-none absolute bottom-2 right-2 text-sm text-gray-500">
                {`${
                    (typeof props.value === "string" ||
                    typeof props.value === "number"
                        ? props.value.toString().length
                        : null) ?? 0
                }${props.maxLength ? `/${props.maxLength}` : ""}`}
            </div>
        </div>
    ),
);

export { Input, CharacterCounterInput };
