import * as React from "react";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";

import { cn } from "src/@/lib/utils";
import { Badge } from "src/@/components/ui/badge";
import { Button } from "src/@/components/ui/button";
import { Separator } from "src/@/components/ui/separator";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "src/@/components/ui/command";

interface FilterSelectProps {
    title?: string;
    subtitle?: string;
    search?: boolean;
    customButton?: JSX.Element;
    selectedValues: Set<string>;
    options: {
        label: string | JSX.Element;
        value: string;
        icon?: React.ComponentType<{ className?: string }>;
        description?: string;
        onClickOverride?: () => void;
        selectedOverride?: boolean;
    }[];
    showSelected?: boolean;
    onOptionSelected: (value: string) => void;
    onClearOptions?: () => void;
    onOpenChange?: (open: boolean) => void;
    open?: boolean;
    contentClassName?: string;
}

/**
 * @deprecated Use FilterSelect2 instead
 */
export function FilterSelect({
    title,
    subtitle,
    search,
    options,
    selectedValues,
    onOptionSelected,
    onClearOptions,
    open,
    onOpenChange,
    contentClassName,
    customButton,
}: FilterSelectProps) {
    return (
        <Popover onOpenChange={onOpenChange} open={open}>
            <PopoverTrigger asChild>
                {customButton ? (
                    customButton
                ) : (
                    <Button
                        variant="outline"
                        size="sm"
                        className="h-8 border-dashed"
                    >
                        <PlusCircledIcon className="mr-2 h-4 w-4" />
                        {title}
                        {selectedValues?.size > 0 && (
                            <>
                                <Separator
                                    orientation="vertical"
                                    className="mx-2 h-4"
                                />
                                <Badge
                                    variant="secondary"
                                    className="rounded-sm px-1 font-normal lg:hidden"
                                >
                                    {selectedValues.size}
                                </Badge>
                                <div className="hidden space-x-1 lg:flex">
                                    {selectedValues.size > 2 ? (
                                        <Badge
                                            variant="secondary"
                                            className="rounded-sm px-1 font-normal"
                                        >
                                            {selectedValues.size} selected
                                        </Badge>
                                    ) : (
                                        options
                                            .filter((option) =>
                                                selectedValues.has(
                                                    option.value,
                                                ),
                                            )
                                            .map((option) => (
                                                <Badge
                                                    variant="secondary"
                                                    key={option.value}
                                                    className="rounded-sm px-1 font-normal"
                                                >
                                                    {option.label}
                                                </Badge>
                                            ))
                                    )}
                                </div>
                            </>
                        )}
                    </Button>
                )}
            </PopoverTrigger>
            <PopoverContent
                className={cn("w-[200px] p-0", contentClassName)}
                align="start"
            >
                <Command>
                    {search ? (
                        <CommandInput placeholder={title} />
                    ) : (
                        <div className="flex w-full rounded-md bg-transparent p-2 text-sm font-semibold outline-none flex-col">
                            <p>{title}</p>
                            {subtitle && (
                                <p className="text-muted-foreground">
                                    {subtitle}
                                </p>
                            )}
                        </div>
                    )}
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => {
                                const isSelected =
                                    selectedValues.has(option.value) ||
                                    option.selectedOverride;
                                return (
                                    <CommandItem
                                        key={option.value}
                                        onSelect={() => {
                                            if (option.onClickOverride) {
                                                option.onClickOverride();
                                            } else {
                                                onOptionSelected(option.value);
                                            }
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                isSelected
                                                    ? "bg-primary text-primary-foreground"
                                                    : "opacity-50 [&_svg]:invisible",
                                            )}
                                        >
                                            <CheckIcon
                                                className={cn("h-4 w-4")}
                                            />
                                        </div>
                                        {option.icon && (
                                            <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                                        )}
                                        <span>{option.label}</span>
                                        {option.description ? (
                                            <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-micro">
                                                {option.description}
                                            </span>
                                        ) : null}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                        {selectedValues.size > 0 && !!onClearOptions ? (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => {
                                            onClearOptions();
                                        }}
                                        className="justify-center text-center"
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        ) : null}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
