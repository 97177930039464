/** @jsxImportSource @emotion/react */
import { toDollar } from "@snackpass/accounting";
import React from "react";

import { replaceZeros } from "#utils/helpers";

type Amount = { amount: number; isDollar: boolean };

export const Amount = ({ amount, isDollar }: Amount) => {
    if (isDollar) {
        return <div>{toDollar(amount)}</div>;
    }
    return <div>{replaceZeros(amount, false, "—")}</div>;
};
