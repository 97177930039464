import { LucideIcon } from "lucide-react";
import { Routes } from "#navigation/routes";

export enum RouteOnClick {
    GlobalSearch = "globalSearch",
    Intercom = "intercom",
}

// Helper type to get the base URL from a route (removes # if present)
type BaseUrl<T extends Routes> = T extends `#${infer Rest}` ? Rest : T;

// Helper type to validate subpage URLs
// e.g. /campaigns/push/create is a valid subpage of /campaigns/push
type ValidateSubpageUrl<
    ParentUrl extends Routes,
    SubpageUrl extends Routes,
> = SubpageUrl extends `${BaseUrl<ParentUrl>}${string}` ? SubpageUrl : never;

// If the route is navigation only, the URL must start with #
type ValidateNavigationOnlyUrl<
    T extends Routes,
    IsNavOnly extends boolean | undefined,
> = IsNavOnly extends true ? (T extends `#${string}` ? T : never) : T;

export interface RouteItem {
    /** Display name of the route in the sidebar and breadcrumb */
    title: string;

    /** Optional Lucide icon to show in the sidebar */
    icon?: LucideIcon;

    /** The route enum value from Routes. If isNavigationOnly is true, must start with # */
    url: ValidateNavigationOnlyUrl<Routes, RouteItem["isNavigationOnly"]>;

    /** Optional array of keywords to help with search functionality */
    searchKeywords?: string[];

    /** Optional flag to mark route as beta feature */
    isBeta?: boolean;

    /** Optional click handler override */
    onClick?: RouteOnClick;

    /** Optional array of nested route items for dropdown menus */
    items?: RouteItem[];

    /**
     * Optional array of child routes that shouldn't appear in sidebar but maintain parent highlighting.
     * The subpage URL must append to the parent URL (e.g., `/campaigns/push/create` for parent `/campaigns/push`)
     */
    nonSidebarSubpages?: ValidateSubpageUrl<RouteItem["url"], Routes>[];

    /**
     * Optional StorePermission key required to access the route.
     * If not set, everyone has permission to access the route.
     * For internal routes, use the INTERNAL_ROUTES array instead.
     */
    permission?: keyof StorePermissions;

    /** Optional external URL string or function to generate URL */
    externalUrl?: string | ((slug: string) => string);

    /**
     * Optional flag to indicate this is just for navigation grouping for sidebar and not a real route.
     * When true, the route must start with # (enforced by ValidateNavigationOnlyUrl)
     */
    isNavigationOnly?: boolean;
}

export type SidebarSection = {
    key: string;
    label?: string;
    defaultOpen?: boolean;
    items: readonly RouteItem[];
};

export enum StorePermission {
    IsOwner = "isOwner",
    HasDashboard = "hasDashboard",
    HasOrders = "hasOrders",
    HasSalesReports = "hasSalesReports",
    HasReportsMenu = "hasReportsMenu",
    HasReportsCustomers = "hasReportsCustomers",
    HasReportsPromotions = "hasReportsPromotions",
    HasReportsGiftCards = "hasReportsGiftCards",
    HasFinancialReports = "hasFinancialReports",
    HasReportsLocations = "hasReportsLocations",
    HasReportsLabor = "hasReportsLabor",
    HasPromos = "hasPromos",
    HasMenuEditing = "hasMenuEditing",
    HasSettings = "hasSettings",
    HasEmployees = "hasEmployees",
    HasGuestbook = "hasGuestbook",
    HasBilling = "hasBilling",
    HasPayouts = "hasPayouts",
    HasDevices = "hasDevices",

    HasAnalytics = "hasAnalytics",
    HasBillingWrite = "hasBillingWrite",
    HasCatering = "hasCatering",
    HasEditAdmin = "hasEditAdmin",
    HasPayroll = "hasPayroll",
    HasSettingsWrite = "hasSettingsWrite",
    HasTopCustomers = "hasTopCustomers",
}

export type StorePermissions = {
    [key in StorePermission]?: string[];
};
