import { Link as RouterLink } from "react-router-dom";
import { cn } from "src/@/lib/utils";

export interface LinkProps {
    to: string;
    className?: string;
    children: React.ReactNode;
}

export const Link = ({ to, className, children }: LinkProps) => {
    return (
        <RouterLink to={to} className={cn("font-medium underline", className)}>
            {children}
        </RouterLink>
    );
};
