import React from "react";
import { useSelector } from "react-redux";
import { StatsigProvider } from "statsig-react";

import config from "#config";
import { getActiveStore, getUser } from "src/redux/selectors";

type StatsigReduxProviderProps = {
    children: React.ReactElement;
};

export const StatsigReduxProvider = ({
    children,
}: StatsigReduxProviderProps) => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    return (
        <StatsigProvider
            sdkKey={config.STATSIG_KEY || ""}
            mountKey={user?._id}
            user={{
                userID: user?._id,
                email: user?.email,
                customIDs: {
                    storeID: store?._id || "",
                },
            }}
            waitForInitialization
            options={{
                environment: { tier: config.STATSIG_ENVIRONMENT },
            }}
        >
            {children}
        </StatsigProvider>
    );
};
