import { createAction } from "@reduxjs/toolkit";
import {
    Addon,
    AddonGroup,
    IHoursSchema,
    IProduct,
    MenuPlatform,
    MenuPriceAdjustment,
    MenuType,
    MenuOverrides,
    ProductOverrideFields,
} from "@snackpass/snackpass-types";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";

const selectMultiMenu = createAction<{ id: string }>("SELECT_MULTIMENU");

const resetCurrentMenu = createAction("RESET_MULTIMENU");

const selectCategory = createAction<{
    categoryWithProducts: IProductCategoryWithProducts;
}>("SELECT_CATEGORY");

const discardCategory = createAction<{
    categoryWithProducts: IProductCategoryWithProducts;
}>("DISCARD_CATEGORY");

const selectProduct = createAction<{ product: IProduct; categoryId: string }>(
    "SELECT_PRODUCT",
);
const discardProduct = createAction<{ product: IProduct; categoryId: string }>(
    "DISCARD_PRODUCT",
);
const selectAddonGroup = createAction<{
    addonGroup: AddonGroup;
    product: IProduct;
    categoryId: string;
}>("SELECT_ADDON_GROUP");

const discardAddonGroup = createAction<{
    addonGroup: AddonGroup;
    product: IProduct;
    categoryId: string;
}>("DISCARD_ADDON_GROUP");

const selectAddon = createAction<{
    addon: Addon;
    addonGroup: AddonGroup;
    product: IProduct;
    categoryId: string;
}>("SELECT_ADDON");

const discardAddon = createAction<{
    addon: Addon;
    addonGroup: AddonGroup;
    product: IProduct;
    categoryId: string;
}>("DISCARD_ADDON");

const setMultiMenuName = createAction<{ name: string }>("SET_MENU_NAME");

const activeMenu = createAction<{ enabled: boolean }>("ENABLE_MENU");

const setMultiMenuTime = createAction<{ hours: IHoursSchema }>(
    "SET_MENU_HOURS",
);

const setMultiMenuType = createAction<{ type: MenuType }>("SET_MENU_TYPE");

const setMultiMenuPlatform = createAction<{
    platforms: MenuPlatform[];
}>("SET_MENU_PLATFORM");

const setMultiMenuPriceAdjustment = createAction<{
    priceAdjustment: MenuPriceAdjustment;
}>("SET_MENU_PRICE_ADJUSTMENT");

const setPriceOverride = createAction<{
    itemType: Exclude<keyof MenuOverrides, "addonGroups">;
    itemId: string;
    price: number | null;
}>("SET_OVERRIDE");

const setTaxOverride = createAction<{
    itemId: string;
    taxes: ProductOverrideFields["taxes"];
}>("SET_TAX_OVERRIDE");

const moveCategory = createAction<{
    oldPlace: number;
    newPlace: number;
}>("MOVE_CATEGORY");

const moveProduct = createAction<{
    categoryId: string;
    oldPlace: number;
    newPlace: number;
}>("MOVE_PRODUCT");

const moveAddon = createAction<{
    addonGroupId: string;
    oldPlace: number;
    newPlace: number;
}>("MOVE_ADDON");

const resetMenuItems = createAction("RESET_MENU_ITEMS");

const resetLoadingUpdateOverrides = createAction("RESET_OVERRIDE_LOADING");

export const multiMenuActions = {
    selectMultiMenu,
    resetCurrentMenu,
    selectCategory,
    discardCategory,
    selectProduct,
    discardProduct,
    selectAddonGroup,
    discardAddonGroup,
    selectAddon,
    discardAddon,
    setMultiMenuName,
    activeMenu,
    setMultiMenuTime,
    setMultiMenuType,
    setMultiMenuPlatform,
    setMultiMenuPriceAdjustment,
    setPriceOverride,
    setTaxOverride,
    moveCategory,
    moveProduct,
    moveAddon,
    resetMenuItems,
    resetLoadingUpdateOverrides,
};
