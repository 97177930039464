import { Granularity } from "../../types";
import { useDashboardContext } from "../../hooks/use-dashboard-context";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";

type GranularityOption = {
    value: Granularity;
    label: string;
};

export const DashboardGranularityPicker = () => {
    const { granularity, setGranularity } = useDashboardContext();

    const options: GranularityOption[] = [
        { value: Granularity.DAY, label: "Daily" },
        { value: Granularity.WEEK, label: "Weekly" },
        { value: Granularity.MONTH, label: "Monthly" },
        { value: Granularity.YEAR, label: "Yearly" },
    ];

    return (
        <FilterSelect
            selectedValues={new Set([granularity])}
            customButton={
                <Button variant="outline" size="sm">
                    {options.find((opt) => opt.value === granularity)?.label}
                </Button>
            }
            title={"Granularity"}
            onOptionSelected={(value) => setGranularity(value as Granularity)}
            options={options}
        />
    );
};
