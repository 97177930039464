import { Moment } from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getStoreTimezone } from "#utils/helpers";

import { getActiveStore } from "../../redux/selectors";
import { genHash } from "../core/utils";

type TimeRange = {
    startDate: Moment;
    endDate: Moment;
};

/**
 * accepts a start and end date and returns those dates but normalized
 * to the start / end of the time-range wrt to the store's timezone
 */
export const useStoreTZAdjustedTimeRange = ({
    startDate,
    endDate,
}: TimeRange): TimeRange & { hash: string } => {
    const store = useSelector(getActiveStore);
    const [adjStartDate, setStart] = useState<Moment>(startDate);
    const [adjEndDate, setEnd] = useState<Moment>(endDate);
    useEffect(() => {
        if (!store?._id || !startDate || !endDate) return;

        const tz = getStoreTimezone(store);
        const adjStartDate = startDate?.tz(tz).startOf("day");
        const adjEndDate = endDate.tz(tz).endOf("day");

        setStart(adjStartDate);
        setEnd(adjEndDate);
    }, [startDate?.unix(), endDate?.unix(), store?._id]);

    return {
        startDate: adjStartDate,
        endDate: adjEndDate,
        hash: genHash(adjStartDate.unix(), adjEndDate.unix()),
    };
};
