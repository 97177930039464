import { z } from "zod";

const containsLowerTest = /[a-z]/;
const containsUpperTest = /[A-Z]/;
const containsNumberTest = /[0-9]/;
const containsSpecialCharTest = /[! "#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\\]/;

export const newAdminAccountSchema = z
    .object({
        email: z.string().email(),
        firstName: z.string().min(1),
        lastName: z.string().min(1),
        password: z.string().min(8),
        confirmPassword: z.string().min(8),
    })
    .superRefine((values, context) => {
        if (values.password !== values.confirmPassword) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Passwords do not match",
                path: ["confirmPassword"],
            });
        }
        if (
            !containsLowerTest.test(values.password) ||
            !containsUpperTest.test(values.password) ||
            !containsNumberTest.test(values.password) ||
            !containsSpecialCharTest.test(values.password)
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message:
                    "Password must contain at least one lowercase letter, one uppercase letter, one number and one special character",
                path: ["password"],
            });
        }
    });
