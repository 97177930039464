import { useCallback, useMemo, useState } from "react";
import { min, differenceInDays } from "date-fns";
import moment from "moment-timezone";
import { DateRange } from "react-day-picker";
import { toast } from "sonner";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { DateRangePicker } from "src/@/components/ui/date-range-picker";
import { Button } from "src/@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { DefinedDates, useOrders } from "../orders-context";
import { Routes } from "#navigation/routes";
import { DatePicker } from "src/@/components/ui/date-picker";
import { MAX_DATE_RANGE } from "#orders/use-query-mongo-orders";
import { useSelector } from "react-redux";
import {
    getActiveStore,
    getUser,
    getUserIsSnackpassEmployee,
} from "src/redux/selectors";

export const OrdersDateSelector = () => {
    const { duration, setDuration, setTimeRange, startDate, endDate } =
        useOrders();

    const [isOpen, setIsOpen] = useState(false);
    const [tempDateRange, setTempDateRange] = useState<DateRange | undefined>({
        from: startDate.toDate(),
        to: min([endDate.toDate(), new Date()]),
    });
    const [hasChanges, setHasChanges] = useState(false);
    const user = useSelector(getUser);
    const activeStore = useSelector(getActiveStore);
    const isSnackpassEmployee = useSelector(getUserIsSnackpassEmployee);
    const KINGS_KITCHEN_HARD_CODE_BLOCK_DATE = useMemo(() => {
        const KINGS_KITCHEN_ID = "66f6e600a62affdda0fdadd0";
        return (
            activeStore?._id == KINGS_KITCHEN_ID &&
            !isSnackpassEmployee &&
            user?.email !== "queeniegz08@gmail.com" &&
            user?.email !== "andyliao5081@gmail.com"
        );
    }, [activeStore?._id, user?.email, isSnackpassEmployee]);

    const formatDate = (date: moment.Moment) => {
        if (!date) return "";
        return date.format("MMM D, YYYY");
    };

    const dateRangeText =
        startDate && endDate
            ? startDate.isSame(endDate, "day")
                ? formatDate(endDate)
                : `${formatDate(startDate)} - ${formatDate(endDate)}`
            : "Select dates";

    const handleCustomDateChange = useCallback(
        (value: DateRange | undefined) => {
            setTempDateRange(value);
            setHasChanges(true);
            if (value?.from && value?.to && value.from === value.to) {
                setDuration(DefinedDates.SPECIFIC_DAY);
            } else if (value?.from && value?.to && value.from !== value.to) {
                setDuration(DefinedDates.DATE_RANGE);
            }
        },
        [setDuration],
    );

    const handleApplyDateRange = useCallback(() => {
        if (!tempDateRange?.from || !tempDateRange?.to) {
            setTimeRange({
                startDate: moment().startOf("day"),
                endDate: moment().endOf("day"),
            });
            return;
        }

        if (tempDateRange.from !== tempDateRange.to) {
            const daysDiff = differenceInDays(
                tempDateRange.to,
                tempDateRange.from,
            );
            if (daysDiff > 10) {
                toast.error("Range too long", {
                    description: `Please use Sales Report for ranges longer than ${MAX_DATE_RANGE} days`,
                    action: {
                        label: "Go To Sales Report",
                        onClick: () =>
                            (window.location.href =
                                Routes.FinancialReportsEssentials),
                    },
                });
                return;
            }
        }

        setTimeRange({
            startDate: moment(tempDateRange.from).startOf("day"),
            endDate: moment(tempDateRange.to).endOf("day"),
        });
        setHasChanges(false);
    }, [tempDateRange, setTimeRange]);

    const handleDurationSelection = useCallback(
        (value: string) => {
            const newDuration = value as DefinedDates;

            if (newDuration === DefinedDates.LAST7DAYS) {
                setDuration(newDuration);
                const now = moment();
                const end = now.clone().subtract(1, "days").endOf("day");
                const start = end.clone().subtract(6, "days").startOf("day");
                const newRange = { from: start.toDate(), to: end.toDate() };
                setTimeRange({ startDate: start, endDate: end });

                setTempDateRange(newRange);
                return;
            }

            setDuration(newDuration);

            if (newDuration === DefinedDates.SPECIFIC_DAY) {
                setIsOpen(false);
                return;
            }

            const now = moment();
            let start: moment.Moment;
            let end: moment.Moment;

            switch (newDuration) {
                case DefinedDates.TODAY:
                    start = now.clone().startOf("day");
                    end = now.clone().endOf("day");
                    break;
                case DefinedDates.YESTERDAY:
                    start = now.clone().subtract(1, "day").startOf("day");
                    end = now.clone().subtract(1, "day").endOf("day");
                    break;
                default:
                    return;
            }

            const newRange = { from: start.toDate(), to: end.toDate() };
            setTimeRange({ startDate: start, endDate: end });
            setTempDateRange(newRange);
        },
        [setDuration, setTimeRange],
    );

    const handleSpecificDayChange = useCallback((date: Date | undefined) => {
        if (!date) return;
        setTempDateRange({
            from: date,
            to: date,
        });
        setHasChanges(true);
    }, []);

    const ALLOWED_DURATIONS = [
        DefinedDates.TODAY,
        DefinedDates.YESTERDAY,
        DefinedDates.LAST7DAYS,
        DefinedDates.SPECIFIC_DAY,
        DefinedDates.DATE_RANGE,
    ];

    const options = ALLOWED_DURATIONS.map((e) => ({
        label: e,
        value: e,
    }));

    return (
        <div className="flex items-center gap-2">
            <FilterSelect
                customButton={
                    <Button
                        variant="outline"
                        size="sm"
                        className="flex items-center"
                        disabled={KINGS_KITCHEN_HARD_CODE_BLOCK_DATE}
                    >
                        <CalendarIcon className="h-4 w-4" />
                        <span className="hidden md:block">{duration}</span>
                    </Button>
                }
                title={duration}
                subtitle={dateRangeText}
                selectedValues={new Set([duration])}
                options={options}
                onOptionSelected={handleDurationSelection}
                open={isOpen}
                onOpenChange={setIsOpen}
            />
            {duration === DefinedDates.SPECIFIC_DAY && (
                <div className="flex items-center gap-2">
                    <DatePicker
                        date={tempDateRange?.from}
                        onDateChange={handleSpecificDayChange}
                        disabled={(date) => date > new Date()}
                    />
                    <Button
                        size="sm"
                        onClick={handleApplyDateRange}
                        disabled={!tempDateRange?.from || !hasChanges}
                    >
                        Apply
                    </Button>
                </div>
            )}
            {duration === DefinedDates.DATE_RANGE && (
                <div className="flex items-center gap-2">
                    <DateRangePicker
                        date={tempDateRange}
                        onDateChange={handleCustomDateChange}
                        disableFutureDates
                    />
                    <Button
                        size="sm"
                        onClick={handleApplyDateRange}
                        disabled={
                            !tempDateRange?.from ||
                            !tempDateRange?.to ||
                            !hasChanges
                        }
                    >
                        Apply
                    </Button>
                </div>
            )}
        </div>
    );
};
