import { createSlice } from "@reduxjs/toolkit";

const authInitialState = {
    needsToReauthenticate: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: authInitialState,
    reducers: {
        setNeedsToReauthenticate: (state, action) => {
            state.needsToReauthenticate = action.payload;
        },
    },
});

export const { setNeedsToReauthenticate } = authSlice.actions;
