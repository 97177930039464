import { useLocation } from "react-router-dom";

import { SidebarTrigger } from "src/@/components/ui/sidebar";
import { Separator } from "src/@/components/ui/separator";
import { Breadcrumb } from "src/@/components/ui/breadcrumb";
import { Routes } from "#navigation/routes";
import { ROUTE_TO_BREADCRUMB_MAP } from "#navigation/sidebar/utils";

export function NavigationHeader() {
    const location = useLocation();
    const breadcrumbItems =
        ROUTE_TO_BREADCRUMB_MAP.get(location.pathname as Routes) || [];
    // do not stick hooks here! use the initial hooks component one level up

    return (
        <header className="flex h-16 shrink-0 items-center gap-2 sticky top-0 bg-background z-10 rounded-xl">
            <div className="flex items-center gap-2 px-4">
                <SidebarTrigger className="-ml-1" />
                <Separator orientation="vertical" className="mr-2 h-4" />
                <Breadcrumb items={breadcrumbItems} />
            </div>
        </header>
    );
}
