import { createSelector } from "@reduxjs/toolkit";
import Dinero from "dinero.js";
import _ from "lodash";

import { InventoryReport } from "src/redux/slices/inventory";
import { RootState } from "src/redux/store";

function getStatus(amountLeft: number) {
    if (!amountLeft || amountLeft <= 0) {
        return "Out of Stock";
    }
    if (amountLeft <= 10) {
        return "Low Stock";
    }
    return "In Stock";
}

export const getInventory = (state: RootState) => state.inventory;

const getInventoryReports = createSelector([getInventory], (inventory) => {
    const ret: InventoryReport[] = [];
    // important to use _id here not id
    const reportsByItemId = _.keyBy(inventory.reports, "inventoryItem._id");

    // add report for items withour reports
    inventory.items.forEach((item) => {
        // item doesn't have attached report so create empty report
        if (!reportsByItemId[item.id]) {
            const blankInventoryReport: InventoryReport = {
                inventoryItem: item,
                unitsOnHand: 0,
                consumedAmount: 0,
                totalCost: 0,
                totalCostValue: "$0.00",
            };
            ret.push(blankInventoryReport);
        } else {
            ret.push({
                ...reportsByItemId[item.id],
                // we use the item NOT nested in the report
                // because the nested item misses some fields like sku and isHidden
                inventoryItem: { ...item },
            });
        }
    });
    return ret;
});

export const getInventoryTableRows = createSelector(
    [getInventoryReports],
    (reports) =>
        reports
            .filter((d) => !d.inventoryItem?.isHidden)
            .map((d) => {
                const status = getStatus(d.unitsOnHand);
                return {
                    "Item Name": d.inventoryItem?.name,
                    SKU: d.inventoryItem?.sku,
                    Status: status,
                    "On Hand": d.unitsOnHand || 0,
                    UOM: d.inventoryItem?.baseUnit,
                    Price: Dinero({
                        amount: d.inventoryItem?.costPerUnit?.amount,
                    }).toFormat(),
                    "Quantity Sold": d.consumedAmount,
                    "Total Cost": d.totalCostValue,
                    original: { ...d },
                };
            }),
);

export const getInventoryStartDate = createSelector(
    [getInventory],
    (inventory) => inventory.startDate,
);

export const getInventoryEndDate = createSelector(
    [getInventory],
    (inventory) => inventory.endDate,
);

export const getInventoryIsFetching = createSelector(
    [getInventory],
    (inventory) => inventory.isFetching,
);

export const getTopUpPersistence = createSelector(
    [getInventory],
    (inventory) => inventory.topUpOnHand,
);

export const getInventoryItems = createSelector(
    [getInventory],
    (inventory) => inventory.items,
);
