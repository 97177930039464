import { useMutation } from "@tanstack/react-query";

import api from "src/api/rest";

export const UseAcceptInvite = () => {
    const { isPending, mutate, isSuccess, error } = useMutation({
        mutationFn: async (body: {
            nonce: string;
            firstName: string;
            lastName: string;
            password: string;
        }) =>
            api.adminUserInvite.createAccount(
                body.nonce,
                body.firstName,
                body.lastName,
                body.password,
            ),
    });

    return {
        isPending,
        mutate,
        isSuccess,
        error,
    };
};
