import { gql } from "@apollo/client";

import { GetStoreAudienceQuery as GetStoreAudienceQueryType } from "src/api/graphql/generated/types";

export type GetStoreAudienceResponse = GetStoreAudienceQueryType;
export const GetStoreAudienceQuery = gql`
    query GetStoreAudience($storeId: ID!) {
        storeAudience(storeId: $storeId) {
            followers
            recentCustomers
            needAttentionCustomers
            newCustomers
            testCustomers

            followersWithIndividualConsent
            recentCustomersWithIndividualConsent
            newCustomersWithIndividualConsent
            needAttentionCustomersWithIndividualConsent
            testCustomersWithIndividualConsent
        }
    }
`;
