import React from "react";
import { useSelector } from "react-redux";

import { getUser } from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";
import { useLogout } from "#hooks/use-logout";
import { Alert, AlertTitle, AlertDescription } from "src/@/components/ui/alert";

export const NotAdminPage = () => {
    const logout = useLogout();
    const user = useSelector(getUser);
    const userIdentifier = user?.email || user?.number || "No user";

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-center">
                <Alert className="w-fit p-3 my-8" variant="destructive">
                    <AlertTitle className="text-lg">
                        You do not have access to this page.
                    </AlertTitle>

                    <AlertDescription className="text-sm">
                        Please contact Snackpass for further assistance. <br />
                        You are logged in as {userIdentifier}
                    </AlertDescription>
                </Alert>
                <Button
                    onClick={logout}
                    variant="default"
                    size="default"
                    className="mt-4 mx-auto block"
                >
                    Logout
                </Button>
            </div>
        </div>
    );
};
