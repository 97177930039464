import React from "react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";

import { Button } from "src/@/components/ui/button";
import {
    usePurchaseReportRows,
    usePurchaseReportTotals,
} from "src/api/rest/usePurchaseReports";
import { UserFacingError } from "src/utils/errors";

export const ReloadButton = () => {
    const { refetch: refetchRows } = usePurchaseReportRows();
    const { refetch: refetchTotals } = usePurchaseReportTotals();

    const handleClick = async () => {
        try {
            await Promise.all([refetchRows(), refetchTotals()]);
            toast.success("Page refreshed!");
        } catch (error) {
            const description =
                error instanceof UserFacingError
                    ? error.message
                    : "Error while refetching. Please try again shortly.";
            toast.error(description);
        }
    };
    return (
        <Button
            variant="outline"
            onClick={handleClick}
            size="sm"
            className="flex items-center space-x-1"
            data-testid="button-wrapper"
        >
            <ReloadIcon className="h-4 w-4" />
        </Button>
    );
};
