import {
    Users,
    ShoppingBag,
    Receipt,
    DollarSign,
    MessageCircle,
    Inbox,
    ThumbsUp,
    ThumbsDown,
    Smartphone,
    Repeat,
    ChevronRightIcon,
} from "lucide-react";
import {
    Bar,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Cell,
    Line,
    LineChart,
} from "recharts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from "src/@/components/ui/card";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "src/@/components/ui/chart";
import { MetricCard } from "#dashboard/components/insights/dashboard-metric-card";
import { useSalesMongo } from "#dashboard/hooks/use-sales-mongo";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { ComparisonPeriod, Range } from "#dashboard/types";
import { useSalesBigquery } from "#dashboard/hooks/use-sales-bigquery";
import { useCustomerInsights } from "#dashboard/hooks/use-customer-insights";
import { getActiveStoreNumUnreads } from "src/redux/slices";
import { useSMSSubscribers } from "#dashboard/hooks/use-sms-subscribers";
import { useSalesCohorts } from "#dashboard/hooks/use-sales-cohorts";
import { DashboardGranularityPicker } from "../filters/granularity-picker";
import { QuickShortcutsCard } from "../quick-shortcuts-card";
import { ChartCard } from "./chart-card";
import { SectionHeader } from "./section-header";
import { SalesBreakdownCharts } from "./sales-breakdown-charts";
import { TopPromotionsChart } from "./top-promotions-chart";
import { TopItemsChart } from "./top-items-chart";
import { Routes } from "#navigation/routes";
import { Button } from "src/@/components/ui/button";
import { Skeleton } from "src/@/components/ui/skeleton";

const getSalesChartConfig = (
    currentPeriodStartEnd: Range | null,
    comparisonPeriodStartEnd: Range | null,
) =>
    ({
        current: {
            label: `${currentPeriodStartEnd?.startDate?.format("MM/DD/YY")} - ${currentPeriodStartEnd?.endDate?.format("MM/DD/YY")}`,
            color: "#0099FF",
        },
        previous: {
            label: comparisonPeriodStartEnd
                ? `${comparisonPeriodStartEnd.startDate?.format("MM/DD/YY")} - ${comparisonPeriodStartEnd.endDate?.format("MM/DD/YY")}`
                : "Previous Period",
            color: "#999DA3",
        },
    }) satisfies ChartConfig;

const getHourlyChartConfig = (
    currentPeriodStartEnd: Range | null,
    comparisonPeriodStartEnd: Range | null,
) =>
    ({
        current: {
            label: currentPeriodStartEnd?.startDate?.format("MM/DD/YY"),
            color: "#0099FF",
        },
        previous: {
            label:
                comparisonPeriodStartEnd?.startDate?.format("MM/DD/YY") ||
                "Previous Period",
            color: "#999DA3",
        },
    }) satisfies ChartConfig;

const customerChartConfig = {
    total: {
        label: "Customer Breakdown",
        color: "hsl(var(--primary))",
    },
} satisfies ChartConfig;

const formatCurrency = (value: number) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);

interface ClickableChartCardProps {
    title: string;
    description?: string;
    to?: string;
    children: React.ReactNode;
}

export function ClickableChartCard({
    title,
    description,
    to,
    children,
}: ClickableChartCardProps) {
    const content = (
        <>
            <CardHeader>
                <CardTitle className="text-sm font-medium text-muted-foreground">
                    {title}
                </CardTitle>
                {description && (
                    <CardDescription>{description}</CardDescription>
                )}
            </CardHeader>
            <CardContent>{children}</CardContent>
        </>
    );

    if (to) {
        return (
            <Link to={to} className="block no-underline">
                <Card className="cursor-pointer transition-shadow duration-200 hover:shadow-md">
                    {content}
                </Card>
            </Link>
        );
    }

    return <Card>{content}</Card>;
}

export const Insights = () => {
    const {
        currentPeriod,
        comparisonPeriod,
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
    } = useDashboardContext();

    // Always call both hooks
    const mongoData = useSalesMongo();
    const bigQueryData = useSalesBigquery();

    // Determine which data source to use
    // const shouldUseMongo = currentPeriod === CurrentPeriod.TODAY;

    // for now, always use mongo because bigquery is not returning the correct data
    const shouldUseMongo = true;

    // Select the appropriate data source
    const {
        report,
        isLoading: isSalesLoading,
        error: salesError,
        netSalesChange,
        ordersChange,
        currentAvgTicket,
        avgTicketChange,
        chartData,
    } = shouldUseMongo ? mongoData : bigQueryData;

    // Get hourly data from bigQuery
    const {
        hourlyData,
        isLoading: isHourlyLoading,
        error: hourlyError,
    } = bigQueryData;

    const numUnreads = useSelector(getActiveStoreNumUnreads);

    const {
        customerData,
        isLoading: isCustomerLoading,
        totals,
        error: customerError,
        changes,
    } = useCustomerInsights();

    const { subscriberCount, loading: smsLoading } = useSMSSubscribers();

    // Get the dynamic chart config
    const salesChartConfig = getSalesChartConfig(
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
    );

    const hourlyChartConfig = getHourlyChartConfig(
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
    );

    const {
        breakdown,
        isLoading: isBreakdownLoading,
        error: breakdownError,
    } = useSalesCohorts();
    return (
        <div className="grid gap-4">
            <div className="grid gap-4 md:grid-cols-3">
                <MetricCard
                    title="Net Sales"
                    value={formatCurrency(report?.netSales || 0)}
                    changeValue={netSalesChange}
                    icon={DollarSign}
                    isLoading={isSalesLoading}
                />
                <MetricCard
                    title="Orders"
                    value={report?.count?.toLocaleString() ?? "0"}
                    changeValue={ordersChange}
                    icon={ShoppingBag}
                    isLoading={isSalesLoading}
                />
                <MetricCard
                    title="Average Ticket"
                    value={formatCurrency(currentAvgTicket)}
                    changeValue={avgTicketChange}
                    icon={Receipt}
                    isLoading={isSalesLoading}
                />
            </div>

            <div className="grid gap-4 md:grid-cols-3">
                <Card className="md:col-span-2">
                    <CardHeader>
                        <div className="flex items-center justify-between">
                            <div className="space-y-1.5">
                                <CardTitle className="text-sm font-medium text-muted-foreground">
                                    Net Sales
                                </CardTitle>
                                <div className="flex items-center gap-4 text-sm text-muted-foreground">
                                    <div className="flex items-center gap-2">
                                        <div
                                            className="h-3 w-3 rounded-full"
                                            style={{
                                                background:
                                                    salesChartConfig.current
                                                        .color,
                                            }}
                                        />
                                        <span>
                                            {salesChartConfig.current.label}
                                        </span>
                                    </div>
                                    {comparisonPeriod !==
                                        ComparisonPeriod.NONE && (
                                        <div className="flex items-center gap-2">
                                            <div
                                                className="h-3 w-3 rounded-full"
                                                style={{
                                                    background:
                                                        salesChartConfig
                                                            .previous.color,
                                                }}
                                            />
                                            <span>
                                                {
                                                    salesChartConfig.previous
                                                        .label
                                                }
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <DashboardGranularityPicker />
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        {isSalesLoading ? (
                            <Skeleton className="h-[330px] w-full" />
                        ) : (
                            <ChartContainer config={salesChartConfig}>
                                <BarChart
                                    data={chartData}
                                    height={300}
                                    margin={{
                                        top: 20,
                                        right: 12,
                                        left: 12,
                                        bottom: 20,
                                    }}
                                >
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        dataKey="period"
                                        tickLine={false}
                                        axisLine={false}
                                        tickMargin={8}
                                    />
                                    <YAxis
                                        tickLine={false}
                                        axisLine={false}
                                        tickMargin={8}
                                        tickFormatter={(value) => `$${value}`}
                                    />
                                    <ChartTooltip
                                        cursor={false}
                                        content={
                                            <ChartTooltipContent
                                                valueIsCurrency
                                            />
                                        }
                                    />
                                    {comparisonPeriod !==
                                        ComparisonPeriod.NONE && (
                                        <Bar
                                            dataKey="previous"
                                            fill="var(--color-previous)"
                                            radius={[4, 4, 0, 0]}
                                        />
                                    )}
                                    <Bar
                                        dataKey="current"
                                        fill="var(--color-current)"
                                        radius={[4, 4, 0, 0]}
                                    />
                                </BarChart>
                            </ChartContainer>
                        )}
                    </CardContent>
                </Card>

                <div className="min-h-[400px] sm:min-h-0 md:col-span-1">
                    <QuickShortcutsCard />
                </div>
            </div>

            <SectionHeader title="Guestbook & Marketing" className="mt-6" />
            <div className="grid gap-4 md:grid-cols-4">
                <MetricCard
                    title="New Customers"
                    value={totals.new.toLocaleString()}
                    changeValue={changes.newCustomersChange}
                    icon={Users}
                    isLoading={isCustomerLoading}
                />
                <MetricCard
                    title="Repeat Customers"
                    value={totals.repeat.toLocaleString()}
                    changeValue={changes.repeatCustomersChange}
                    icon={Repeat}
                    isLoading={isCustomerLoading}
                />
                <MetricCard
                    title="App Customers"
                    value={totals.app.toLocaleString()}
                    changeValue={changes.appCustomersChange}
                    icon={Smartphone}
                    isLoading={isCustomerLoading}
                />
                <MetricCard
                    title="SMS Subscribers"
                    value={subscriberCount.toLocaleString()}
                    icon={MessageCircle}
                    isLoading={smsLoading}
                    to={Routes.GuestbookCampaignsSMS}
                />
            </div>

            <SectionHeader title="Customer Feedback" className="mt-6" />
            <div className="grid gap-4 md:grid-cols-3">
                <MetricCard
                    title="Unread Messages"
                    value={numUnreads.toString()}
                    icon={Inbox}
                    to={Routes.GuestbookConversations}
                />
                <MetricCard
                    title="Positive Feedback"
                    value={
                        breakdown.feedbackData
                            .find((item) => item.name === "Good")
                            ?.value.toLocaleString() ?? "0"
                    }
                    icon={ThumbsUp}
                    to={Routes.GuestbookConversations}
                    isLoading={isBreakdownLoading}
                />
                <MetricCard
                    title="Not Great Feedback"
                    value={
                        breakdown.feedbackData
                            .find((item) => item.name === "Bad")
                            ?.value.toLocaleString() ?? "0"
                    }
                    icon={ThumbsDown}
                    to={Routes.GuestbookConversations}
                    isLoading={isBreakdownLoading}
                />
            </div>
            <div className="flex justify-end w-full">
                <Link
                    to={Routes.GuestbookConversations}
                    className="no-underline text-card-foreground"
                >
                    <Button variant="outline">
                        View All Feedback
                        <ChevronRightIcon className="ml-2 h-4 w-4" />
                    </Button>
                </Link>
            </div>
            <SectionHeader title="Insights" className="mt-6" />
            <div className="mt-4 grid gap-4 md:grid-cols-2">
                <ChartCard
                    title="Sales by Hour (Totals)"
                    isLoading={isHourlyLoading}
                >
                    <ChartContainer config={hourlyChartConfig}>
                        <LineChart
                            data={hourlyData}
                            margin={{
                                top: 20,
                                right: 12,
                                left: 12,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                dataKey="time"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                            />
                            <YAxis
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                tickFormatter={(value) => `$${value}`}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={
                                    <ChartTooltipContent
                                        indicator="line"
                                        valueIsCurrency
                                    />
                                }
                            />
                            <Line
                                type="monotone"
                                dataKey="current"
                                stroke="var(--color-current)"
                                strokeWidth={2}
                                dot={false}
                                activeDot={{ r: 6 }}
                            />
                            {comparisonPeriod !== ComparisonPeriod.NONE && (
                                <Line
                                    type="monotone"
                                    dataKey="previous"
                                    stroke="var(--color-previous)"
                                    strokeWidth={2}
                                    dot={false}
                                />
                            )}
                        </LineChart>
                    </ChartContainer>
                </ChartCard>
                <TopItemsChart />
                <SalesBreakdownCharts
                    breakdown={breakdown}
                    isLoading={isBreakdownLoading}
                />
                <ChartCard
                    title="Top Customers"
                    description={`${currentPeriodStartEnd?.startDate?.format("MM/DD/YY")} - ${currentPeriodStartEnd?.endDate?.format("MM/DD/YY")}`}
                    to={Routes.ReportsCustomerDirectoryInsights}
                    isLoading={isCustomerLoading}
                >
                    <ChartContainer config={customerChartConfig}>
                        <BarChart
                            data={customerData}
                            layout="vertical"
                            margin={{
                                top: 0,
                                right: 12,
                                bottom: 0,
                                left: 70,
                            }}
                        >
                            <CartesianGrid horizontal={false} />
                            <XAxis
                                type="number"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                tickFormatter={(value) =>
                                    `$${value.toLocaleString()}`
                                }
                            />
                            <YAxis
                                type="category"
                                dataKey="name"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={({ active, payload }) => {
                                    if (!active || !payload?.length)
                                        return null;
                                    const data = payload[0].payload;
                                    return (
                                        <div className="space-y-2 rounded-lg border bg-white p-2 shadow-md">
                                            <div className="font-medium">
                                                {data.name}
                                            </div>
                                            <div className="text-muted-foreground">
                                                ${data.value.toFixed(2)} spent
                                            </div>
                                            <div className="text-muted-foreground">
                                                {data.orders} orders
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                            <Bar dataKey="value" radius={[0, 4, 4, 0]}>
                                {customerData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ChartContainer>
                </ChartCard>

                <TopPromotionsChart />
            </div>
        </div>
    );
};
