import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { compose, propOr, toNumber } from "lodash/fp";
import { addDays } from "date-fns";

import api from "src/api/rest";
import { getActiveStoreId } from "src/redux/selectors";
import { setActiveStore } from "src/redux/slices";

export enum Action {
    Pause = "pause",
    Resume = "resume",
}

export enum PauseTime {
    Five = "5",
    Ten = "10",
    Fifteen = "15",
    Twenty = "20",
    Thirty = "30",
    FortyFive = "45",
    RestOfDay = "Rest of Day",
    Custom = "Custom",
}

export enum PauseReason {
    StoreClosedEarly = "Store closed early",
    StoreTooBusy = "Store too busy",
    InternetConnectionIssues = "Internet connection issues",
    PrinterIssues = "Printer issues",
    OutOfInventory = "Out of inventory",
    Other = "Other",
}

type ToggleAction = Action.Pause | Action.Resume;
export type SelectedOption = null | string;
export type SelectedDate = Date | undefined;
export type SelectOption = Dispatch<SetStateAction<SelectedOption>>;

export const DEFAULT_DATE = addDays(new Date(), 1);

export const useToggleOrders = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState<SelectedOption>(
        PauseTime.Five,
    );
    const [selectedDate, setSelectedDate] =
        useState<SelectedDate>(DEFAULT_DATE);
    const [selectedReason, setSelectedReason] = useState<SelectedOption>("");
    const [closedUntil, setClosedUntil] = useState<null | string>(null);
    const [customReason, setCustomReason] = useState<string>("");
    const dispatch = useDispatch();
    const storeId = useSelector(getActiveStoreId);
    const setStore = compose(dispatch, setActiveStore);

    const pauseOrdersUntil = useMemo(() => {
        let pausedUntil = null;
        if (selectedTime === PauseTime.Custom) {
            pausedUntil = moment(selectedDate).toDate();
        } else if (selectedTime === PauseTime.RestOfDay) {
            pausedUntil = moment().endOf("day").toDate();
        } else if (toNumber(selectedTime)) {
            pausedUntil = moment().add(selectedTime, "minutes").toDate();
        }
        return pausedUntil;
    }, [selectedTime, selectedDate]);

    const resetFields = () => {
        setSelectedTime(PauseTime.Five);
        setSelectedDate(DEFAULT_DATE);
        setSelectedReason("");
    };

    const toggleOrders = async ({ action }: { action: ToggleAction }) => {
        try {
            setIsLoading(true);

            const reason =
                selectedReason === PauseReason.Other
                    ? "Other: " + customReason
                    : selectedReason;

            const {
                data: { store },
            } = await api.stores.update(storeId, {
                closedUntil: action === Action.Pause ? pauseOrdersUntil : null,
                closedUntilReason: action === Action.Pause ? reason : null,
            });
            setClosedUntil(
                moment(store.closedUntil).format("MMMM Do YYYY, h:mm a"),
            );
            setStore(store);
        } catch (error) {
            propOr(
                "Something went wrong, we're looking into it!",
                "message",
                error,
            );
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        resetFields,
        selectedTime,
        setSelectedTime,
        selectedDate,
        setSelectedDate,
        selectedReason,
        setSelectedReason,
        toggleOrders,
        closedUntil,
        customReason,
        setCustomReason,
    };
};
