import { useContext } from "react";
import { SearchCommandBarContext } from "./search-command-bar-provider";

export function useSearchCommandBar() {
    const context = useContext(SearchCommandBarContext);
    if (context === undefined) {
        throw new Error(
            "useSearchCommandBar must be used within a SearchCommandBarProvider",
        );
    }
    return context;
}
