import { PaymentProvider } from "@snackpass/snackpass-types";

export enum PaymentDefinition {
    ALL = "All",
    ALL_EXCEPT_CASH_AND_OTHER = "Card",
    CASH_AND_OTHER = "Cash",
    THIRD_PARTY = "Third Party",
}

export const DefinedPaymentTypes = [
    {
        label: "Card",
        value: "Card",
    },
    {
        label: "Cash",
        value: "Cash",
    },
    {
        label: "Third Party",
        value: "Third Party",
    },
];

export const getPaymentMethods = (
    paymentMethods: PaymentDefinition[],
): PaymentProvider[] => {
    if (!paymentMethods.length) {
        return [];
    }

    const providers = new Set<PaymentProvider>();

    paymentMethods.forEach((type) => {
        switch (type) {
            case PaymentDefinition.ALL:
                break;
            case PaymentDefinition.ALL_EXCEPT_CASH_AND_OTHER:
                providers.add(PaymentProvider.stripe);
                break;
            case PaymentDefinition.CASH_AND_OTHER:
                providers.add(PaymentProvider.inStore);
                break;
            case PaymentDefinition.THIRD_PARTY:
                providers.add(PaymentProvider.thirdParty);
                break;
        }
    });

    return Array.from(providers);
};
