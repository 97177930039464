import moment from "moment";
import { CurrentPeriod, ComparisonPeriod, Range } from "../types";

type ComparisonOption = {
    label: string;
    value: ComparisonPeriod;
};

export const NO_COMPARISON_OPTION: ComparisonOption = {
    label: "No Comparison",
    value: ComparisonPeriod.NONE,
} as const;

export const CUSTOM_COMPARISON_OPTION: ComparisonOption = {
    label: "Custom",
    value: ComparisonPeriod.CUSTOM,
} as const;

export const getComparisonOptions = (
    currentPeriod: CurrentPeriod,
): ComparisonOption[] => {
    const baseOptions = [
        { label: "No Comparison", value: ComparisonPeriod.NONE },
    ];

    let periodOptions: ComparisonOption[] = [];

    switch (currentPeriod) {
        case CurrentPeriod.TODAY:
            periodOptions = [
                { label: "Yesterday", value: ComparisonPeriod.PREVIOUS_DAY },
                {
                    label: "This Day Last Week",
                    value: ComparisonPeriod.THIS_DAY_LAST_WEEK,
                },
                {
                    label: "This Day Last Month",
                    value: ComparisonPeriod.THIS_DAY_LAST_MONTH,
                },
                {
                    label: "This Day Last Year",
                    value: ComparisonPeriod.THIS_DAY_LAST_YEAR,
                },
            ];
            break;
        case CurrentPeriod.YESTERDAY:
            periodOptions = [
                { label: "Previous Day", value: ComparisonPeriod.PREVIOUS_DAY },
                {
                    label: "This Day Last Week",
                    value: ComparisonPeriod.THIS_DAY_LAST_WEEK,
                },
                {
                    label: "This Day Last Month",
                    value: ComparisonPeriod.THIS_DAY_LAST_MONTH,
                },
                {
                    label: "This Day Last Year",
                    value: ComparisonPeriod.THIS_DAY_LAST_YEAR,
                },
            ];
            break;
        case CurrentPeriod.LAST7DAYS:
            periodOptions = [
                {
                    label: "Previous 7 days",
                    value: ComparisonPeriod.PREVIOUS_7_DAYS,
                },
                {
                    label: "This Week Last Year",
                    value: ComparisonPeriod.THIS_WEEK_LAST_YEAR,
                },
            ];
            break;
        case CurrentPeriod.LAST30DAYS:
            periodOptions = [
                {
                    label: "Previous 30 Days",
                    value: ComparisonPeriod.PREVIOUS_30_DAYS,
                },
                {
                    label: "This Month Last Year",
                    value: ComparisonPeriod.THIS_MONTH_LAST_YEAR,
                },
            ];
            break;
        case CurrentPeriod.LAST90DAYS:
            periodOptions = [
                {
                    label: "Previous 90 Days",
                    value: ComparisonPeriod.PREVIOUS_90_DAYS,
                },
                {
                    label: "This 90 Days Last Year",
                    value: ComparisonPeriod.THIS_90_DAYS_LAST_YEAR,
                },
            ];
            break;
        case CurrentPeriod.LAST365DAYS:
            periodOptions = [
                {
                    label: "Previous 365 Days",
                    value: ComparisonPeriod.PREVIOUS_365_DAYS,
                },
            ];
            break;
        default:
            periodOptions = [];
    }

    // Always add custom option at the end
    return [...baseOptions, ...periodOptions, CUSTOM_COMPARISON_OPTION];
};

export const getCurrentPeriodRange = (period: CurrentPeriod): Range => {
    const now = moment();

    switch (period) {
        case CurrentPeriod.TODAY:
            return {
                startDate: now.clone().startOf("day"),
                endDate: now.clone().endOf("day"),
            };
        case CurrentPeriod.YESTERDAY:
            return {
                startDate: now.clone().subtract(1, "day").startOf("day"),
                endDate: now.clone().subtract(1, "day").endOf("day"),
            };
        case CurrentPeriod.LAST7DAYS:
            return {
                startDate: now.clone().subtract(7, "days").startOf("day"),
                endDate: now.clone().subtract(1, "day").endOf("day"),
            };
        case CurrentPeriod.LAST30DAYS:
            return {
                startDate: now.clone().subtract(30, "days").startOf("day"),
                endDate: now.clone().subtract(1, "day").endOf("day"),
            };
        case CurrentPeriod.LAST90DAYS:
            return {
                startDate: now.clone().subtract(90, "days").startOf("day"),
                endDate: now.clone().subtract(1, "day").endOf("day"),
            };
        case CurrentPeriod.LAST365DAYS:
            return {
                startDate: now.clone().subtract(365, "days").startOf("day"),
                endDate: now.clone().subtract(1, "day").endOf("day"),
            };
        default:
            return {
                startDate: now.clone().startOf("day"),
                endDate: now.clone().endOf("day"),
            };
    }
};

export const getComparisonRange = (
    currentRange: Range,
    comparisonPeriod: ComparisonPeriod,
): Range => {
    const { startDate, endDate } = currentRange;

    switch (comparisonPeriod) {
        case ComparisonPeriod.PREVIOUS_DAY:
            return {
                startDate: startDate.clone().subtract(1, "day").startOf("day"),
                endDate: startDate.clone().subtract(1, "day").endOf("day"),
            };
        case ComparisonPeriod.THIS_DAY_LAST_WEEK:
            return {
                startDate: startDate.clone().subtract(1, "week").startOf("day"),
                endDate: startDate.clone().subtract(1, "week").endOf("day"),
            };
        case ComparisonPeriod.THIS_DAY_LAST_MONTH:
            return {
                startDate: startDate
                    .clone()
                    .subtract(1, "month")
                    .startOf("day"),
                endDate: startDate.clone().subtract(1, "month").endOf("day"),
            };
        case ComparisonPeriod.THIS_DAY_LAST_YEAR:
            return {
                startDate: startDate.clone().subtract(1, "year").startOf("day"),
                endDate: startDate.clone().subtract(1, "year").endOf("day"),
            };
        case ComparisonPeriod.PREVIOUS_7_DAYS:
            return {
                startDate: startDate.clone().subtract(7, "days").startOf("day"),
                endDate: startDate.clone().subtract(1, "day").endOf("day"),
            };
        case ComparisonPeriod.THIS_WEEK_LAST_YEAR:
            return {
                startDate: startDate.clone().subtract(1, "year"),
                endDate: endDate.clone().subtract(1, "year"),
            };
        case ComparisonPeriod.PREVIOUS_30_DAYS:
            return {
                startDate: startDate
                    .clone()
                    .subtract(30, "days")
                    .startOf("day"),
                endDate: startDate.clone().subtract(1, "day").endOf("day"),
            };

        case ComparisonPeriod.PREVIOUS_365_DAYS:
            return {
                startDate: startDate.clone().subtract(1, "year").startOf("day"),
                endDate: endDate.clone().subtract(1, "year").endOf("day"),
            };
        case ComparisonPeriod.THIS_MONTH_LAST_YEAR:
            return {
                startDate: startDate.clone().subtract(1, "year").startOf("day"),
                endDate: endDate.clone().subtract(1, "year").endOf("day"),
            };
        case ComparisonPeriod.PREVIOUS_90_DAYS:
            return {
                startDate: startDate.clone().subtract(90, "days"),
                endDate: endDate.clone().subtract(90, "days"),
            };
        case ComparisonPeriod.THIS_90_DAYS_LAST_YEAR:
            return {
                startDate: startDate.clone().subtract(1, "year"),
                endDate: endDate.clone().subtract(1, "year"),
            };
        default:
            return currentRange;
    }
};
