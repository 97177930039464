import moment from "moment-timezone";
import { useOrders } from "../../orders-context";
import { DataTable } from "src/@/components/ui/data-table";
import { columns } from "./lib";
import { OrdersDownloadButton } from "../download-button";
import { Skeleton } from "src/@/components/ui/skeleton";

export const OrderHistory = () => {
    const {
        purchaseReportRows: { data: rows, isLoading },
    } = useOrders();
    const updatedTime = moment().format("h:mm a");

    const toolbarOptions = {
        showColumnFilter: true,
        search: {
            key: "Customer",
            placeholder: "Search Customer Name or Phone",
        },
        customComponent: <OrdersDownloadButton />,
    };

    if (isLoading) {
        return (
            <>
                <Skeleton className="my-4 h-8 w-64" />
                <Skeleton className="h-52 w-full" />
            </>
        );
    }
    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <h4 className="text-large">Orders</h4>
            </div>
            <DataTable
                toolbar={toolbarOptions}
                columns={columns}
                data={rows ?? []}
                showPagination={true}
                footerElement={
                    <span className="text-micro text-neutral-600 md:text-body">
                        Updated {updatedTime}
                    </span>
                }
                hiddenColumns={[
                    "Custom Surcharge",
                    "Custom Discounts",
                    "Order Tax Rate",
                ]}
            />
        </div>
    );
};
