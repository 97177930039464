import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetSliceState } from "src/core/domain";

export const activeStoreNumUnreadsSlice = createSlice({
    name: "activeStoreNumUnreads",
    initialState: {
        count: 0,
        channels: [] as string[],
    },
    reducers: {
        removeUnreadChannel: (state, action: PayloadAction<string>) => {
            // remove channelId from unreads and decrement count when it exists in the array
            if (state.channels.indexOf(action.payload) !== -1) {
                state.count > 0 ? state.count-- : 0;
                state.channels = state.channels.filter(
                    (channelId) => channelId !== action.payload,
                );
            }
        },
        setActiveStoreNumUnreadChannels: (
            state,
            action: PayloadAction<string[]>,
        ) => {
            state.channels = action.payload;
            state.count = action.payload.length;
        },
    },
});

export const { removeUnreadChannel, setActiveStoreNumUnreadChannels } =
    activeStoreNumUnreadsSlice.actions;

type State = GetSliceState<typeof activeStoreNumUnreadsSlice>;
export const { getActiveStoreNumUnreads, getActiveStoreNumUnreadChannels } = {
    getActiveStoreNumUnreads: (state: State) =>
        state.activeStoreNumUnreads.channels.length,
    getActiveStoreNumUnreadChannels: (state: State) =>
        state.activeStoreNumUnreads.channels,
};
