import { useMemo } from "react";
import { Building } from "lucide-react";
import {
    READABLE_THIRD_PARTY_MAPPING,
    ThirdPartySource,
} from "@snackpass/snackpass-types";

import { useOrders } from "../../orders-context";
import { getDashboardTransactionChannelOptions } from "src/redux/slices";
import { useSelector } from "react-redux";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";

export const OrdersProviderPicker = () => {
    const { providers, setProviders } = useOrders();

    const selectedProviders = useMemo(
        () => new Set(providers as string[]),
        [providers],
    );
    const channelOptions = useSelector(getDashboardTransactionChannelOptions);

    return (
        <FilterSelect2
            title="Provider"
            icon={Building}
            selectedValues={selectedProviders}
            options={channelOptions.map((e) => ({
                label: READABLE_THIRD_PARTY_MAPPING[e],
                value: e,
            }))}
            onOptionSelected={function (value: string): void {
                if (selectedProviders.has(value)) {
                    selectedProviders.delete(value);
                } else {
                    selectedProviders.add(value);
                }
                setProviders(
                    Array.from(selectedProviders) as ThirdPartySource[],
                );
            }}
        />
    );
};
