import { Link } from "react-router-dom";
import { featuredShortcuts } from "./featured-shortcuts-data";
import { FeatureCardContent } from "./feature-card-content";

const baseCardClassName =
    "relative h-96 overflow-hidden rounded-lg group hover:shadow-lg transition-all duration-500 ease-in-out";

function InteractiveCard({
    shortcut,
}: {
    shortcut: (typeof featuredShortcuts)[0];
}) {
    return (
        <div
            key={shortcut.id}
            onClick={shortcut.onClick}
            className={`${baseCardClassName} cursor-pointer`}
        >
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </div>
    );
}

function ExternalCard({
    shortcut,
}: {
    shortcut: (typeof featuredShortcuts)[0];
}) {
    return (
        <a
            href={shortcut.link}
            target="_blank"
            rel="noopener noreferrer"
            className={baseCardClassName}
        >
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </a>
    );
}

function InternalCard({
    shortcut,
}: {
    shortcut: (typeof featuredShortcuts)[0];
}) {
    return (
        <Link to={shortcut.link} className={baseCardClassName}>
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </Link>
    );
}

export function FeatureGrid() {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {featuredShortcuts.map((shortcut) => {
                if (shortcut.onClick) {
                    return (
                        <InteractiveCard
                            key={shortcut.id}
                            shortcut={shortcut}
                        />
                    );
                }

                if (shortcut.link.startsWith("http")) {
                    return (
                        <ExternalCard key={shortcut.id} shortcut={shortcut} />
                    );
                }

                return <InternalCard key={shortcut.id} shortcut={shortcut} />;
            })}
        </div>
    );
}
