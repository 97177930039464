import moment, { Moment as MomentTZ } from "moment-timezone";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getDashboardReportsDuration,
    getDashboardReportsEndDate,
    getDashboardReportsStartDate,
    resetDashboardFilter,
    setDashboardFilterDuration,
    setDashboardFilterTimeRange,
} from "src/redux/slices";

import { DefinedDates } from "../../date-picker/lib";

import { calculateCurrentTimeFromDuration } from "./lib";
import { usePreviousDates } from "./use-previous-dates";

export type OneWeekAgo = {
    today: {
        startOfDay: MomentTZ;
        endOfDay: MomentTZ;
    };
    oneWeekAgo: {
        startOfDay: MomentTZ;
        endOfDay: MomentTZ;
    };
};

export const useGlobalDate = () => {
    const globalDuration = useSelector(getDashboardReportsDuration);
    const globalStartDate = useSelector(getDashboardReportsStartDate);
    const globalEndDate = useSelector(getDashboardReportsEndDate);

    const [globalStartDateWithTZ, globalEndDateWithTZ] = useMemo(
        () => [
            globalStartDate ? moment(globalStartDate.toDate()) : null,
            globalEndDate ? moment(globalEndDate.toDate()) : null,
        ],
        [globalStartDate, globalEndDate],
    );

    const { calculatedDates, hash: calculatedDatesHash } = usePreviousDates(
        globalDuration,
        globalStartDateWithTZ,
        globalEndDateWithTZ,
    );

    const dispatch = useDispatch();
    const setGlobalTimeRange = ({
        startDate,
        endDate,
    }: {
        startDate: MomentTZ | null;
        endDate: MomentTZ | null;
    }) => dispatch(setDashboardFilterTimeRange({ startDate, endDate }));

    useEffect(() => {
        if (globalDuration === DefinedDates.CUSTOM) return;

        const { startDate, endDate } =
            calculateCurrentTimeFromDuration(globalDuration);

        if (
            !startDate.isSame(globalStartDate) ||
            !endDate.isSame(globalEndDate)
        )
            setGlobalTimeRange({ startDate, endDate });
    }, [globalDuration]);

    const setGlobalDuration = (duration: DefinedDates) => {
        dispatch(setDashboardFilterDuration(duration));
    };
    const resetGlobalDateDefaults = () => {
        dispatch(resetDashboardFilter);
    };

    return {
        currentDuration: globalDuration,
        startDate: calculatedDates.adjStartDate.clone(),
        endDate: calculatedDates.adjEndDate.clone(),
        hash: calculatedDatesHash,
        setGlobalTimeRange,
        setGlobalDuration,
        resetGlobalDateDefaults,
    };
};
