export interface FeaturedShortcutData {
    id: string;
    title: string;
    description: string;
    image: string;
    link: string;
    onClick?: () => void;
}

// REMMEBER TO MAKE THESE IMAGES SMALL!!!
export const featuredShortcuts: FeaturedShortcutData[] = [
    {
        id: "kitchen-display",
        title: "Kitchen Display",
        description: "Real-time kitchen management and tracking",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fbef6f9a5-6ede-45a2-a485-998f7216642c.png?alt=media&token=aaf826c6-8859-4248-917e-866006d26f45",
        link: "https://store.snackpass.co/collections/kitchen-display",
    },
    {
        id: "pickup-screen",
        title: "Pickup Screen",
        description: "Announce customer pickups with AI voice",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fcps.jpeg?alt=media&token=ab9d6f63-cfb6-4751-af44-332e89e2999e",
        link: "https://store.snackpass.co/collections/pickup-screens",
    },
    {
        id: "standing-kiosk-gen-2",
        title: "Standing Kiosk Gen 2",
        description: "Our new portable standing kiosk design",
        image: "https://store.snackpass.co/cdn/shop/files/5ClmoNXUn0OGiXIhTTmrLiFNqTw.webp?v=1722992842&width=823",
        link: "https://store.snackpass.co/products/standing-kiosk-gen-3",
    },
    {
        id: "handheld",
        title: "Handheld",
        description: "Everything on SnackOS, in a handheld",
        image: "https://store.snackpass.co/cdn/shop/files/Handheld_702598cd-f9d7-4bbf-aa50-0d9546b8f378.jpg?v=1719322929&width=1426",
        link: "https://store.snackpass.co/products/handheld-register-v2",
    },
    {
        id: "partner-app",
        title: "Partner App",
        description: "Manage your store and view analytics on the go",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2F173shots_so.png?alt=media&token=147c715a-633a-4333-b834-ebab7f796b97",
        link: "https://apps.apple.com/us/app/snackpass-partner/id1591165543",
    },
    {
        id: "branding",
        title: "Branding",
        description: "Kiosk splash video, tip image, logo and more",
        image: "https://store.snackpass.co/cdn/shop/files/Presotea_Harware_2024_02_090562copy.jpg?v=1725483384&width=823",
        link: "/settings-brand-and-appearance",
    },
    {
        id: "gift-cards",
        title: "Gift Cards",
        description: "Boost sales with gift cards, physical and digital",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fe4c57b92-c979-489e-af72-ca5ec33958d5.png?alt=media&token=0799fb44-b461-44d9-8801-8ad16e647b1a",
        link: "/settings-gift-card",
    },
    {
        id: "website",
        title: "Website",
        description:
            "Create an SEO-optimized site with a custom domain in minutes",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fwebsite-small-2.png?alt=media&token=89cac6b4-0d73-4267-b6d2-eeb7462797e0",
        link: "https://airtable.com/appy8eQbMkVKrsnZ4/pagwhjpPkzA0a3vgS/form",
    },
    {
        id: "sms-campaigns",
        title: "SMS Campaigns",
        description: "Create and track SMS marketing campaigns",
        image: "https://framerusercontent.com/images/dZJaMuCh6kiMg3EiHAjUXVR7YA.png?scale-down-to=1024",
        link: "/guestbook/campaigns/sms",
    },
    {
        id: "smart-receipts",
        title: "Smart Receipts",
        description: "Boost your postive yelp reviews and chat with customers",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2F883shots_so.png?alt=media&token=9290350f-0fa2-40a8-bb6f-785ea089512a",
        link: "https://support.snackpass.co/en/articles/9722334-smart-receipt",
    },
    {
        id: "commission-free-delivery",
        title: "Commission-Free Delivery",
        description: "Go direct and keep 100% of your delivery sales",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/snackpass-assets%2Fimage.png?alt=media&token=068ae0a7-7d90-4af2-aa38-2aa80486d039",
        link: "",
        onClick: () => {
            window.Intercom("startConversation", {
                message: "I'd like to set up commission-free delivery",
            });
        },
    },
    {
        id: "our-team",
        title: "Thank You",
        description: "We are honored to support you 24/7, 365 days a year",
        image: "https://firebasestorage.googleapis.com/v0/b/futura-c7f12/o/team%20normal.png?alt=media&token=5b968b32-f26a-44e5-b33f-60dd3a0936b7",
        link: "#",
        onClick: () => {
            window.Intercom("show");
        },
    },
];
