import { IStore } from "@snackpass/snackpass-types";
import { useQuery } from "@tanstack/react-query";

import api from "src/api/rest";
import { invariant } from "src/lib/invariant";
import { useAppSelector } from "src/redux/hooks";
import { getUser } from "src/redux/selectors";

/**
 * Returns stores which you are an admin of.
 * If you are a snackpass employee, returns all stores.
 */
export function useMyAdminStores() {
    const user = useAppSelector(getUser);
    invariant(user, "user is defined");

    const { isPending, data, error } = useQuery({
        queryFn: async () => {
            if (user.snackpassPermissions.isSnackpassEmployee) {
                const res = await api.stores.get(); // this is a SP-employee only endpoint that returns all stores in snackpass.
                return res.data.stores as IStore[];
            }
            const res = await api.stores.getMyAdminStores();
            return res.data.stores;
        },
        queryKey: ["my-admin-stores", user._id],
    });
    return { isPending, stores: data ?? [], error };
}
