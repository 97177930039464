import { ChevronRight } from "lucide-react";
import { cn } from "src/@/lib/utils";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem {
    title: string;
    url: string;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
    className?: string;
}

export function Breadcrumb({ items, className }: BreadcrumbProps) {
    const navigate = useNavigate();

    return (
        <nav
            className={cn(
                "flex items-center space-x-1 text-sm text-muted-foreground",
                className,
            )}
        >
            {items.map((item, index) => (
                <div key={item.url} className="flex items-center">
                    {index > 0 && <ChevronRight className="mx-2 h-4 w-4" />}
                    {index === items.length - 1 ? (
                        <span className="font-medium text-foreground">
                            {item.title}
                        </span>
                    ) : (
                        <button
                            onClick={() => navigate(item.url)}
                            className="text-muted-foreground hover:text-foreground transition-colors"
                        >
                            {item.title}
                        </button>
                    )}
                </div>
            ))}
        </nav>
    );
}
