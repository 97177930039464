import { useSelector } from "react-redux";
import { useEffect } from "react";

import { getActiveStore } from "src/redux/selectors";
import { LocalStorageKeys } from "#hooks/ domain/constants";
import { sendError, ErrorWithCause } from "src/utils/errors";
import config from "#config";

const KEY = config.isProduction
    ? LocalStorageKeys.activeStoreId
    : LocalStorageKeys.activeStoreId + `_${process.env.NODE_ENV}`;

export const getPersistedActiveStoreId = () => {
    try {
        return window.localStorage.getItem(KEY);
    } catch (error) {
        const errorWithCause = new ErrorWithCause(
            "Error getting persisted active store id from local storage",
            error,
        );
        sendError(errorWithCause);
        return null;
    }
};

export const clearPersistedActiveStoreId = () => {
    try {
        window.localStorage.setItem(KEY, "");
    } catch (error) {
        const errorWithCause = new ErrorWithCause(
            "Error clearing persisted active store id from local storage",
            error,
        );
        sendError(errorWithCause);
    }
};

export const usePersistStoreId = () => {
    const activeStore = useSelector(getActiveStore);

    useEffect(() => {
        if (!activeStore?._id) return;
        try {
            window.localStorage.setItem(KEY, activeStore._id);
        } catch (error) {
            const errorWithCause = new ErrorWithCause(
                "Error setting active store id in local storage",
                error,
            );
            sendError(errorWithCause);
        }
    }, [activeStore?._id]);
};
