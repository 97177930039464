import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import axios from "axios";
import { useDashboardContext } from "./use-dashboard-context";

interface PromotionData {
    name: string;
    redemptions: number;
    revenue: number;
    discount: number;
}

export function usePromotionReports() {
    const activeStore = useSelector(getActiveStore);
    const { currentPeriodStartEnd } = useDashboardContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [promotions, setPromotions] = useState<PromotionData[]>([]);

    const abortController = useRef<AbortController | null>(null);

    const fetchData = useCallback(async () => {
        if (
            !activeStore?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        )
            return;

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();
        setIsLoading(true);
        setError(false);

        try {
            const params = {
                storeId: activeStore._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                source: JSON.stringify([]),
            };

            const response = await api.reports.getPromotionsReport(
                params,
                abortController.current,
            );

            const formattedData = response.data.promotionReport
                .map((promo: any) => ({
                    name: promo.promotionContext?.name || "Unnamed Promotion",
                    redemptions: promo.totalUses,
                    revenue: promo.discountNetSales || 0,
                    discount: promo.discountCost || 0,
                }))
                .filter((promo: PromotionData) => promo.redemptions > 0)
                .sort(
                    (a: PromotionData, b: PromotionData) =>
                        b.redemptions - a.redemptions,
                )
                .slice(0, 5); // Get top 5 promotions

            setPromotions(formattedData);
            setIsLoading(false);
        } catch (err: any) {
            if (!axios.isCancel(err?.cause)) {
                setError(true);
                setIsLoading(false);
            }
        }
    }, [activeStore?._id, currentPeriodStartEnd]);

    useEffect(() => {
        fetchData();
        return () => {
            abortController.current?.abort();
        };
    }, [fetchData]);

    return {
        promotions,
        isLoading,
        error,
    };
}
