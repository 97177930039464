import React, { createContext, useCallback, useEffect, useMemo } from "react";

import { getActiveStore, getUser } from "src/redux/selectors";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { invariant } from "src/lib/invariant";
import { sendError, logAndSendError } from "src/utils/errors";
import { setLegacyProducts } from "src/redux/slices";

import { provideMenuService } from "./service";
import { fetchStoreProducts } from "#menu/api";
import { toast } from "sonner";

export type MenuService = ReturnType<typeof useMenuService>;

export const MenuServiceContext = createContext({} as MenuService);

export type MenuServiceProviderProps = {
    children: React.ReactNode;
};

export function MenuServiceProvider({ children }: MenuServiceProviderProps) {
    const _menuService = useMenuService();

    return (
        <MenuServiceContext.Provider value={_menuService}>
            {children}
        </MenuServiceContext.Provider>
    );
}

function useMenuService() {
    const dispatch = useAppDispatch();
    const store = useAppSelector(getActiveStore);
    const user = useAppSelector(getUser);

    const service = useMemo(
        () => (store?._id && user ? provideMenuService(store?._id) : null),
        [store?._id, user],
    );

    const fetchProducts = useCallback(async () => {
        invariant(store, "[fetchMenu] store exists");
        const result = await fetchStoreProducts(store._id);
        dispatch(setLegacyProducts(result.products));
    }, [dispatch, service, store?._id]);

    // Automatically fetch the menu when the store is available
    useEffect(() => {
        if (store) {
            void fetchProducts().catch((error) => {
                if (
                    !error.message.includes("Network Error") &&
                    !error.message.includes("404")
                ) {
                    toast.error("Error fetching products", {
                        description: error.message,
                    });
                    logAndSendError(error);
                    sendError(error);
                }
            });
        }
    }, [fetchProducts, store]);

    const sellOutProduct = useCallback(
        async (productId: string, until?: Date) => {
            invariant(
                service,
                "[sellOutProduct]: Menu service is not available.",
            );
            const result = await service.sellOutProduct(productId, until);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                },
            );
        },
        [dispatch, service],
    );

    const restockProduct = useCallback(
        async (productId: string) => {
            invariant(
                service,
                "[restockProduct]: Menu service is not available.",
            );
            const result = await service.restockProduct(productId);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                },
            );
        },
        [dispatch, service],
    );

    return useMemo(
        () => ({
            fetchProducts,
            restockProduct,
            sellOutProduct,
        }),
        [fetchProducts, restockProduct, sellOutProduct],
    );
}
