import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";

import { CampaignState } from "../types";

/** Selectors */
export const getCampaignState = (state: RootState): CampaignState =>
    state.campaign;

export const getCampaignMessageInput = createSelector(
    getCampaignState,
    (m) => m.message,
);

export const getCampaignMessageInputTitle = createSelector(
    getCampaignState,
    (m) => m.title,
);

export const getCampaignMessageInputVariables = createSelector(
    getCampaignState,
    (m) => m.variables,
);

export const getCampaignAudience = createSelector(
    getCampaignState,
    (m) => m.audience,
);
export const getCampaignType = createSelector(getCampaignState, (m) => m.type);
export const getDiscountPromoType = createSelector(
    getCampaignState,
    (m) => m.discount?.promoType,
);
export const getDiscountType = createSelector(
    getCampaignState,
    (m) => m.discount?.type,
);
export const getDiscountPercentOff = createSelector(
    getCampaignState,
    (m) => m.discount?.percentOff,
);
export const getDiscountDollarOff = createSelector(
    getCampaignState,
    (m) => m.discount?.dollarsOff,
);
export const getDiscountExpiresInDays = createSelector(
    getCampaignState,
    (m) => m.discount?.expiresInDays,
);
export const getDiscountPurchaseRequired = createSelector(
    getCampaignState,
    (m) => m.discount?.purchaseRequired,
);
export const getDiscountMaximumUses = createSelector(
    getCampaignState,
    (m) => m.discount?.maximumUses,
);
export const getDiscountProduct = createSelector(
    getCampaignState,
    (m) => m.discount?.product,
);

export const getActiveCampaignId = createSelector(
    getCampaignState,
    (m) => m.activeCampaignId,
);
