/** @jsxImportSource @emotion/react */
import React from "react";
import { toNumber } from "lodash";

import {
    PauseTime,
    SelectedOption,
    SelectOption,
} from "#dashboard/components/pause-orders/use-toggle-orders";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";

type TimeInputProps = {
    selectedOption: SelectedOption;
    selectOption: SelectOption;
};

export const TimeInput = ({ selectedOption, selectOption }: TimeInputProps) => (
    <>
        <h6>Pause orders for:</h6>
        <Select
            value={selectedOption ?? undefined}
            onValueChange={selectOption}
        >
            <SelectTrigger>
                <SelectValue placeholder={"Choose"} />
            </SelectTrigger>
            <SelectContent>
                {Object.values(PauseTime).map((option) => (
                    <SelectItem key={option} value={option}>
                        {formatTime(option)}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    </>
);

const formatTime = (timeMins: string) => {
    const numMins = toNumber(timeMins);

    if (!numMins) {
        return timeMins;
    } else if (numMins >= 60) {
        const hours = Math.floor(numMins / 60);
        const mins = numMins % 60;
        if (mins === 0) {
            return `${hours} hr`;
        } else {
            return `${hours} hr ${mins} min`;
        }
    } else {
        return `${numMins} min`;
    }
};
