import { RouteItem } from "../../types";
import { sidebarSections } from "../sidebar-hierarchy";
import { useRoutePermissions } from "./use-route-permissions";
import { Routes } from "#navigation/routes";

function filterNavItems(
    items: readonly RouteItem[],
    hasRoutePermission: (route: Routes) => boolean,
): RouteItem[] {
    return items
        .filter((item) => hasRoutePermission(item.url as Routes))
        .map((item) => ({
            ...item,
            ...("items" in item && item.items
                ? {
                      items: filterNavItems(
                          item.items as readonly RouteItem[],
                          hasRoutePermission,
                      ),
                  }
                : {}),
        }));
}

export function useCreateSidebar() {
    const { hasRoutePermission } = useRoutePermissions();
    const sections = sidebarSections.map((section) => ({
        ...section,
        items: filterNavItems(section.items, hasRoutePermission),
    }));

    return { sections };
}
