interface FeatureCardContentProps {
    image: string;
    title: string;
    description: string;
}

export function FeatureCardContent({
    image,
    title,
    description,
}: FeatureCardContentProps) {
    return (
        <>
            <div
                className="w-full h-full inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-125"
                style={{ backgroundImage: `url(${image})` }}
            />
            <div
                className="absolute inset-x-0 bottom-0 p-4 opacity-100 hover:bg-black/10 w-full h-full transition-all duration-500 flex flex-col justify-end"
                style={{
                    background:
                        "linear-gradient(to top, rgb(0, 0, 0,0.4) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 100%)",
                }}
            >
                <h3 className="text-lg font-semibold text-white">{title}</h3>
                <p
                    className="mt-1 text-sm"
                    style={{ color: "rgb(255 255 255 / 0.7)" }}
                >
                    {description}
                </p>
            </div>
        </>
    );
}
