import api from "src/api/rest";
import { firebaseAuth } from "#app/firebase";
import { Routes } from "#navigation/routes";
import { useAppDispatch } from "src/redux/hooks";
import { clearUser, setMfaPrecheck } from "src/redux/slices";

export const useLogout = () => {
    const dispatch = useAppDispatch();

    return () => {
        // Clear MFA cookie
        void api.users.clearVerification();

        // Sign out from Firebase
        void firebaseAuth.signOut();

        // Partner App has us embedded in a webview and wants to know if we
        // log out
        window.ReactNativeWebView?.postMessage("LOGOUT");
        window.PartnerAppJsApi?.logout();

        // Clear user Redux state
        dispatch(clearUser());
        dispatch(setMfaPrecheck(false));

        // Redirect to home or any other page
        window.location.href = Routes.Index;
    };
};
