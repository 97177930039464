import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    usePubNubWithToken,
    useUser,
    useConversations,
} from "@snackpass/conversations.hooks";
import { utils } from "@snackpass/conversations.client";

import { setActiveStoreNumUnreadChannels } from "src/redux/slices";

export const useLoadActiveStoreNumUnreads = () => {
    const dispatch = useDispatch();
    const pubnub = usePubNubWithToken();
    const [pnUser] = useUser(pubnub, { uuid: pubnub.getUUID() });
    const isFirstLoadRef = useRef(true);
    const { conversations } = useConversations(pubnub);

    const filteredUnreadChannels = useMemo(() => {
        if (!pnUser || conversations.length === 0) {
            return [];
        }
        const unreadChannels = utils.getUnreadChannels(pnUser);
        if (unreadChannels.length === 0) {
            return [];
        }
        return unreadChannels.filter((channel) =>
            conversations.some((c) => c.id === channel),
        );
    }, [pnUser, conversations]);

    useEffect(() => {
        if (isFirstLoadRef.current && filteredUnreadChannels.length > 0) {
            dispatch(setActiveStoreNumUnreadChannels(filteredUnreadChannels));
            isFirstLoadRef.current = false;
        }
    }, [dispatch, filteredUnreadChannels]);
};
