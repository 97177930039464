import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
export const getUser = (state: RootState) => state.user;

export const getUserAdminPermission = createSelector(
    [getUser],
    (user) => user?.snackpassPermissions?.isAdmin ?? false,
);

export const getUserFinancialReportsPermission = (storeId?: string) =>
    createSelector(
        [getUser],
        (user) =>
            (user?.snackpassPermissions.isSnackpassEmployee ||
                user?.permissions?.isOwner?.includes(storeId ?? "") ||
                user?.permissions?.hasFinancialReports?.includes(
                    storeId ?? "",
                )) ??
            false,
    );

export const getUserTeamPermission = createSelector(
    [getUser],
    (user) => user?.snackpassPermissions?.isSnackpassEmployee ?? false,
);

export const getUserIsSnackpassEmployee = createSelector(
    [getUser],
    (user) => user?.snackpassPermissions?.isSnackpassEmployee ?? false,
);

export const getUserSeniorSupportPermission = createSelector(
    [getUser],
    (user) =>
        (user?.snackpassPermissions.isSnackpassEmployee &&
            user?.snackpassPermissions.isSeniorSupport) ??
        false,
);

export const getUserFeeSettingsPermission = createSelector(
    [getUser],
    (user) =>
        !!(
            user?.snackpassPermissions?.isSnackpassEmployee ||
            user?.snackpassPermissions?.hasFeeSettings
        ),
);

export const getUserIsTestEnabled = createSelector(
    [getUser],
    (user) => user?.test?.testEnabled ?? false,
);

export const getUserIsAdmin = createSelector(
    [getUser],
    (user) => user?.snackpassPermissions?.isAdmin ?? false,
);

export const useActiveUser = () => useSelector(getUser);

export const getUserSnackpassPermissionsStoreIds = createSelector(
    [getUser],
    (user) => user?.snackpassPermissions?.storeIds ?? [],
);
