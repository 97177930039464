import { Wallet } from "lucide-react";

import {
    PaymentDefinition,
    DefinedPaymentTypes,
} from "#payment-type-picker/lib";
import { useOrders } from "../../orders-context";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";

export const OrdersPaymentTypePicker = () => {
    const { paymentMethods, setPaymentMethods } = useOrders();

    const selectedPaymentTypes = new Set(paymentMethods);

    return (
        <FilterSelect2
            icon={Wallet}
            title="Payment"
            selectedValues={selectedPaymentTypes}
            options={DefinedPaymentTypes}
            onOptionSelected={(value: string) => {
                const paymentType = value as PaymentDefinition;
                if (selectedPaymentTypes.has(paymentType)) {
                    selectedPaymentTypes.delete(paymentType);
                    setPaymentMethods([]); // Clear selection when deselecting
                } else {
                    selectedPaymentTypes.clear(); // Clear previous selection
                    selectedPaymentTypes.add(paymentType);
                    setPaymentMethods([paymentType]);
                }
            }}
        />
    );
};
