import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@radix-ui/react-icons";

import { Button } from "src/@/components/ui/button";
import { Routes } from "#navigation/routes";
import { Alert, AlertDescription } from "src/@/components/ui/alert";

import { ReferralButton } from "./components/referral";
import { Warnings } from "./components/warnings";
import { Insights } from "./components/insights";
import { FeatureGrid } from "./components/feature-grid";
import { ComparisonPicker } from "./components/filters/comparison-picker";
import { ChannelPicker } from "./components/filters/channel-picker";
import { CurrentPeriodPicker } from "./components/filters/current-period-picker";
import { SectionHeader } from "./components/insights/section-header";
import {
    DashboardFiltersProvider,
    useDashboardContext,
} from "./hooks/use-dashboard-context";
import { Page } from "src/@/components/ui/page";
import { CurrentPeriod } from "#dashboard/types";

const DashboardContent = () => {
    const navigate = useNavigate();
    const { currentPeriod } = useDashboardContext();
    return (
        <Page className="mb-32">
            <Alert className="max-w-xl">
                <AlertDescription className="flex items-center justify-between">
                    <span>Looking for today's sales?</span>
                    <Button
                        variant="outline"
                        size="sm"
                        className="font-medium"
                        onClick={() => navigate(Routes.BetaOrders)}
                    >
                        Open Daily Report{" "}
                        <ChevronRightIcon className="ml-2 h-4 w-4" />
                    </Button>
                </AlertDescription>
            </Alert>

            {currentPeriod === CurrentPeriod.TODAY && (
                <Alert className="max-w-xl mb-4" variant="warning">
                    <AlertDescription>
                        <span>
                            This data refreshes every ~10 minutes. For real-time
                            data, go to Daily Report above.
                        </span>
                    </AlertDescription>
                </Alert>
            )}

            <ReferralButton />
            <Warnings />
            <div className="space-y-4">
                <div className="relative flex items-center space-x-2 whitespace-nowrap">
                    <CurrentPeriodPicker />
                    <ComparisonPicker />
                    <ChannelPicker />
                </div>
                <Insights />
                <div className="flex justify-end">
                    <Button
                        variant="outline"
                        onClick={() => navigate(Routes.ReportsOverview)}
                    >
                        View All Reports
                        <ChevronRightIcon className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            </div>

            <div className="space-y-8">
                <SectionHeader title="Featured" />
                <FeatureGrid />
            </div>
        </Page>
    );
};

export const DashboardGrid = () => {
    return (
        <DashboardFiltersProvider>
            <DashboardContent />
        </DashboardFiltersProvider>
    );
};
