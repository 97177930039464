import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setNeedsToReauthenticate } from "src/redux/slices";

// accumulation of legacy code that needs to be refactored
// all modern code should not be added to this
export const useQueryPageAccess = () => {
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        if (
            queryParams.has("client") &&
            queryParams.get("client") === "order-hub"
        ) {
            dispatch(setNeedsToReauthenticate(true));
        }
    }, []);
};
