import { getActiveStore } from "@snackpass/accounting";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { trackSegmentEvent } from "#utils/segment";

export const useTrackEvent = () => {
    const activeStore = useSelector(getActiveStore);

    const storeId = activeStore?._id ?? null;
    const storeName = activeStore?.name ?? null;

    return useCallback(
        (event: string) => trackSegmentEvent(event, { storeId, storeName }),
        [storeId, storeName],
    );
};
