import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentProvider } from "@snackpass/snackpass-types";

import {
    getDashboardPaymentType,
    setDashboardFilterPaymentType,
} from "src/redux/slices";
import { genHash } from "#core/utils";
import { PaymentDefinition } from "#payment-type-picker/lib";

const calculatePaymentProviders = (
    globalPaymentType: PaymentDefinition,
): Array<PaymentProvider> => {
    switch (globalPaymentType) {
        case PaymentDefinition.ALL:
            return [];
        case PaymentDefinition.ALL_EXCEPT_CASH_AND_OTHER:
            return [
                PaymentProvider.stripe,
                PaymentProvider.checkout,
                PaymentProvider.paypal,
                PaymentProvider.adjustment,
                PaymentProvider.square,
                PaymentProvider.ocmp,
                PaymentProvider.thirdParty,
            ];
        case PaymentDefinition.CASH_AND_OTHER:
            return [PaymentProvider.inStore];
        default:
            return [];
    }
};

export const usePaymentTypePicker = () => {
    //globalSource need to come before useState hook to prevent payment type to be undefined
    const globalPaymentType = useSelector(getDashboardPaymentType);
    const [paymentMethod, setPaymentMethod] =
        useState<PaymentDefinition>(globalPaymentType);
    //default for paymentProviders is an empty array, signaling to query all paymentMethods
    const [paymentProviders, setPaymentProviders] = useState<
        Array<PaymentProvider>
    >(calculatePaymentProviders(globalPaymentType));

    useEffect(() => {
        setPaymentProviders(calculatePaymentProviders(globalPaymentType));
        setPaymentMethod(globalPaymentType);
    }, [globalPaymentType]);

    const dispatch = useDispatch();
    const setGlobalPaymentType = (paymentMethod: PaymentDefinition) => {
        dispatch(setDashboardFilterPaymentType(paymentMethod));
    };

    return {
        paymentMethod: paymentMethod,
        paymentProviders: paymentProviders,
        setGlobalPaymentType,
        hash: genHash(paymentMethod),
    };
};
