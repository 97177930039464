import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ActiveMenu = "regular" | "catering";

const activeMenuInitialState = "regular";

export const activeMenuSlice = createSlice({
    name: "activeMenu",
    initialState: activeMenuInitialState as ActiveMenu, // XXX: Need to cast otherwise it is inferred as string
    reducers: {
        setActiveMenu: (_, action: PayloadAction<ActiveMenu>) => action.payload,
    },
});

export const { setActiveMenu } = activeMenuSlice.actions;
