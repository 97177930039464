import {
    ArrowDownIcon,
    ArrowUpIcon,
    CaretSortIcon,
    DrawingPinFilledIcon,
    DrawingPinIcon,
    EyeNoneIcon,
} from "@radix-ui/react-icons";
import { Column } from "@tanstack/react-table";
import { useCallback } from "react";

import { cn } from "src/@/lib/utils";
import { Button } from "src/@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";

interface DataTableColumnHeaderProps<TData, TValue> {
    column: Column<TData, TValue>;
    title: string | JSX.Element;
    className?: string;
    buttonClassName?: string;
    hideIconUntilSorted?: boolean;
    disableSort?: boolean;
    disablePin?: boolean;
}

export function DataTableColumnHeader<TData, TValue>({
    column,
    title,
    className,
    buttonClassName,
    hideIconUntilSorted,
    disableSort,
    disablePin,
}: DataTableColumnHeaderProps<TData, TValue>) {
    const pinColumn = useCallback(() => {
        const isPinned = !!column.getIsPinned();
        if (isPinned) {
            column.pin(false);
        } else {
            column.pin("left");
        }
    }, [column]);

    // if no actions available at all, just don't show the dropdown button.
    if (
        (!column.getCanSort() || disableSort) &&
        (!column.getCanPin() || disablePin) &&
        !column.getCanHide()
    ) {
        return <div className={cn(className)}>{title}</div>;
    }

    return (
        <div className={cn("flex items-center space-x-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        className={cn(
                            "-ml-3 h-8 rounded-md  data-[state=open]:bg-accent bg-transparent",
                            buttonClassName,
                        )}
                    >
                        <span>{title}</span>
                        {column.getIsSorted() === "desc" ? (
                            <ArrowDownIcon className="ml-2 h-4 w-4" />
                        ) : column.getIsSorted() === "asc" ? (
                            <ArrowUpIcon className="ml-2 h-4 w-4" />
                        ) : hideIconUntilSorted ? null : (
                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        )}
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    {column.getCanSort() && !disableSort ? (
                        <>
                            <DropdownMenuItem
                                onClick={() => column.toggleSorting(false)}
                            >
                                <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                Asc
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => column.toggleSorting(true)}
                            >
                                <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                Desc
                            </DropdownMenuItem>
                        </>
                    ) : null}

                    {column.getCanPin() && !disablePin ? (
                        <>
                            <DropdownMenuItem onClick={() => pinColumn()}>
                                {column.getIsPinned() ? (
                                    <DrawingPinFilledIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                ) : (
                                    <DrawingPinIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                )}
                                {column.getIsPinned() ? "Unpin" : "Pin"}
                            </DropdownMenuItem>
                        </>
                    ) : null}

                    {column.getCanHide() ? (
                        <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                                onClick={() => column.toggleVisibility(false)}
                            >
                                <EyeNoneIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                Hide
                            </DropdownMenuItem>
                        </>
                    ) : null}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
