import { useCallback, useMemo } from "react";
import { PurchaseReportTransactionSource } from "@snackpass/snackpass-types";
import { useDashboardContext } from "../../hooks/use-dashboard-context";
import { DEFINED_SOURCES } from "#pickers/channel-picker/lib";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";
import { Settings2 } from "lucide-react";

export const ChannelPicker = () => {
    const { channels, setChannels } = useDashboardContext();

    const selectedSources = useMemo(
        () => new Set(channels as string[]),
        [channels],
    );

    const handleOptionSelected = useCallback(
        (value: string) => {
            const newSelection = new Set(selectedSources);
            if (newSelection.has(value)) {
                newSelection.delete(value);
            } else {
                newSelection.add(value);
            }
            setChannels(
                Array.from(newSelection) as PurchaseReportTransactionSource[],
            );
        },
        [selectedSources, setChannels],
    );

    return (
        <FilterSelect2
            icon={Settings2}
            title="Channel"
            selectedValues={selectedSources}
            options={DEFINED_SOURCES}
            onOptionSelected={handleOptionSelected}
        />
    );
};
