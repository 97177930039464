import { useMemo } from "react";
import { TransactionSource } from "@snackpass/snackpass-types";
import { Settings2 } from "lucide-react";

import { DEFINED_SOURCES } from "#pickers/channel-picker/lib";
import { useOrders } from "../../orders-context";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";

export const OrdersChannelPicker = () => {
    const { channels, setChannels } = useOrders();

    const selectedChannels = useMemo(
        () => new Set(channels as string[]),
        [channels],
    );

    return (
        <FilterSelect2
            icon={Settings2}
            title="Channel"
            selectedValues={selectedChannels}
            options={DEFINED_SOURCES}
            onOptionSelected={function (value: string): void {
                if (selectedChannels.has(value)) {
                    selectedChannels.delete(value);
                } else {
                    selectedChannels.add(value);
                }
                setChannels(
                    Array.from(selectedChannels) as TransactionSource[],
                );
            }}
        />
    );
};
