import { ChevronRight } from "lucide-react";

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuItem,
} from "src/@/components/ui/sidebar";
import { hasActiveNestedRoute } from "../utils";
import { RouteItem, SidebarSection } from "../../types";
import { useLocation } from "react-router-dom";
import { NavButton } from "#navigation/sidebar/components/nav-button";
import { useMemo, useState, useEffect } from "react";
import { TopLevelNavItem } from "#navigation/sidebar/components/top-level-nav-item";

interface NavMainProps {
    section: SidebarSection;
}

function useHasActiveItem(items: readonly RouteItem[], pathname: string) {
    return useMemo(
        () => hasActiveNestedRoute(items as RouteItem[], pathname),
        [items, pathname],
    );
}

export function Section({ section }: NavMainProps) {
    const location = useLocation();
    const pathname = location.pathname;
    const { label, items, defaultOpen } = section;

    const hasActiveItem = useHasActiveItem(items, pathname);
    const [isOpen, setIsOpen] = useState(defaultOpen || hasActiveItem);

    useEffect(() => {
        if (hasActiveItem) {
            setIsOpen(true);
        }
    }, [hasActiveItem]);

    // shortcut for top level items
    if (!label) {
        return (
            <SidebarGroup>
                <SidebarMenu>
                    {items.map((item) => (
                        <SidebarMenuItem key={item.url}>
                            <NavButton item={item} />
                        </SidebarMenuItem>
                    ))}
                </SidebarMenu>
            </SidebarGroup>
        );
    }

    return (
        <SidebarGroup className="py-0">
            <Collapsible
                open={isOpen}
                onOpenChange={setIsOpen}
                className="group/collapsible"
            >
                <CollapsibleTrigger asChild>
                    <div className="flex items-center cursor-pointer select-none rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-sidebar-accent hover:text-sidebar-accent-foreground">
                        <SidebarGroupLabel className="font-normal select-none text-muted-foreground text-sm">
                            {label}
                        </SidebarGroupLabel>
                        <ChevronRight className="size-3 shrink-0 text-muted-foreground transition-transform duration-300 ease-in-out group-data-[state=open]/collapsible:rotate-90" />
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent className="relative overflow-hidden">
                    <SidebarMenu className="py-1 mb-0">
                        {items.map((item) => (
                            <SidebarMenuItem key={item.url}>
                                <TopLevelNavItem
                                    item={item}
                                    pathname={pathname}
                                />
                            </SidebarMenuItem>
                        ))}
                    </SidebarMenu>
                </CollapsibleContent>
            </Collapsible>
        </SidebarGroup>
    );
}
