import {
    BarChart3,
    BookOpen,
    Briefcase,
    ChefHat,
    LayoutDashboard,
    LifeBuoy,
    MessageCircle,
    Monitor,
    Rocket,
    ShoppingCart,
    Store,
    Users,
    Search,
    BabyIcon,
    Globe,
    Utensils,
    Clock,
    Landmark,
    Lock,
    Settings2,
    Coins,
    Users2,
    MoreHorizontal,
    ShoppingBasket,
    BookUser,
    SquarePercent,
    Gift,
    PiggyBank,
    Map,
    Truck,
    HandHeart,
    History,
    MessagesSquare,
    Star,
    Megaphone,
    ShoppingBag,
    QrCode,
    Brush,
    UtensilsCrossed,
    MessageSquareDot,
    BadgePercent,
    Smartphone,
} from "lucide-react";
import { Routes } from "#navigation/routes";
import { RouteItem, RouteOnClick, StorePermission } from "./types";

// Store slug state management
export let currentStoreSlug = "";
export const setStoreSlug = (slug: string) => {
    currentStoreSlug = slug;
};

// See README.md for detailed documentation on route items and navigation

export const AVAILABLE_BEFORE_ONBOARDING_COMPLETE = [
    Routes.Onboarding,
    Routes.Support,
    Routes.Menu,
    Routes.MenuEditor,
    Routes.Promotion,
    Routes.MultiMenus,
    Routes.MenuManager,
    Routes.MenuInventoryManager,
    Routes.SettingsTeam,
    Routes.SettingsPermissions,
    Routes.SnackpassAdmin,
    Routes.InternalSettings,
    Routes.InternalFees,
    Routes.InternalDevices,
    Routes.InternalSeniorSupport,
    Routes.Other,
    Routes.NotAllowed,
    Routes.Logout,
    Routes.Devices,
];

export const INTERNAL_ROUTES = [
    Routes.SnackpassAdmin,
    Routes.InternalSettings,
    Routes.InternalFees,
    Routes.InternalSeniorSupport,
    Routes.InternalDevices,
    Routes.CreatePushCampaign,
];

export const RouteItems: Record<Routes, RouteItem> = {
    // Quick Actions
    [Routes.Onboarding]: {
        title: "Welcome",
        url: Routes.Onboarding,
        icon: BabyIcon,
        permission: StorePermission.HasDashboard,
    },
    [Routes.WhatsNew]: {
        title: "What's New",
        url: Routes.WhatsNew,
        icon: BabyIcon,
        permission: StorePermission.HasDashboard,
    },
    [Routes.Support]: {
        url: Routes.Support,
        title: "Support",
        icon: LifeBuoy,
        onClick: RouteOnClick.Intercom,
    },
    [Routes.Search]: {
        url: Routes.Search,
        title: "Search",
        icon: Search,
        onClick: RouteOnClick.GlobalSearch,
    },

    // Store/Platform
    [Routes.Index]: {
        title: "Dashboard",
        url: Routes.Index,
        icon: LayoutDashboard,
        searchKeywords: ["net sales", "ticket size", "dashboard"],
        permission: StorePermission.HasDashboard,
    },
    [Routes.BetaOrders]: {
        title: "Orders",
        url: Routes.BetaOrders,
        icon: ShoppingCart,
        searchKeywords: ["order summary", "order history"],
        permission: StorePermission.HasOrders,
    },
    [Routes.OrdersDetails]: {
        title: "Order Details",
        url: Routes.OrdersDetails,
        permission: StorePermission.HasOrders,
    },
    [Routes.PurchaseDetails]: {
        title: "Purchase Details",
        url: Routes.PurchaseDetails,
        permission: StorePermission.HasOrders,
    },

    // Menu
    [Routes.Menu]: {
        url: Routes.Menu,
        title: "Menu",
        icon: BookOpen,
        searchKeywords: ["menu", "menu editor", "menu items"],
        permission: StorePermission.HasMenuEditing,
        isNavigationOnly: true,
    },
    [Routes.MenuEditor]: {
        title: "Items",
        url: Routes.MenuEditor,
        searchKeywords: ["new item", "menu edit", "menu editor"],
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MenuManager]: {
        title: "Menus",
        url: Routes.MenuManager,
        searchKeywords: ["special menus", "menu manager"],
        permission: StorePermission.HasMenuEditing,
        nonSidebarSubpages: [Routes.NEW_MENU, Routes.EDIT_MENU],
    },
    [Routes.NEW_MENU]: {
        title: "New Menu",
        url: Routes.NEW_MENU,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.EDIT_MENU]: {
        title: "Edit Menu",
        url: Routes.EDIT_MENU,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MenuInventoryManager]: {
        title: "Ingredients",
        url: Routes.MenuInventoryManager,
        isBeta: true,
        searchKeywords: ["inventory", "inventory manager", "ingredients"],
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenus]: {
        title: "Third Party",
        url: Routes.MultiMenus,
        isBeta: true,
        searchKeywords: [
            "new menu",
            "additional menus",
            "third party",
            "deliverect",
        ],
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusCreate]: {
        title: "Create Multi Menu",
        url: Routes.MultiMenusCreate,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusOutline]: {
        title: "Multi Menu Outline",
        url: Routes.MultiMenusOutline,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusEdit]: {
        title: "Edit Multi Menu",
        url: Routes.MultiMenusEdit,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusProductOverridesEdit]: {
        title: "Edit Product Overrides",
        url: Routes.MultiMenusProductOverridesEdit,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusAddonOverridesEdit]: {
        title: "Edit Addon Overrides",
        url: Routes.MultiMenusAddonOverridesEdit,
        permission: StorePermission.HasMenuEditing,
    },
    [Routes.MultiMenusSettings]: {
        title: "Multi Menu Settings",
        url: Routes.MultiMenusSettings,
        permission: StorePermission.HasMenuEditing,
    },

    // Growth - Guestbook
    [Routes.Guestbook]: {
        url: Routes.Guestbook,
        title: "Guestbook",
        icon: MessageCircle,
        searchKeywords: ["guestbook", "messages", "customers"],
        isNavigationOnly: true,
        permission: StorePermission.HasGuestbook,
    },
    [Routes.GuestbookConversations]: {
        title: "Conversations",
        url: Routes.GuestbookConversations,
        searchKeywords: ["conversations", "message", "guestbook", "chat"],
        permission: StorePermission.HasGuestbook,
        icon: MessagesSquare,
    },
    [Routes.GuestbookCustomers]: {
        title: "Loyalty",
        url: Routes.GuestbookCustomers,
        searchKeywords: ["customers", "loyalty", "guestbook"],
        permission: StorePermission.HasGuestbook,
        icon: Star,
    },
    [Routes.GuestbookAuditLog]: {
        title: "Audit Log",
        url: Routes.GuestbookAuditLog,
        searchKeywords: ["customers", "guestbook", "audit log"],
        permission: StorePermission.HasGuestbook,
        icon: History,
    },
    [Routes.GuestbookCampaignsSMS]: {
        title: "SMS Campaigns",
        url: Routes.GuestbookCampaignsSMS,
        searchKeywords: ["campaigns", "sms", "new campaign", "guestbook"],
        permission: StorePermission.HasGuestbook,
        icon: Megaphone,
    },
    [Routes.GuestbookCampaignsSMSDetails]: {
        title: "SMS Campaign Details",
        url: Routes.GuestbookCampaignsSMSDetails,
        permission: StorePermission.HasGuestbook,
    },
    [Routes.GuestbookCampaignsPush]: {
        title: "Push Campaigns",
        url: Routes.GuestbookCampaignsPush,
        searchKeywords: [
            "push",
            "push notifications",
            "send messages",
            "messages",
        ],
        permission: StorePermission.HasGuestbook,
        icon: MessageSquareDot,
        nonSidebarSubpages: [
            Routes.GuestbookCampaignsPushDetails,
            Routes.CreatePushCampaign,
        ],
    },
    [Routes.CreatePushCampaign]: {
        title: "Create",
        url: Routes.CreatePushCampaign,
        permission: StorePermission.HasGuestbook,
    },
    [Routes.GuestbookCampaignsPushDetails]: {
        title: "Push Campaign Details",
        url: Routes.GuestbookCampaignsPushDetails,
        permission: StorePermission.HasGuestbook,
    },

    // Growth - Marketing
    [Routes.Marketing]: {
        url: Routes.Marketing,
        title: "Marketing",
        icon: Rocket,
        searchKeywords: ["marketing", "campaigns", "promotions"],
        isNavigationOnly: true,
        permission: StorePermission.HasDashboard,
    },
    [Routes.Promotion]: {
        title: "Promos",
        url: Routes.Promotion,
        searchKeywords: [
            "bogo",
            "promotions",
            "promos",
            "buy one get one",
            "happy hour",
            "promo code",
            "first-time customer",
            "deal drop",
            "student",
            "gift cards",
            "punchcard",
            "referral",
            "group buy",
            "party",
            "current promos",
            "discounts",
        ],
        permission: StorePermission.HasPromos,
        icon: BadgePercent,
        nonSidebarSubpages: [
            Routes.CreatePromotionGeneric,
            Routes.CreatePromotionReward,
            Routes.CreatePromotionGiftCard,
        ],
    },
    [Routes.CreatePromotionAudience]: {
        title: "Audience",
        url: Routes.CreatePromotionAudience,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionGeneric]: {
        title: "Generic",
        url: Routes.CreatePromotionGeneric,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionReward]: {
        title: "Reward",
        url: Routes.CreatePromotionReward,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionHappyHour]: {
        title: "Happy Hour",
        url: Routes.CreatePromotionHappyHour,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionBogo]: {
        title: "Buy One Get One",
        url: Routes.CreatePromotionBogo,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionStudents]: {
        title: "Students",
        url: Routes.CreatePromotionStudents,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionFirstTime]: {
        title: "First Time",
        url: Routes.CreatePromotionFirstTime,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionDealDrop]: {
        title: "Deal Drop",
        url: Routes.CreatePromotionDealDrop,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionPromoCode]: {
        title: "Promo Code",
        url: Routes.CreatePromotionPromoCode,
        permission: StorePermission.HasPromos,
    },
    [Routes.CreatePromotionGiftCard]: {
        title: "Gift Card",
        url: Routes.CreatePromotionGiftCard,
        permission: StorePermission.HasPromos,
    },
    [Routes.SettingsGiftCard]: {
        title: "Gift Cards",
        url: Routes.SettingsGiftCard,
        searchKeywords: ["gift card", "digital gift card", "sell gift card"],
        permission: StorePermission.HasPromos,
        icon: Gift,
    },

    // Growth - Digital Presence
    [Routes.DigitalPresence]: {
        url: Routes.DigitalPresence,
        title: "Digital Presence",
        icon: Globe,
        searchKeywords: ["digital presence", "online presence", "web presence"],
        permission: StorePermission.HasDashboard,
        isNavigationOnly: true,
    },
    [Routes.DigitalPresenceOnlineOrdering]: {
        title: "Online Ordering",
        url: Routes.DigitalPresenceOnlineOrdering,
        searchKeywords: ["online ordering", "online menu", "online presence"],
        permission: StorePermission.HasDashboard,
        externalUrl: (slug: string) =>
            `https://order.snackpass.co/${slug || ""}`,
        icon: ShoppingBag,
    },
    [Routes.SettingsQrCode]: {
        title: "QR Codes",
        url: Routes.SettingsQrCode,
        searchKeywords: ["qr codes", "qr", "online ordering", "dine-in qr"],
        permission: StorePermission.HasDashboard,
        icon: QrCode,
    },
    [Routes.DigitalPresenceApp]: {
        title: "Custom App",
        url: Routes.DigitalPresenceApp,
        searchKeywords: ["app", "mobile app", "online ordering app"],
        permission: StorePermission.HasDashboard,
        externalUrl:
            "https://airtable.com/appy8eQbMkVKrsnZ4/paglW8OzypSkSbqep/form",
        icon: Smartphone,
    },
    [Routes.DigitalPresenceWebsite]: {
        title: "Website",
        url: Routes.DigitalPresenceWebsite,
        searchKeywords: ["website", "online presence", "website builder"],
        permission: StorePermission.HasDashboard,
        isBeta: true,
        externalUrl:
            "https://airtable.com/appy8eQbMkVKrsnZ4/pagwhjpPkzA0a3vgS/form",
        icon: Brush,
    },
    [Routes.SettingsCatering]: {
        title: "Catering",
        url: Routes.SettingsCatering,
        searchKeywords: ["catering", "catering menu", "catering orders"],
        permission: StorePermission.HasDashboard,
        icon: UtensilsCrossed,
    },

    // Growth - Business Tools
    [Routes.BusinessTools]: {
        url: Routes.BusinessTools,
        title: "Business Tools",
        icon: Briefcase,
        permission: StorePermission.HasDashboard,
        isNavigationOnly: true,
    },
    [Routes.BuyHardware]: {
        title: "Buy Hardware",
        url: Routes.BuyHardware,
        searchKeywords: ["hardware", "buy hardware", "equipment"],
        permission: StorePermission.HasDashboard,
        externalUrl: "https://store.snackpass.co/",
    },
    [Routes.Invoices]: {
        title: "Invoices",
        url: Routes.Invoices,
        searchKeywords: ["invoices", "invoice", "billing"],
        permission: StorePermission.HasDashboard,
    },
    [Routes.SettingsIntegrations]: {
        title: "Integrations",
        url: Routes.SettingsIntegrations,
        permission: StorePermission.HasSettings,
    },

    // Data - Financial Reports
    [Routes.FinancialReports]: {
        title: "More",
        url: Routes.FinancialReports,
        icon: MoreHorizontal,
        permission: StorePermission.HasSalesReports,
        isNavigationOnly: true,
    },
    [Routes.FinancialReportsPayoutHistory]: {
        title: "Payouts",
        url: Routes.FinancialReportsPayoutHistory,
        permission: StorePermission.HasFinancialReports,
        searchKeywords: ["payouts", "payout history", "deposits"],
    },
    [Routes.FinancialReportsCash]: {
        title: "Cash",
        url: Routes.FinancialReportsCash,
        permission: StorePermission.HasFinancialReports,
        icon: Coins,
    },
    [Routes.FinancialReportsEssentials]: {
        title: "Reports",
        url: Routes.FinancialReportsEssentials,
        permission: StorePermission.HasFinancialReports,
        searchKeywords: [
            "reports",
            "reports overview",
            "sales",
            "tips",
            "net sales",
            "gross sales",
        ],
    },

    // Data - Reports
    [Routes.ReportsOverview]: {
        title: "Overview Unused",
        url: Routes.ReportsOverview,
        permission: StorePermission.HasSalesReports,
        searchKeywords: [
            "reports",
            "reports overview",
            "sales",
            "tips",
            "net sales",
            "gross sales",
        ],
    },
    [Routes.SalesReports]: {
        title: "Sales",
        url: Routes.SalesReports,
        permission: StorePermission.HasSalesReports,
        isNavigationOnly: true,
        icon: PiggyBank,
    },
    [Routes.Reports]: {
        title: "More Reports",
        url: Routes.Reports,
        permission: StorePermission.HasDashboard,
        icon: BarChart3,
        isNavigationOnly: true,
    },
    [Routes.ReportsSales]: {
        title: "Sales",
        url: Routes.ReportsSales,
        permission: StorePermission.HasSalesReports,
        isNavigationOnly: true,
    },
    [Routes.ReportsSalesSummary]: {
        title: "Comparisons",
        url: Routes.ReportsSalesSummary,
        searchKeywords: ["sales graphs"],
        permission: StorePermission.HasSalesReports,
    },
    [Routes.ReportsSalesChannels]: {
        title: "Third Parties",
        url: Routes.ReportsSalesChannels,
        searchKeywords: ["sales providers", "sales channels", "sales report"],
        permission: StorePermission.HasSalesReports,
        icon: Truck,
    },
    [Routes.ReportsMenu]: {
        title: "Menu",
        url: Routes.ReportsMenu,
        permission: StorePermission.HasReportsMenu,
        isNavigationOnly: true,
        icon: ShoppingBasket,
    },
    [Routes.ReportsMenuItemInsights]: {
        title: "Items",
        url: Routes.ReportsMenuItemInsights,
        searchKeywords: ["menu items", "menu insights", "menu statistics"],
        permission: StorePermission.HasReportsMenu,
    },
    [Routes.ReportsMenuCategoryInsights]: {
        title: "Categories",
        url: Routes.ReportsMenuCategoryInsights,
        searchKeywords: ["menu categories", "menu insights", "menu statistics"],
        permission: StorePermission.HasReportsMenu,
    },
    [Routes.ReportsCustomerDirectoryInsights]: {
        title: "Customers",
        url: Routes.ReportsCustomerDirectoryInsights,
        searchKeywords: [
            "customers",
            "customer directory",
            "customer insights",
        ],
        permission: StorePermission.HasReportsCustomers,
        icon: BookUser,
    },
    [Routes.ReportsPromotions]: {
        title: "Promotions",
        url: Routes.ReportsPromotions,
        searchKeywords: [
            "promotions",
            "promo codes",
            "promo campaigns",
            "promo discounts",
        ],
        permission: StorePermission.HasReportsPromotions,
        icon: SquarePercent,
    },
    [Routes.ReportsGiftCard]: {
        title: "Gift Cards",
        url: Routes.ReportsGiftCard,
        permission: StorePermission.HasReportsGiftCards,
        icon: Gift,
    },
    [Routes.ReportsGiftCardPurchases]: {
        title: "Overview",
        url: Routes.ReportsGiftCardPurchases,
        searchKeywords: [
            "gift card overview",
            "gift card purchases",
            "gift card transactions",
        ],
        permission: StorePermission.HasReportsGiftCards,
    },
    [Routes.ReportsGiftCardBalances]: {
        title: "Balances",
        url: Routes.ReportsGiftCardBalances,
        searchKeywords: [
            "gift card balances",
            "gift card transactions",
            "gift card reports",
        ],
        permission: StorePermission.HasReportsGiftCards,
    },
    [Routes.ReportsGiftCardTransactions]: {
        title: "Transactions",
        url: Routes.ReportsGiftCardTransactions,
        permission: StorePermission.HasReportsGiftCards,
    },
    [Routes.ReportsGiftCardLiabilities]: {
        title: "Liabilities",
        url: Routes.ReportsGiftCardLiabilities,
        permission: StorePermission.HasReportsGiftCards,
    },
    [Routes.ReportsLocation]: {
        title: "Locations",
        url: Routes.ReportsLocation,
        permission: StorePermission.HasReportsLocations,
        isNavigationOnly: true,
        icon: Map,
    },
    [Routes.ReportsLocationSales]: {
        title: "Sales",
        url: Routes.ReportsLocationSales,
        permission: StorePermission.HasReportsLocations,
    },
    [Routes.ReportsLocationMenuCategories]: {
        title: "Categories",
        url: Routes.ReportsLocationMenuCategories,
        permission: StorePermission.HasReportsLocations,
    },
    [Routes.ReportsLocationMenuItems]: {
        title: "Items",
        url: Routes.ReportsLocationMenuItems,
        permission: StorePermission.HasReportsLocations,
    },

    // Data - Labor
    [Routes.Labor]: {
        title: "Labor",
        url: Routes.Labor,
        icon: Users2,
        permission: StorePermission.HasReportsLabor,
        isNavigationOnly: true,
    },

    // Data - Time Cards
    [Routes.EmployeeTimeCards]: {
        title: "Time Cards",
        url: Routes.EmployeeTimeCards,
        icon: Clock,
        permission: StorePermission.HasEmployees,
    },
    [Routes.Tipping]: {
        title: "Tips",
        url: Routes.Tipping,
        permission: StorePermission.HasReportsLabor,
        icon: HandHeart,
        searchKeywords: ["tips", "tipping", "tips report", "labor report"],
    },
    [Routes.MinuteToMinute]: {
        title: "Hourly",
        url: Routes.MinuteToMinute,
        permission: StorePermission.HasSalesReports,
        searchKeywords: [
            "hourly",
            "tips hourly",
            "sales hourly",
            "minute to minute",
            "minute to minute reports",
            "hourly",
        ],
    },

    // Settings - Store
    [Routes.Settings]: {
        title: "Settings",
        url: Routes.Settings,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsBusinessInfo]: {
        title: "Store",
        url: Routes.SettingsBusinessInfo,
        icon: Store,
        searchKeywords: [
            "business info",
            "manage information",
            "display about store",
            "store display",
            "store id",
            "copy store id",
            "store location",
            "store name",
            "store appearance",
            "customers",
            "store address",
            "ordering page",
            "app page",
            "store timezone",
            "store type",
            "contact information",
            "store phone number",
            "phone number",
            "store email address",
            "account information",
            "subscriptions",
            "tax",
            "schedule ahead orders",
            "store hours",
            "time zone",
            "store schedule",
            "special hours",
            "override store hours",
            "cancel scheduled orders",
            "third party delivery hours",
            "holiday hours",
        ],
        permission: StorePermission.HasSettings,
    },

    // Settings - Team
    [Routes.SettingsTeam]: {
        title: "Team",
        url: Routes.SettingsTeam,
        icon: Users,
        permission: StorePermission.HasEmployees,
        isNavigationOnly: true,
    },
    [Routes.SettingsPermissions]: {
        title: "Users",
        url: Routes.SettingsPermissions,
        searchKeywords: ["users", "permissions", "team management"],
        permission: StorePermission.HasEmployees,
    },
    [Routes.SettingsEmployees]: {
        title: "Clock In and Out",
        url: Routes.SettingsEmployees,
        searchKeywords: ["clock in", "clock out", "employee time cards"],
        permission: StorePermission.HasSettings,
    },

    // Settings - Devices
    [Routes.Devices]: {
        title: "Devices",
        url: Routes.Devices,
        icon: Monitor,
        permission: StorePermission.HasDevices,
    },

    // Settings - Front of House
    [Routes.SettingsFrontOfHouse]: {
        title: "Front of House",
        url: Routes.SettingsFrontOfHouse,
        icon: Utensils,
        permission: StorePermission.HasSettings,
        isNavigationOnly: true,
    },
    [Routes.SettingsBrandAndAppearance]: {
        title: "Brand and Appearance",
        url: Routes.SettingsBrandAndAppearance,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsTipping]: {
        title: "Tipping",
        url: Routes.SettingsTipping,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsAllOrders]: {
        title: "All Orders",
        url: Routes.SettingsAllOrders,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsOnlineOrders]: {
        title: "Online Orders",
        url: Routes.SettingsOnlineOrders,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsPlatforms]: {
        title: "Kiosk and Register",
        url: Routes.SettingsPlatforms,
        searchKeywords: ["kiosk", "register", "point of sale", "POS"],
        permission: StorePermission.HasSettings,
    },

    // Settings - Back of House
    [Routes.SettingsBOH]: {
        title: "Back of House",
        url: Routes.SettingsBOH,
        icon: ChefHat,
        permission: StorePermission.HasSettings,
        isNavigationOnly: true,
    },
    [Routes.SettingsBackOfHouse]: {
        title: "Order Flow",
        url: Routes.SettingsBackOfHouse,
        searchKeywords: ["order flow", "order management", "order tracking"],
        permission: StorePermission.HasSettings,
    },
    [Routes.PrepStations]: {
        title: "Prep Stations",
        url: Routes.PrepStations,
        searchKeywords: [
            "prep stations",
            "kitchen equipment",
            "kitchen layout",
        ],
        permission: StorePermission.HasDevices,
    },
    [Routes.SettingsStoreTablesLayout]: {
        title: "Table Editor",
        url: Routes.SettingsStoreTablesLayout,
        searchKeywords: ["table editor", "store layout", "table management"],
        permission: StorePermission.HasSettings,
    },

    // Settings - Account and Tax
    [Routes.AccountAndTax]: {
        title: "Account & Tax",
        url: Routes.AccountAndTax,
        icon: Landmark,
        permission: StorePermission.HasPayouts,
        isNavigationOnly: true,
    },
    [Routes.Billing]: {
        title: "Billing",
        url: Routes.Billing,
        permission: StorePermission.HasBilling,
    },
    [Routes.Payouts]: {
        title: "Bank Account",
        url: Routes.Payouts,
        searchKeywords: ["account", "financial", "payouts", "bank"],
        permission: StorePermission.HasPayouts,
    },
    [Routes.PayoutsSchedule]: {
        title: "Payout Schedule",
        url: Routes.PayoutsSchedule,
        searchKeywords: [
            "payout schedule",
            "payout periods",
            "payout management",
        ],
        permission: StorePermission.HasPayouts,
    },
    [Routes.TaxForms]: {
        title: "Tax Forms",
        url: Routes.TaxForms,
        searchKeywords: ["tax forms", "tax returns", "tax preparation"],
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsTax]: {
        title: "Tax Rates",
        url: Routes.SettingsTax,
        searchKeywords: ["tax rates", "tax calculations", "tax management"],
        permission: StorePermission.HasSettings,
    },

    // Settings - Other
    [Routes.Other]: {
        title: "Other",
        url: Routes.Other,
        icon: Settings2,
        permission: StorePermission.HasSettings,
        isNavigationOnly: true,
    },
    [Routes.SettingsProducts]: {
        title: "Products",
        url: Routes.SettingsProducts,
        searchKeywords: [
            "products",
            "product management",
            "inventory management",
        ],
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsOrders]: {
        title: "Orders",
        url: Routes.SettingsOrders,
        permission: StorePermission.HasSettings,
    },
    [Routes.SettingsLegal]: {
        title: "Legal",
        url: Routes.SettingsLegal,
        permission: StorePermission.HasSettings,
    },

    // Settings - Snackpass Admin
    [Routes.SnackpassAdmin]: {
        title: "Snackpass",
        url: Routes.SnackpassAdmin,
        icon: Lock,
    },
    [Routes.InternalSettings]: {
        title: "General",
        url: Routes.InternalSettings,
        searchKeywords: ["general", "settings", "internal settings"],
    },
    [Routes.InternalFees]: {
        title: "Fee Settings",
        url: Routes.InternalFees,
        searchKeywords: ["fees", "fee settings", "internal fees"],
    },
    [Routes.InternalSeniorSupport]: {
        title: "Senior Support",
        url: Routes.InternalSeniorSupport,
        searchKeywords: ["senior support", "internal senior support"],
    },
    [Routes.InternalDevices]: {
        title: "Search Devices",
        url: Routes.InternalDevices,
        searchKeywords: ["devices", "internal devices"],
    },

    // Others
    [Routes.MyAccountTeams]: {
        title: "Teams",
        url: Routes.MyAccountTeams,
    },
    [Routes.SettingsAccount]: {
        title: "Account",
        url: Routes.SettingsAccount,
    },
    [Routes.NewUserAcceptInvite]: {
        title: "Accept Invite",
        url: Routes.NewUserAcceptInvite,
    },
    [Routes.NotAllowed]: {
        title: "Not Allowed",
        url: Routes.NotAllowed,
    },
    [Routes.NotFound]: {
        title: "Not Found",
        url: Routes.NotFound,
    },
    [Routes.Logout]: {
        title: "Logout",
        url: Routes.Logout,
    },
};
