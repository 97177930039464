import { Event, EventHint } from "@sentry/react";
import axios from "axios";

import { ErrorWithCause } from "src/utils/errors";

const findBaseError = (exception: ErrorWithCause | unknown): unknown => {
    // We wrap ErrorWithCause in various places, usually on Axios errors, but at most this is 2 levels deep.
    if (exception instanceof ErrorWithCause) {
        return findBaseError(exception.cause);
    }
    return exception;
};

/**
 * This function should be passed as `beforeSend` in the arguments in
 * `Sentry.init`.
 *
 * This function is used to add additional tags on axios errors so we can easily
 * classify them in sentry under the "tags" feature in an individual error.
 */
export const beforeSend = (event: Event, hint: EventHint): Event => {
    if (window.PartnerAppJsApi) {
        event.tags = {
            ...event.tags,
            partnerApp: true,
        };
    }

    const exception = hint.originalException;
    const baseError = findBaseError(exception);

    if (axios.isAxiosError(baseError)) {
        // Axios throws an AxiosError when the HTTP request fails. It can be hard to know exactly
        // what is happening on these errors, so we want to extract some additional tags to see
        // what is happening
        event.tags = {
            baseUrl: baseError.config?.baseURL,
            endpoint: baseError.config?.url,
            status: baseError.response?.status,
            ...event.tags,
        };
    }

    return event;
};
