import * as Sentry from "@sentry/react";
import axios from "axios";

const isErrorLike = (e: unknown): boolean =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e as any)?.stack && (e as any)?.message;

export const sendError = (e: unknown): void => {
    /**
     * We do not want to group errors by StackTrace for ErrorWithCause otherwise
     * it will group all ErrorWithCause exceptions together, so we manually set
     * the fingerprint here.
     */
    if (e instanceof ErrorWithCause) {
        Sentry.captureException(e, { fingerprint: [e.message, e.cause] });
        return;
    }

    /**
     * Capture errors directly.
     */
    if (e instanceof Error || isErrorLike(e)) {
        Sentry.captureException(e);
        return;
    }

    /**
     * If e is not error like, we have some work to do...
     */
    Sentry.withScope((scope) => {
        if (!(e instanceof Error)) {
            // this gives us a full serialization of the error entity
            scope.setExtra("thrownEntity", e);
        }
        Sentry.captureException(e);
    });
};

const bypassByCode = (code?: number) =>
    code ? [400, 401, 403, 404].includes(code) : false;

const bypassByMessage = (message?: string) =>
    message ? ["timeout of 120000ms exceeded"].includes(message) : false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logAndSendError = (e: any): void => {
    // bypass sentry for unauthorized and forbidden
    if (e instanceof ErrorWithCause && axios.isAxiosError(e.cause)) {
        if (bypassByCode(e.cause.status)) {
            console.warn(e.cause.message ?? "Unauthorized.");
            return;
        }
        if (bypassByCode(e.cause.response?.status)) {
            console.warn(e.cause.response?.data?.message ?? "Unauthorized.");
            return;
        }
        if (bypassByMessage(e.cause.message)) {
            console.warn(e.cause.message);
            return;
        }
    }

    // otherwise warn and report to sentry
    console.warn(e);
    sendError(e);
};

export class ErrorWithCause extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cause?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message?: string, cause?: any) {
        super(message);
        if (cause) {
            this.cause = cause;
        }
    }
}

export class UserFacingError extends Error {
    constructor(message: string) {
        super(message);
    }
}
