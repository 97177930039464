import type { PurchaseReportTransactionSource } from "@snackpass/snackpass-types";

export const TRANSACTION_SOURCES: PurchaseReportTransactionSource[] = [
    "app",
    "kiosk",
    "online",
    "thirdParty",
    "register",
];

export const DEFINED_SOURCES: {
    label: string;
    value: PurchaseReportTransactionSource;
}[] = [
    {
        label: "App",
        value: "app",
    },
    {
        label: "Kiosk",
        value: "kiosk",
    },
    {
        label: "Online",
        value: "online",
    },
    {
        label: "Integration",
        value: "thirdParty",
    },
    {
        label: "Register",
        value: "register",
    },
];
