import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AlertCircle } from "lucide-react";

import { Routes } from "#navigation/routes";
import { getUser } from "src/redux/selectors";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";

const NoAccessPage = () => {
    const user = useSelector(getUser);
    const userIdentifier = user?.email || user?.number || "No user";

    return (
        <Alert className="mx-auto mt-8 w-fit" variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>You do not have access to this page.</AlertTitle>
            <AlertDescription className="mt-2">
                <div>
                    See your teams and permissions on your{" "}
                    <Link to={Routes.MyAccountTeams}>Teams Page</Link>.
                </div>
                <div>
                    Please contact your store administrator if you need further
                    assistance.
                </div>
                <div>You are logged in as: {userIdentifier}</div>
            </AlertDescription>
        </Alert>
    );
};

export default NoAccessPage;
