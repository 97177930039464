import { useState, useMemo } from "react";
import { Archive, Check, ChevronsUpDown } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";

import { cn } from "src/@/lib/utils";
import { Button } from "src/@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "src/@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { useMyAdminStores } from "#store-selector/hooks";
import { setActiveStoreIdSliceId } from "src/redux/slices";
import { getActiveStore, getActiveStoreLogoUrl } from "src/redux/selectors";
import {
    Drawer,
    DrawerContent,
    DrawerTrigger,
} from "src/@/components/ui/drawer";
import { useMediaQuery } from "src/@/hooks/use-media-query";

const MAX_RESULTS = 20;

export function StoreCombobox() {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const isDesktop = useMediaQuery("(min-width: 768px)");

    const { stores, isPending } = useMyAdminStores();
    const hasMultipleStores = stores.length > 1;
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const activeStoreLogoUrl = useSelector(getActiveStoreLogoUrl);
    const nonArchivedStores = useMemo(
        () => stores.filter((store) => !store.isArchived),
        [stores],
    );

    const fuse = useMemo(() => {
        return new Fuse(nonArchivedStores, {
            keys: [
                { name: "name", weight: 2 }, // Give name matches higher priority
                { name: "address", weight: 1 },
                { name: "_id", weight: 1 },
            ],
            isCaseSensitive: false,
            threshold: 0.3, // Slightly more lenient threshold
            distance: 100, // Allow matches to be further apart
            shouldSort: true,
            ignoreLocation: true,
            minMatchCharLength: 2, // Require at least 2 characters to match
        });
    }, [nonArchivedStores]);

    const filteredStores = useMemo(() => {
        if (!searchQuery) {
            return nonArchivedStores.slice(0, MAX_RESULTS);
        }

        // Get fuzzy search results
        const fuseResults = fuse.search(searchQuery);

        return fuseResults.map((result) => result.item).slice(0, MAX_RESULTS);
    }, [nonArchivedStores, searchQuery, fuse]);

    const handleSelect = (currentValue: string) => {
        dispatch(setActiveStoreIdSliceId(currentValue));
        setOpen(false);
        setSearchQuery("");
    };

    if (isPending || !activeStore) {
        return null;
    }

    const StoreHeader = () => (
        <div className="flex items-center w-full">
            {activeStoreLogoUrl ? (
                <div
                    className="aspect-square size-8 rounded-lg bg-sidebar-primary text-sidebar-primary-foreground bg-cover bg-center bg-no-repeat"
                    style={{
                        backgroundImage: `url(${activeStoreLogoUrl})`,
                    }}
                    aria-label={activeStore.name}
                />
            ) : (
                <div className="aspect-square size-8 rounded-lg bg-sidebar-primary text-sidebar-primary-foreground flex items-center justify-center">
                    <span className="text-sm font-medium">
                        {activeStore.name.charAt(0).toUpperCase()}
                    </span>
                </div>
            )}
            <div className="grid flex-1 text-left text-sm leading-tight ml-3">
                <span className="truncate font-semibold">
                    {activeStore.name}
                </span>
                <span className="truncate text-xs">{activeStore.address}</span>
            </div>
            {hasMultipleStores && (
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            )}
        </div>
    );

    if (!hasMultipleStores) {
        return <StoreHeader />;
    }

    if (isDesktop) {
        return (
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between hover:bg-transparent p-0"
                    >
                        <StoreHeader />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    className="w-[300px] p-0"
                    align="start"
                    sideOffset={8}
                >
                    <StoreSelector
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        filteredStores={filteredStores}
                        activeStore={activeStore}
                        handleSelect={handleSelect}
                    />
                </PopoverContent>
            </Popover>
        );
    }

    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerTrigger asChild>
                <Button
                    variant="ghost"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between hover:bg-transparent p-0"
                >
                    <StoreHeader />
                </Button>
            </DrawerTrigger>
            <DrawerContent className="h-[50vh]">
                <div className="mt-4 border-t">
                    <StoreSelector
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        filteredStores={filteredStores}
                        activeStore={activeStore}
                        handleSelect={handleSelect}
                    />
                </div>
            </DrawerContent>
        </Drawer>
    );
}

function StoreSelector({
    searchQuery,
    setSearchQuery,
    filteredStores,
    activeStore,
    handleSelect,
}: {
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    filteredStores: any[];
    activeStore: any;
    handleSelect: (value: string) => void;
}) {
    return (
        <Command>
            <CommandInput
                placeholder="Search store..."
                value={searchQuery}
                onValueChange={setSearchQuery}
            />
            <CommandList>
                <CommandEmpty>No store found.</CommandEmpty>
                <CommandGroup>
                    {filteredStores.map((store) => (
                        <CommandItem
                            key={store._id}
                            value={store.name}
                            keywords={[store._id]}
                            onSelect={() => handleSelect(store._id)}
                            className={cn(
                                "flex items-center justify-between",
                                activeStore._id === store._id && "bg-accent",
                            )}
                        >
                            <div className="flex flex-col flex-1">
                                <div className="flex items-center gap-2">
                                    {store?.isArchived && (
                                        <Archive className="h-3 w-3 text-muted-foreground shrink-0" />
                                    )}
                                    <span className="font-medium">
                                        {store.name}
                                    </span>
                                </div>
                                <span className="text-xs text-muted-foreground">
                                    {store.address}
                                </span>
                            </div>
                            {activeStore._id === store._id && (
                                <Check className="h-4 w-4 shrink-0 opacity-100" />
                            )}
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    );
}
