import { useUnread } from "#navigation/sidebar/hooks/use-unread";
import { RouteItem } from "#navigation/types";
import { ExternalLink } from "lucide-react";
import { Badge } from "src/@/components/ui/badge";
import { cn } from "src/@/lib/utils";
import { useLocation } from "react-router-dom";
import { isUrlActive } from "../utils";
import { useMemo } from "react";

export function NavButtonContent({
    item,
    children,
}: {
    item: RouteItem;
    children?: React.ReactNode;
}) {
    const { getRouteUnread } = useUnread();
    const unreadCount = getRouteUnread(item.url);
    const { pathname } = useLocation();

    // Check if this item is active
    const isActive = useMemo(() => {
        const checkActive = (itemToCheck: RouteItem): boolean => {
            return isUrlActive(pathname, itemToCheck.url || "");
        };

        return checkActive(item);
    }, [pathname, item]);

    return (
        <>
            {item.icon && (
                <item.icon
                    className={cn("size-4", isActive && "text-blue-600")}
                    color={isActive ? "#0099FF" : undefined}
                />
            )}
            <span
                className={cn(
                    "flex items-center gap-2",
                    isActive && "text-blue-600",
                )}
            >
                <span className="flex-1">{item.title}</span>
                {item.isBeta && <Badge variant="outline">Beta</Badge>}
                {unreadCount && unreadCount > 0 ? (
                    <span className="flex h-5 min-w-5 items-center justify-center rounded-md bg-blue-500/10 px-1 text-xs font-medium text-blue-600 tabular-nums">
                        {unreadCount}
                    </span>
                ) : null}
                {item.externalUrl && (
                    <ExternalLink className="size-3 shrink-0 text-muted-foreground" />
                )}
                {children}
            </span>
        </>
    );
}
