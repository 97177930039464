import { ApolloProvider } from "@apollo/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";

import { apolloClient } from "src/api/graphql/apollo";
import { reactQueryClient } from "src/lib/clients";
import { store } from "src/redux/store";
import { StatsigReduxProvider } from "src/statsig/StatsigReduxProvider";
import { MenuServiceProvider } from "#menu/MenuServiceProvider";
import CacheBusting from "#cache-busting";

/**
 * Global state providers for the RDB app.
 */
// only put _global_ providers here!
export function GlobalProviders({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    return (
        <QueryClientProvider client={reactQueryClient}>
            <ApolloProvider client={apolloClient}>
                <ReduxProvider store={store}>
                    <StatsigReduxProvider>
                        <MenuServiceProvider>
                            <CacheBusting>{children}</CacheBusting>
                        </MenuServiceProvider>
                    </StatsigReduxProvider>
                </ReduxProvider>
            </ApolloProvider>
        </QueryClientProvider>
    );
}
