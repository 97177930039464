import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getActiveStoreId } from "src/redux/selectors";
import {
    FetchTokenResponse,
    PubNubWithTokenProvider,
    useToken,
} from "@snackpass/conversations.hooks";
import { storeUUID } from "@snackpass/conversations.types";
import Pubnub from "pubnub";
import config from "#config";
import api from "src/api/rest";
import { ErrorWithCause, sendError } from "src/utils/errors";
import { ReactNode } from "react";
const fetchToken = async (
    uuid: string,
    retries = 3,
): Promise<FetchTokenResponse> => {
    if (uuid !== "store_") {
        try {
            return await api.conversations.fetchToken(uuid);
        } catch (error) {
            if (retries > 0) {
                return fetchToken(uuid, retries - 1);
            }
            const errorWithCause = new ErrorWithCause(
                "Failed to fetch conversations token",
                error,
            );
            sendError(errorWithCause);
        }
    }

    return { token: undefined };
};
export function PubnubContextProvider({ children }: { children: ReactNode }) {
    const activeStoreId = useSelector(getActiveStoreId);

    const uuid = useMemo(() => storeUUID(activeStoreId), [activeStoreId]);

    const pubNub = useMemo(
        () => new Pubnub({ ...config.pubnub.conversations, uuid }),
        [uuid],
    );
    const [token] = useToken({
        uuid,
        ttl: 15, // minutes
        fetchToken,
    });

    return (
        <PubNubWithTokenProvider client={pubNub} uuid={uuid} token={token}>
            {children}
        </PubNubWithTokenProvider>
    );
}
