import config from "#config";
import { sendError } from "src/utils/errors";

/**
 * reactjs.org/docs/error-boundaries.html#how-about-event-handlers
 * ! Must use "onerror" and "onunhandledrejection" because
 * ! "Error boundaries do not catch errors inside event handlers."
 * ! React Error Boundaries can only catch errors at render time, not afterward
 */

const genericErrorHandler = (error: unknown) => {
    console.warn(error);

    // If production, initialize sentry and capture
    if (config.env === "production") {
        sendError(error);
    }
};

window.onerror = genericErrorHandler;
