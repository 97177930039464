import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import {
    createMultiMenu,
    deleteMultiMenu,
    fetchStoreMultiMenus,
    multiMenuThunks,
} from "#menu-editor/multi-menus/redux/thunks";
import { MultiMenuState } from "#menu-editor/multi-menus/redux/types";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { reducerHelpers } from "#menu-editor/multi-menus/redux/reducer-helpers";
import { isNotUndefined } from "#core";

/**
 * Takes the element in oldIndex in the array and places it in newIndex.
 *
 * CAUTION: The original array its mutated
 * @param arr Array to reorder items in. Gets MUTATED
 * @param oldIndex Index of the item to move
 * @param newIndex New index the item to move will be placed at
 */
/* eslint-disable-next-line */
const moveElementInArray = (arr: any[], oldIndex: number, newIndex: number) => {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
};

const multiMenuInitialState: MultiMenuState = {
    currentMenu: {},
    storeMenus: [],
};

export const multiMenusReducer = createReducer(
    multiMenuInitialState,
    (builder) => {
        builder
            .addCase(multiMenuActions.selectMultiMenu, (state, action) => {
                const selectedMenu = state.storeMenus.find(
                    ({ id }) => id === action.payload.id,
                );
                if (selectedMenu) {
                    state.currentMenu = cloneDeep(selectedMenu);
                    if (!state.currentMenu.menuOverrides) {
                        reducerHelpers.resetMenuItems(state);
                    }
                }
            })
            .addCase(multiMenuActions.resetCurrentMenu, (state) => {
                state.currentMenu = multiMenuInitialState.currentMenu;
            })
            .addCase(multiMenuActions.selectCategory, (state, action) => {
                const { categoryWithProducts } = action.payload;
                const existingCategory = reducerHelpers.findCategoryById(
                    state,
                    categoryWithProducts._id,
                );
                if (existingCategory)
                    existingCategory.productIds =
                        categoryWithProducts.productIds;
                else
                    state.currentMenu.categories?.push({
                        id: categoryWithProducts._id,
                        productIds: categoryWithProducts.productIds,
                    });
                categoryWithProducts.products
                    .flatMap(({ addonGroups }) => addonGroups)
                    .forEach(reducerHelpers.selectAddonGroup(state));
            })
            .addCase(multiMenuActions.discardCategory, (state, action) => {
                const { categoryWithProducts } = action.payload;
                state.currentMenu.categories = reducerHelpers.filterCategory(
                    state,
                    categoryWithProducts._id,
                );
                categoryWithProducts.productIds.forEach(
                    reducerHelpers.discardProductOverrides(state),
                );
                categoryWithProducts.products
                    .flatMap(({ addonGroups }) => addonGroups)
                    .forEach(reducerHelpers.discardAddonGroup(state));
            })
            .addCase(multiMenuActions.selectProduct, (state, action) => {
                const { categoryId, product } = action.payload;
                reducerHelpers.selectProduct(state, categoryId, product._id);
                product.addonGroups.forEach(
                    reducerHelpers.selectAddonGroup(state),
                );
            })
            .addCase(multiMenuActions.discardProduct, (state, action) => {
                const { categoryId, product } = action.payload;
                const productCategory = reducerHelpers.findCategoryById(
                    state,
                    categoryId,
                );
                if (productCategory) {
                    productCategory.productIds =
                        productCategory.productIds.filter(
                            (id) => id !== product._id,
                        );
                    if (productCategory.productIds.length === 0)
                        state.currentMenu.categories =
                            reducerHelpers.filterCategory(state, categoryId);
                }
                reducerHelpers.discardProductOverrides(state, product._id);
                product.addonGroups.forEach(
                    reducerHelpers.discardAddonGroup(state),
                );
            })
            .addCase(multiMenuActions.selectAddonGroup, (state, action) => {
                const { addonGroup, product, categoryId } = action.payload;
                reducerHelpers.selectProduct(state, categoryId, product._id);
                reducerHelpers.selectProductRequiredAddonGroups(state, product);
                reducerHelpers.selectAddonGroup(state, addonGroup);
            })
            .addCase(multiMenuActions.discardAddonGroup, (state, action) => {
                const { addonGroup } = action.payload;
                reducerHelpers.discardAddonGroup(state, addonGroup);
            })
            .addCase(multiMenuActions.selectAddon, (state, action) => {
                const { addon, addonGroup, product, categoryId } =
                    action.payload;
                reducerHelpers.selectProduct(state, categoryId, product._id);
                reducerHelpers.selectProductRequiredAddonGroups(state, product);
                reducerHelpers.selectAddon(state, addonGroup._id, addon._id);
            })
            .addCase(multiMenuActions.discardAddon, (state, action) => {
                const { addon, addonGroup } = action.payload;
                const addonGroupsOverrides =
                    reducerHelpers.getAddonGroupsOverrides(state);
                if (addonGroupsOverrides?.[addonGroup._id]) {
                    addonGroupsOverrides[addonGroup._id].addons =
                        addonGroupsOverrides?.[addonGroup._id].addons.filter(
                            (id) => id !== addon._id,
                        );
                    if (
                        addonGroupsOverrides[addonGroup._id].addons.length === 0
                    )
                        delete addonGroupsOverrides[addonGroup._id];
                }
                reducerHelpers.discardAddonOverride(state, addon._id);
            })
            .addCase(multiMenuActions.setMultiMenuName, (state, action) => {
                const { name } = action.payload;
                state.currentMenu.name = name;
            })
            .addCase(multiMenuActions.activeMenu, (state, action) => {
                const { enabled } = action.payload;
                state.currentMenu.enabled = enabled;
            })
            .addCase(multiMenuActions.setMultiMenuType, (state, action) => {
                const { type } = action.payload;
                state.currentMenu.type = type;
            })
            .addCase(multiMenuActions.resetMenuItems, (state, action) => {
                reducerHelpers.resetMenuItems(state);
            })
            .addCase(multiMenuActions.setMultiMenuPlatform, (state, action) => {
                const { platforms } = action.payload;
                state.currentMenu.platforms = platforms;
            })
            .addCase(multiMenuActions.setMultiMenuTime, (state, action) => {
                const { hours } = action.payload;
                state.currentMenu.hours = hours;
            })
            .addCase(
                multiMenuActions.setMultiMenuPriceAdjustment,
                (state, action) => {
                    const { priceAdjustment } = action.payload;
                    state.currentMenu.priceAdjustment = priceAdjustment;
                },
            )
            .addCase(multiMenuActions.setPriceOverride, (state, action) => {
                const { itemType, itemId, price } = action.payload;
                const menuOverrides =
                    state.currentMenu.menuOverrides?.[itemType];
                if (!menuOverrides) return;

                if (itemType === "addons" && !isNotUndefined(price))
                    menuOverrides[itemId] && delete menuOverrides[itemId];
                else if (!menuOverrides[itemId])
                    menuOverrides[itemId] = { price };
                else menuOverrides[itemId].price = price;
            })
            .addCase(multiMenuActions.setTaxOverride, (state, action) => {
                const { itemId, taxes } = action.payload;
                const menuOverrides = state.currentMenu.menuOverrides?.products;
                if (!menuOverrides) return;

                if (!menuOverrides[itemId]) {
                    menuOverrides[itemId] = { taxes, price: null };
                } else menuOverrides[itemId].taxes = taxes;
            })
            .addCase(multiMenuActions.moveCategory, (state, action) => {
                const { oldPlace, newPlace } = action.payload;

                if (!state.currentMenu.categories) return;

                moveElementInArray(
                    state.currentMenu.categories,
                    oldPlace,
                    newPlace,
                );
            })
            .addCase(multiMenuActions.moveProduct, (state, action) => {
                const { categoryId, oldPlace, newPlace } = action.payload;

                const category = state.currentMenu.categories?.find(
                    (category) => category.id === categoryId,
                );

                if (!category) return;

                moveElementInArray(category.productIds, oldPlace, newPlace);
            })
            .addCase(multiMenuActions.moveAddon, (state, action) => {
                const { addonGroupId, oldPlace, newPlace } = action.payload;

                const addonGroupOverrides =
                    state.currentMenu.menuOverrides?.addonGroups?.[
                        addonGroupId
                    ];

                if (!addonGroupOverrides) return;

                moveElementInArray(
                    addonGroupOverrides.addons,
                    oldPlace,
                    newPlace,
                );
            })
            .addCase(multiMenuActions.resetLoadingUpdateOverrides, (state) => {
                state.loadingUpdateOverrides = "idle";
            })
            .addCase(fetchStoreMultiMenus.pending, (state) => {
                state.loadingFetchStoreMenus = "pending";
            })
            .addCase(fetchStoreMultiMenus.fulfilled, (state, action) => {
                const storeMultiMenus = action.payload;
                state.storeMenus = storeMultiMenus;
                state.loadingFetchStoreMenus = "succeeded";
            })
            .addCase(fetchStoreMultiMenus.rejected, (state) => {
                state.loadingFetchStoreMenus = "failed";
            })
            .addCase(createMultiMenu.pending, (state) => {
                state.loadingCreateMultiMenu = "pending";
            })
            .addCase(createMultiMenu.fulfilled, (state, action) => {
                state.storeMenus.push(action.payload);
                state.currentMenu = action.payload;
                state.loadingCreateMultiMenu = "succeeded";
            })
            .addCase(createMultiMenu.rejected, (state) => {
                state.loadingCreateMultiMenu = "failed";
            })
            .addCase(deleteMultiMenu.pending, (state) => {
                state.loadingDeleteMultiMenu = "pending";
            })
            .addCase(deleteMultiMenu.fulfilled, (state, action) => {
                state.currentMenu = multiMenuInitialState.currentMenu;
                state.storeMenus = state.storeMenus.filter(
                    (menu) => menu.id !== action.payload,
                );
                state.loadingDeleteMultiMenu = "succeeded";
            })
            .addCase(multiMenuThunks.persistCurrentMenu.pending, (state) => {
                state.loadingUpdateMultiMenu = "pending";
            })
            .addCase(
                multiMenuThunks.persistCurrentMenu.fulfilled,
                (state, action) => {
                    reducerHelpers.updateMenu(state, action.payload);
                    state.loadingUpdateMultiMenu = "succeeded";
                },
            )
            .addCase(multiMenuThunks.persistCurrentMenu.rejected, (state) => {
                state.loadingUpdateMultiMenu = "failed";
            })
            .addCase(multiMenuThunks.resetCurrentMenu.pending, (state) => {
                state.loadingFetchCurrentMenu = "pending";
            })
            .addCase(
                multiMenuThunks.resetCurrentMenu.fulfilled,
                (state, action) => {
                    state.currentMenu = action.payload;
                    state.loadingFetchCurrentMenu = "succeeded";
                },
            )
            .addCase(
                multiMenuThunks.resetProductFields.fulfilled,
                (state, action) => {
                    action.payload.forEach(reducerHelpers.updateMenu(state));
                    state.loadingUpdateOverrides = "succeeded";
                },
            )
            .addCase(multiMenuThunks.resetProductFields.pending, (state) => {
                state.loadingUpdateOverrides = "pending";
            })
            .addCase(
                multiMenuThunks.updateOverrides.fulfilled,
                (state, action) => {
                    if (action.payload) {
                        reducerHelpers.updateMenu(state, action.payload);
                    }
                    state.loadingUpdateOverrides = "succeeded";
                },
            )
            .addCase(multiMenuThunks.updateOverrides.pending, (state) => {
                state.loadingUpdateOverrides = "pending";
            })
            .addCase(multiMenuThunks.updateOverrides.rejected, (state) => {
                state.loadingUpdateOverrides = "failed";
            })
            .addCase(
                multiMenuThunks.resetMenuOverrides.fulfilled,
                (state, action) => {
                    if (action.payload)
                        reducerHelpers.updateMenu(state, action.payload);

                    state.loadingUpdateOverrides = "succeeded";
                },
            )
            .addCase(multiMenuThunks.resetMenuOverrides.rejected, (state) => {
                state.loadingUpdateOverrides = "failed";
            })
            .addCase(multiMenuThunks.resetMenuOverrides.pending, (state) => {
                state.loadingUpdateOverrides = "pending";
            })
            .addCase(multiMenuThunks.updateMenu.pending, (state) => {
                state.loadingUpdateMultiMenu = "pending";
            })
            .addCase(multiMenuThunks.updateMenu.fulfilled, (state, action) => {
                if (action.payload)
                    reducerHelpers.updateMenu(state, action.payload);
                state.loadingUpdateMultiMenu = "succeeded";
            })
            .addCase(multiMenuThunks.updateMenu.rejected, (state) => {
                state.loadingUpdateMultiMenu = "failed";
            })
            .addCase(
                multiMenuThunks.updateModifiers.fulfilled,
                (state, action) => {
                    if (action.payload) {
                        action.payload.forEach((menu) =>
                            reducerHelpers.updateMenu(state, menu),
                        );
                    }

                    state.loadingUpdateMultiMenu = "succeeded";
                },
            )
            .addCase(multiMenuThunks.updateModifiers.rejected, (state) => {
                state.loadingUpdateMultiMenu = "failed";
            })
            .addCase(multiMenuThunks.updateModifiers.pending, (state) => {
                state.loadingUpdateMultiMenu = "pending";
            });
    },
);
