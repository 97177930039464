import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ThirdPartySource,
    PurchaseReportTransactionSource,
} from "@snackpass/snackpass-types";
import { Moment } from "moment";

import { DefinedDates } from "#date-picker/lib";
import { PaymentDefinition } from "#payment-type-picker/lib";
import { TRANSACTION_SOURCES } from "#pickers/channel-picker/lib";
import { ReportGranularity } from "#financial-reports/types";
import { GetSliceState } from "src/core/domain";

type State = {
    currentDuration: DefinedDates;
    startDate: Moment | null;
    endDate: Moment | null;
    transactionSource: PurchaseReportTransactionSource[];
    transactionChannel: ThirdPartySource[];
    transactionChannelOptions: ThirdPartySource[];
    paymentMethod: PaymentDefinition;
    granularity: ReportGranularity;
};

const initialState: State = {
    currentDuration: DefinedDates.LAST7DAYS,
    startDate: null,
    endDate: null,
    transactionSource: TRANSACTION_SOURCES,
    transactionChannel: [],
    transactionChannelOptions: [],
    paymentMethod: PaymentDefinition.ALL,
    granularity: "month",
};

export const dashboardFilterSlice = createSlice({
    name: "dashboardFilter",
    initialState,
    reducers: {
        setDashboardFilterTimeRange: (
            state,
            action: PayloadAction<{
                startDate: Moment | null;
                endDate: Moment | null;
            }>,
        ) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setDashboardFilterDuration: (
            state,
            action: PayloadAction<DefinedDates>,
        ) => {
            state.currentDuration = action.payload;
        },
        setDashboardFilterTransactionSource: (
            state,
            action: PayloadAction<PurchaseReportTransactionSource[]>,
        ) => {
            state.transactionSource = action.payload;
        },
        setDashboardFilterPaymentType: (
            state,
            action: PayloadAction<PaymentDefinition>,
        ) => {
            state.paymentMethod = action.payload;
        },
        setDashboardFilterGranularity: (
            state,
            action: PayloadAction<ReportGranularity>,
        ) => {
            state.granularity = action.payload;
        },
        setDashboardFilterTransactionChannel: (
            state,
            action: PayloadAction<ThirdPartySource[]>,
        ) => {
            state.transactionChannel = action.payload;
        },
        setDashboardFilterTransactionChannelOptions: (
            state,
            action: PayloadAction<ThirdPartySource[]>,
        ) => {
            // auto select all options by default
            state.transactionChannel = action.payload;
            state.transactionChannelOptions = action.payload;
        },
        resetDashboardFilter: (state) => {
            state.currentDuration = DefinedDates.LAST7DAYS;
            state.startDate = null;
            state.endDate = null;
            state.transactionSource = TRANSACTION_SOURCES;
            state.paymentMethod = PaymentDefinition.ALL;
        },
    },
});

export const {
    setDashboardFilterTimeRange,
    setDashboardFilterDuration,
    setDashboardFilterTransactionSource,
    setDashboardFilterTransactionChannel,
    setDashboardFilterTransactionChannelOptions,
    setDashboardFilterPaymentType,
    setDashboardFilterGranularity,
    resetDashboardFilter,
} = dashboardFilterSlice.actions;

type SliceState = GetSliceState<typeof dashboardFilterSlice>;
const getDashboardFilter = (state: SliceState) => state.dashboardFilter;

export const getDashboardReportsDuration = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.currentDuration,
);

export const getDashboardReportsStartDate = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.startDate,
);

export const getDashboardReportsEndDate = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.endDate,
);

export const getDashboardPaymentType = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.paymentMethod,
);

export const getDashboardReportsTransactionSource = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.transactionSource,
);

export const getDashboardReportsGranularity = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.granularity,
);

export const getDashboardTransactionChannel = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.transactionChannel,
);

export const getDashboardTransactionChannelOptions = createSelector(
    [getDashboardFilter],
    (dashboardFilter) => dashboardFilter.transactionChannelOptions,
);
