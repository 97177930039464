import {
    CashDrawer,
    CashDrawerWithPrinter,
    CateringStatusType,
    IAttendanceSettings,
    IChain,
    IGift,
    IHoursSchema,
    InventoryUnit,
    IProduct,
    IPurchase,
    IStore,
    PrepStation,
    Printer,
    PrinterWithPrepStation,
    PurchaseStatus,
    RefundSource,
    ThirdPartySource,
    AddonOverrideFields,
    Menu as MultiMenu,
    ProductOverrideFields,
    IPromotion,
    IProductCategory,
    EmployeePermissions,
    IUser,
    PayoutPolicy,
    FeePolicy,
    IConvenienceFeePolicy,
    ICommissionPolicy,
    IFixedChargeFeePolicy,
    IKiosk,
    AddonGroup,
    Addon,
} from "@snackpass/snackpass-types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { z } from "zod";
import { FetchTokenResponse } from "@snackpass/conversations.hooks";
import { SortingState } from "@tanstack/react-table";
import { PartialDeep } from "type-fest";

import { UserInvite } from "src/core/types";
import {
    EsperCommandResponseType,
    EsperDeviceInfo,
} from "src/api/rest/esper/types";
import { EmployeeData } from "#core/types";
import {
    BaseStoreDevice,
    DeviceType,
    MDMProvider,
    NetworkReportPeriod,
    StoreDevice,
} from "#devices/utils/deviceTypes";
import { ErrorWithCause, UserFacingError } from "src/utils/errors";
import {
    DecryptedPersonVerification,
    PayoutChannels,
    Statuses,
    StatusInfo,
    StatusReasons,
    StatusType,
    VerificationsResponse,
} from "#payouts/domain/types";
import {
    GetChannelLinksRequest,
    GetChannelLinksResponse,
} from "#settings-integrations/modules/deliverect";
import {
    AdminFields,
    SnackpassPermissions,
} from "#settings/settings-permissions/types";
import {
    SharableBrandRegistration,
    TwilioBrandRegistrationStatus,
} from "#sms-campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import { SaasAccount } from "#billing/domain/types";
import { RotationState } from "#devices/utils/deviceTypes/DeviceStats";
import {
    ShareTwilioBrandCheckSchema,
    SharedTwilioBrandFormSchema,
} from "#sms-campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";
import { StoreRelationshipInfo } from "#my-teams/types";
import { SeniorSupportUser } from "#settings/settings-senior-support/utils";
import {
    SalesDailyAggregateReport,
    SalesHourlyAggregateReport,
    SummaryTilesData,
    TableSummaryDataRow,
} from "#reports/sales-summary/types";
import {
    AggregateResponse,
    CustomerResponse,
} from "#dashboard/hooks/use-customer-insights";

import { client } from "./client";
import { SalesMetric } from "#tip-reporting/types";
import { ProductReport } from "src/api/rest/api-types";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

class Uploads {
    static async uploadMenuPhoto(formData: FormData, path: string) {
        return client
            .post(`/uploads/image`, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    path,
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Uploads.uploadMenuPhoto: POST to /uploads/image failed`,
                    cause,
                );
            });
    }

    // The uploadMenuPhoto function above uses a datestring to create unique filenames so nothing is overwritten.
    // Here, we purposely want to overwrite any existing files so any time a new file is uploaded,
    // we dont have to do any sort of cleanup on outdated items, thus keeping our storage costs down,
    // since these files can be a larger file size compared to menu photos, which get heavily compressed.
    static async uploadAndReplaceImage(formData: FormData, path: string) {
        return client.post(`/uploads/uploadAndReplace`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            },
            params: {
                path,
            },
        });
    }
}

class Users {
    static async getMe(idToken: string) {
        return client
            .get<{ user: IUser; hash?: string }>("/users/me", {
                headers: { Authorization: `Bearer ${idToken}` },
                params: {
                    includeDashboardIntercomHashFor: "web",
                    noPunchcards: true,
                },
            })
            .catch((error) => {
                throw new Error("GET to /users/me failed", error);
            });
    }

    static async sendVerificationCode() {
        return client
            .post<void>("/users/send-verification-code", undefined, {
                withCredentials: true,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    const parseResult = z
                        .object({
                            status: z.literal(400),
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(cause.response);
                    if (parseResult.success) {
                        throw new UserFacingError(
                            parseResult.data.data.message,
                        );
                    }
                }

                throw new ErrorWithCause(
                    "POST /api/v4/users/send-verification-code failed",
                    cause,
                );
            });
    }

    static async verifyCode(args: { passcode: string }) {
        return client
            .post<void>("/users/verify-code", args, {
                withCredentials: true,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    const parseResult = z
                        .union([
                            z
                                .object({
                                    status: z.literal(400),
                                    data: z.object({ message: z.string() }),
                                })
                                .transform(({ data }) => data.message),
                            z
                                .object({
                                    status: z.literal(422),
                                })
                                .transform(() => "Invalid code"),
                        ])
                        .safeParse(cause);
                    if (parseResult.success) {
                        throw new UserFacingError(parseResult.data);
                    }
                }

                throw new ErrorWithCause(
                    "POST /api/v4/users/verify-code failed",
                    cause,
                );
            });
    }

    static async checkVerification() {
        return client
            .get<void>("/users/check-verification", {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/users/check-verification failed",
                    cause,
                );
            });
    }

    static async clearVerification() {
        return client
            .post<void>("/users/clear-verification", undefined, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/users/clear-verification failed",
                    cause,
                );
            });
    }

    static async getMyStoreRelationships() {
        return client
            .get<{
                myStoreInfo: StoreRelationshipInfo[];
            }>("/users/me/store-relationships")
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/me/store-relationships failed",
                    cause,
                );
            });
    }

    static async checkPhoneNumberAvailable(number: string) {
        return client
            .post<{ available: boolean }>("/users/is-phone-available", {
                phoneNumber: number,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/is-phone-available failed",
                    cause,
                );
            });
    }

    static async getEmployees() {
        return client
            .get<{ employees: SeniorSupportUser[] }>("/users/employees")
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/employees failed",
                    cause,
                );
            });
    }

    static async updateEmployee(
        userId: string,
        request: {
            isSeniorSupport: boolean;
        },
    ) {
        return client
            .patch<{
                user: SeniorSupportUser;
            }>(`/users/employees/${userId}`, request)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/users/employees/:id failed",
                    cause,
                );
            });
    }
}

export type AnalyticsDashboard = "analytics" | "promos" | "crm" | "social";

export type PayoutHistoryItemStatus =
    | "initiated"
    | "in_transit"
    | "paid"
    | "unknown";
export type PayoutHistoryItem = {
    // One-off payments (e.g. reimbursement for incident, true-up for failed
    // payouts, etc.) will have a null periodStart and periodEnd.
    periodStart: Date | null;
    periodEnd: Date | null;

    /** Date the payout was sent. */
    payoutDate: Date;

    /** Estimated date the payout will arrive. Only supported for Stripe. */
    arrivalDate?: Date | null;

    netPayout: number;
    status: PayoutHistoryItemStatus;
    description: string;
};

class Stores {
    static async getOne(storeId: string) {
        return client.get<{ store: IStore; success: boolean }>(
            `/stores/${storeId}?serviceFeeEnabled=true`,
        );
    }

    /**
     * This is a snackpass employee protected endpoint that returns all stores.
     * Don't depend on this for non-internal routes!
     */
    static async get() {
        // TODO: this seems dangerous
        // selectively return keys; usage: encodeURI(["name", "emoji"...].toString())
        return client
            .get(`/stores`, {
                params: {
                    _select: [
                        "_id",
                        "name",
                        "isArchived",
                        "region",
                        // Note: `address` is no longer the most up to date location info.
                        "address",
                        "addressComponents",
                    ].toString(),
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.get: GET to /stores failed`,
                    cause,
                );
            });
    }

    static async getChainStores(
        chainId: string,
        abortController?: AbortController,
    ): Promise<AxiosResponse<{ stores: IStore[] }>> {
        return client
            .get(`/stores/chain/${chainId}`, {
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getChainStores: GET to /stores/chain/${chainId} failed`,
                    cause,
                );
            });
    }

    static async checkSlug(
        storeId: string,
        slug: string,
    ): Promise<{ data: { success: boolean; available: boolean } }> {
        return client
            .get(`/onboarding/${storeId}/slug/${slug}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.checkSlug: GET to /onboarding/${storeId}/slug/${slug} failed`,
                    cause,
                );
            });
    }

    static async getAnalyticsUrl(
        storeId: string,
        dashboard: AnalyticsDashboard,
    ) {
        return client
            .get(`/stores/${storeId}/analytics-url`, {
                params: { dashboard },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getAnalyticsUrl: GET to /stores/${storeId}/analytics-url failed`,
                    cause,
                );
            });
    }

    static async update(storeId: string, body: Object) {
        return client
            .put<{
                store: IStore;
                success?: boolean;
            }>(`/stores/${storeId}`, body)
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    throw cause;
                }

                throw new ErrorWithCause(
                    `api.Stores.update: PUT to /stores/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateActiveMenus(storeId: string, activeMenus: string[]) {
        return client
            .put<{
                store: IStore;
                success?: boolean;
            }>(`/stores/${storeId}/activeMenus`, {
                activeMenus,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    throw cause;
                }

                throw new ErrorWithCause(
                    `api.Stores.updateActiveMenus: PUT to /stores/${storeId}/activeMenus failed`,
                    cause,
                );
            });
    }

    static async updateCategory(
        storeId: string,
        categoryId: string,
        body: Object,
    ) {
        return client
            .put(`/stores/${storeId}/productCategories/${categoryId}`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCategory: PUT to /stores/${storeId}/productCategories/${categoryId} failed`,
                    cause,
                );
            });
    }

    static async updateManualThirdPartySources(
        storeId: string,
        thirdPartySources: ThirdPartySource[],
    ) {
        return client
            .put(`/stores/${storeId}/manualThirdPartySources`, {
                thirdPartySources,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateManualThirdPartySources: PUT to /stores/${storeId}/manualThirdPartySources failed`,
                    cause,
                );
            });
    }

    static async removeProductCategory(storeId: string, categoryId: string) {
        return client
            .delete(`/stores/${storeId}/productCategories/${categoryId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.removeProductCategory: DELETE to /stores/${storeId}/productCategories/${categoryId} failed`,
                    cause,
                );
            });
    }

    static async getProducts(
        storeId: string,
        includeCateringProducts = true,
        includePayByWeight = true,
        includeSpecialProducts = true,
    ) {
        return client
            .get<{ products: IProduct[]; topProducts: string[] }>(
                `/stores/${storeId}/products`,
                {
                    params: {
                        includeCateringProducts,
                        includePayByWeight,
                        includeSpecialProducts,
                    },
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getProducts: GET to /stores/${storeId}/products failed`,
                    cause,
                );
            });
    }

    static async listTables(storeId: string) {
        return client.get(`/stores/${storeId}/tables`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.listTables: GET to /stores/${storeId}/tables failed`,
                cause,
            );
        });
    }

    static async createTable(storeId: string, body: Object) {
        return client.post(`/stores/${storeId}/tables`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.createTable: POST to /stores/${storeId}/tables failed`,
                cause,
            );
        });
    }

    static async deleteTable(storeId: string, tableId: string) {
        return client
            .delete(`/stores/${storeId}/tables/${tableId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.deleteTable: DELETE to /stores/${storeId}/tables/${tableId} failed`,
                    cause,
                );
            });
    }

    /**
     * returns an array of items indicating payment history entries
     */
    static async getPayoutHistory(
        storeId: string,
        since: Date,
        until: Date,
        config?: AxiosRequestConfig,
    ): Promise<PayoutHistoryItem[]> {
        return client
            .get<{ payouts: PayoutHistoryItem[] }>(
                `/stores/${storeId}/payout-history`,
                {
                    ...(config || {}),
                    params: {
                        ...config?.params,
                        since,
                        until,
                        useUnifiedFormat: true,
                    },
                },
            )
            .then((res) => res.data.payouts)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getPayoutHistory failed`,
                    cause,
                );
            });
    }

    static async getImportStatus(storeId: string) {
        return client.get(`/stores/${storeId}/import-status`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getImportStatus: GET to /stores/${storeId}/import-status failed`,
                cause,
            );
        });
    }

    static async importMenuV2({
        storeId,
        isLargeMenu,
        url,
        catalogId,
    }: {
        storeId: string;
        isLargeMenu: boolean;
        url: string;
        catalogId: string;
    }) {
        return client
            .post<{
                status: string;
            }>(`/stores/${storeId}/import-menu-v2`, {
                catalogId,
                isLargeMenu,
                url,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.importMenuV2: POST to /stores/${storeId}/import-menu-v2 failed`,
                    cause,
                );
            });
    }

    static async getEmployees(
        storeId: string,
    ): Promise<AxiosResponse<{ employees: EmployeeData[] }>> {
        return client.get(`/stores/${storeId}/employees`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getEmployees: GET to /stores/${storeId}/employees failed`,
                cause,
            );
        });
    }

    static async updateEmployee(
        storeId: string,
        employeeData: EmployeeData,
    ): Promise<AxiosResponse<{ employee: EmployeeData }>> {
        return client
            .post(`/stores/${storeId}/employees/${employeeData.id}/update`, {
                ...employeeData,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateEmployee: POST to /stores/${storeId}/employees/${employeeData.id}/update failed`,
                    cause,
                );
            });
    }

    static async addEmployee(
        storeId: string,
        employeeData: EmployeeData,
    ): Promise<AxiosResponse<{ employee: EmployeeData }>> {
        return client
            .post(`/stores/${storeId}/employees/add`, {
                ...employeeData,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.addEmployee: POST to /stores/${storeId}/employees/add failed`,
                    cause,
                );
            });
    }

    static async deleteEmployee(
        storeId: string,
        employeeId: string,
    ): Promise<AxiosResponse<null>> {
        return client
            .delete(`/stores/${storeId}/employees/${employeeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.deleteEmployee: DELETE to /stores/${storeId}/employees/${employeeId} failed`,
                    cause,
                );
            });
    }

    static async sendPinToEmployee(
        storedId: string,
        employee: EmployeeData,
    ): Promise<AxiosResponse<null>> {
        return client
            .post(`/ems/${storedId}/forgot-employee-pin`, {
                phone: employee.phone,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.sendPinToEmployee: POST to /ems/${storedId}/forgot-employee-pin failed`,
                    cause,
                );
            });
    }

    static async resetEmployeePin(
        storeId: string,
        employeeId: string,
        pin?: string,
    ): Promise<AxiosResponse<{ pin: string }>> {
        return client
            .put(`/ems/${storeId}/update-employee-pin`, {
                employeeId,
                pin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.resetEmployeePin: POST to /ems/${storeId}/update-employee-pin failed`,
                    cause,
                );
            });
    }

    static async getUnusedPin(
        storeId: string,
    ): Promise<AxiosResponse<{ pin: string }>> {
        return client.get(`/ems/${storeId}/get-unused-pin`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getUnusedPin: GET to /ems/${storeId}/get-unused-pin failed`,
                cause,
            );
        });
    }

    static async setAttendanceSettings(
        storeId: string,
        attendanceSettings: IAttendanceSettings,
    ): Promise<AxiosResponse<{ store: IStore }, unknown>> {
        return client
            .post(`/stores/attendance/${storeId}/settings`, attendanceSettings)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.setAttendanceSettings: POST to /stores/attendance/${storeId}/settings failed`,
                    cause,
                );
            });
    }

    static async getPurchaseChannels(
        storeId: string,
    ): Promise<AxiosResponse<{ channels: ThirdPartySource[] }>> {
        return client.get(`/stores/${storeId}/channels`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getPurchaseChannels: GET to /stores/${storeId}/channels failed`,
                cause,
            );
        });
    }

    static async duplicateMenu(id: string, sourceStoreId: string | null) {
        return client
            .post(`/stores/${id}/duplicate-menu-from/${sourceStoreId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.duplicateMenu: POST to /stores/${id}/duplicate-menu-from/${sourceStoreId} failed`,
                    cause,
                );
            });
    }

    static async getMyAdminStores(
        abortController?: AbortController,
    ): Promise<AxiosResponse<{ stores: IStore[] }>> {
        return client
            .get(`/users/me/admin-stores`, {
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getMyAdminStores: GET to /users/me/admin-stores failed`,
                    cause,
                );
            });
    }

    static async getAdmins(storeId: string) {
        return client.get(`/stores/${storeId}/admins`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getStoreAdmins: GET to /stores/${storeId}}/admins failed`,
                cause,
            );
        });
    }

    static async scheduleOwnershipTransfer(
        storeId: string,
        body: {
            mode: "entity_transfer" | "ownership_transfer";
            activeAt: Date;
            ownerEmail?: string;
        },
    ) {
        return client
            .post(`/stores/${storeId}/schedule-ownership-transfer`, body)
            .catch((e) => {
                if (axios.isAxiosError(e)) {
                    const parseResult = z
                        .object({
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(e.response);
                    if (parseResult.success) {
                        throw new Error(parseResult.data.data.message);
                    }
                }
                throw e;
            });
    }

    static async updatePendingPayoutPolicy(
        storeId: string,
        body: { type: PayoutPolicy["__t"] | null },
    ) {
        return client
            .patch(`/stores/${storeId}/pending-payout-policy`, body)
            .catch((e) => {
                if (axios.isAxiosError(e)) {
                    const parseResult = z
                        .object({
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(e.response);
                    if (parseResult.success) {
                        throw new Error(parseResult.data.data.message);
                    }
                }
                throw e;
            });
    }

    static async finishOnboarding(storeId: string) {
        return client.post(`/onboarding/${storeId}/finish`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.finishOnboarding: POST to ${`/onboarding/${storeId}/finish`}`,
                cause,
            );
        });
    }

    static async updateBagFeePolicy(storeId: string, bagFeePolicy?: FeePolicy) {
        return client
            .put(`/stores/fee-settings/${storeId}/bagFeePolicy`, {
                bagFeePolicy,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateBagFeePolicy: PUT to ${`/stores/${storeId}/bagFeePolicy`}`,
                    cause,
                );
            });
    }

    static async updateCustomFeeLabels(
        storeId: string,
        params: Pick<IStore, "customFeeLabels">,
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/customFeeLabels`, params)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCustomFeeLabels: PUT to ${`/stores/${storeId}/customFeeLabels`}`,
                    cause,
                );
            });
    }

    static async updateMinimumChargeAmount(
        storeId: string,
        minimumChargeAmountCents: number,
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/minimumChargeAmount`, {
                minimumChargeAmount: minimumChargeAmountCents,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.minimumChargeAmount: PUT to ${`/stores/${storeId}/minimumChargeAmount`}`,
                    cause,
                );
            });
    }

    static async updateConvenienceFeePolicy(
        storeId: string,
        convenienceFeePolicies: IConvenienceFeePolicy[],
    ) {
        return client
            .put(
                `/stores/fee-settings/${storeId}/storeConvenienceFeePolicies`,
                {
                    convenienceFeePolicies,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateConvenienceFeePolicy: PUT to ${`/stores/${storeId}/storeConvenienceFeePolicies`}`,
                    cause,
                );
            });
    }

    static async updateServiceFeePolicy(
        storeId: string,
        feePolicies: FeePolicy[],
    ) {
        return client
            .put(
                `/stores/fee-settings/${storeId}/snackpassServiceFeePolicies`,
                {
                    feePolicies,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateServiceFeePolicy: PUT to ${`/stores/${storeId}/snackpassServiceFeePolicies`}`,
                    cause,
                );
            });
    }

    static async updateCommissionPolicy(
        storeId: string,
        body: {
            defaultCommissionPolicy: { fixed: number; percent: number };
            customCommissionPolicies: ICommissionPolicy[];
        },
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/commissionPolicies`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCommissionPolicy: PUT to ${`/stores/${storeId}/commissionPolicies`}`,
                    cause,
                );
            });
    }

    static async updateChargePolicy(
        storeId: string,
        body: {
            chargeFeePolicy: Pick<IFixedChargeFeePolicy, "percentage" | "flat">;
            customChargeFeePolicies: IFixedChargeFeePolicy[];
        },
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/chargeFeePolicies`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateChargePolicy: PUT to ${`/stores/${storeId}/chargeFeePolicies`}`,
                    cause,
                );
            });
    }
}

class Tables {
    static async delete(tableId: string) {
        return client.delete(`/tables/${tableId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Tables.delete: DELETE to /tables/${tableId} failed`,
                cause,
            );
        });
    }
}

class Logs {
    static send = async (body: Object) =>
        client.post(`/logs`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Logs.send: POST to /logs failed`,
                cause,
            );
        });
}

class Products {
    static async update(productId: string, body: Partial<IProduct>) {
        return client.put(`/products/${productId}`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.update: PUT to /products/${productId} failed`,
                cause,
            );
        });
    }

    static async updateAddonGroups(body: {
        storeId: string;
        addonGroup: Omit<PartialDeep<AddonGroup>, "addons"> & {
            addons: Omit<Addon, "_id">[];
        };
        groupIDs: string[];
    }): Promise<AxiosResponse<{ products: Array<IProduct> }>> {
        return client
            .put(`/products/addonGroups/batchUpdate`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.update: PUT to /products/addonGroups failed`,
                    cause,
                );
            });
    }

    static async create(body: Object) {
        return client.post(`/products`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.create: POST to /products failed`,
                cause,
            );
        });
    }

    static async remove(productId: string) {
        return client.delete(`/products/${productId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.remove: DELETE to /products/${productId} failed`,
                cause,
            );
        });
    }

    static async getTemplates(storeId: string) {
        return client
            .get<{ templateProducts: IProduct[] }>(`/products/templates`, {
                params: { storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.getTemplates: GET to /products/templates failed`,
                    cause,
                );
            });
    }

    static async createGiftCard(storeId: string, isDigital: boolean) {
        return client
            .post(`/products/createGiftCard`, { storeId, isDigital })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.createGiftCard: POST to /products/createGiftCard failed`,
                    cause,
                );
            });
    }
}

class Promotions {
    static async list(
        storeId: string,
    ): Promise<AxiosResponse<{ promotions: Array<IPromotion> }>> {
        return client.get(`/promotions?storeId=${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.list: GET to /promotions?storeId=${storeId} failed`,
                cause,
            );
        });
    }

    static async create(
        body: Partial<IPromotion>,
    ): Promise<AxiosResponse<{ promotion: IPromotion }>> {
        return client.post(`/promotions`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.create: POST to /promotions failed`,
                cause,
            );
        });
    }

    static async remove(_promoId: string): Promise<AxiosResponse<boolean>> {
        console.log("Not implemented yet.");
        //@ts-expect-error Improper return type while endpoint is not active.
        return;
        // return client.delete(`/promotions/${promoId}`);
    }

    static async update(
        promoId: string,
        body: Partial<IPromotion>,
    ): Promise<AxiosResponse<{ promotion: IPromotion }>> {
        return client.put(`/promotions/${promoId}`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.update: PUT to /promotions/${promoId} failed`,
                cause,
            );
        });
    }
}

class Admins {
    static async update(userId: string, update: Partial<IUser>) {
        return client
            .put(`/users/${userId}`, {
                user: { ...update },
                communicationPreferences: [],
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.update: PUT to /users/${userId} failed`,
                    cause,
                );
            });
    }

    static async updateSelf(
        userId: string,
        update: {
            firstName?: string;
            lastName?: string;
            profilePicUrl?: string;
        },
    ) {
        const updates = {
            firstName: update.firstName,
            lastName: update.lastName,
            profilePicUrl: update.profilePicUrl,
        };
        return client
            .put(`/users/${userId}/update-self`, updates)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.updateSelf: PUT to /users/${userId}/update-self failed`,
                    cause,
                );
            });
    }

    static async updateAdmin(
        userId: string,
        updates: { user: AdminFields },
        storeId: string,
        permissionUpdates?: SnackpassPermissions,
        employeePin?: string,
        employeePermissions?: EmployeePermissions,
    ) {
        const { pin, wage, ...userWithoutPin } = updates.user;
        const put = {
            user: {
                ...userWithoutPin,
                wage: wage?.toString() || "0",
            },
            storeId,
            permissionUpdates,
            employeePin,
            employeePermissions,
        };
        console.log("put", put);
        return client
            .put(`/users/${userId}/update-admin-and-password`, put)
            .catch((cause) => {
                console.log("cause", cause);
                throw new ErrorWithCause(
                    `api.Admins.updateWithPassword: PUT to /users/${userId}/update-admin-and-password failed`,
                    cause,
                );
            });
    }

    static async removeAdminFromStore(userId: string, storeId: string) {
        return client
            .patch(`/users/remove-admin`, {
                userId,
                storeId,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Users.removeAdminFromStore: PATCH to /users/remove-admin failed`,
                    cause,
                );
            });
    }

    static async create(
        body: { user: AdminFields },
        storeId: string,
        permissionUpdates?: SnackpassPermissions,
        employeePermissions?: EmployeePermissions,
        employeePin?: string,
    ) {
        return client
            .post(`/users/create-admin`, {
                ...body,
                storeId,
                permissionUpdates,
                employeePermissions,
                employeePin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.create: POST to /users/create-admin failed`,
                    cause,
                );
            });
    }

    static async sendInvite(
        storeId: string,
        email: string,
        permissions: SnackpassPermissions,
        employeePermissions: EmployeePermissions,
        wage: string,
        role: string,
        identifier?: string,
        pin?: string,
    ) {
        return client
            .post("/admin-invite/create", {
                storeId,
                email: email.toLowerCase(),
                permissions,
                employeePermissions,
                wage,
                jobTitle: role,
                identifier,
                pin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "api.Admins.sendInvite: POST to /user-invite/create failed",
                    cause,
                );
            });
    }
}

export type GetPurchasesForStoreParams = {
    count: number; // required, even with since
    since?: Date;
    before?: Date;
    includeKioskPurchases?: boolean;
    isCatering?: boolean;
    kioskOnly?: boolean;
    transactionSource?: string | null;
};

export type GetRestaurantDashboardPurchasesForStoreParams =
    GetPurchasesForStoreParams & { onlyIncludeRestaurantDashboardFields: true };

type RefundParams = {
    reason: string;
    partialRefund?: number;
    refundedProductIds?: string[];
    refundedItemIds?: string[];
    pin?: string;
    refundSource?: RefundSource;
};

type UpchargeParams = {
    upcharge?: number;
    productAdjustments?: string[];
    pin?: string;
};

export type UpdateStatusParams = {
    status: PurchaseStatus;
    isDelayed?: boolean;
    delayDuration?: number;
    pickupTimeDuration?: number;
    isKioskPurchase?: boolean;
    finishedEarly?: boolean;
};

class Purchases {
    static async getById(
        purchaseId: string,
        params?: { includeReceiptUrl?: boolean },
    ) {
        return client
            .get(`/purchases/${purchaseId}`, {
                params,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchases.getById: GET to /purchases/${purchaseId} failed`,
                    cause,
                );
            });
    }

    static async getForStore(
        storeId: string,
        params: GetPurchasesForStoreParams,
    ): Promise<
        AxiosResponse<{ purchases: IPurchase[]; kioskPurchases?: IPurchase[] }>
    > {
        return client
            .get(`/purchases`, {
                params: { ...params, storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.getForStore: GET to /purchases failed`,
                    cause,
                );
            });
    }

    static async getForStoreByUser(
        storeId: string,
        userId: string,
        params: GetPurchasesForStoreParams,
    ): Promise<
        AxiosResponse<{ purchases: IPurchase[]; kioskPurchases?: IPurchase[] }>
    > {
        return client
            .get(`/purchases`, {
                params: { ...params, storeId, userId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.getForStoreByUser: GET to /purchases failed`,
                    cause,
                );
            });
    }

    static async updateCateringStatus(
        purchaseId: string,
        cateringStatus: CateringStatusType,
    ) {
        return client
            .put(`/purchases/${purchaseId}/catering-status`, {
                cateringStatus,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.updateCateringStatus: PUT to /purchases/${purchaseId}/catering-status failed`,
                    cause,
                );
            });
    }

    static async sendFax(purchaseId: string, queryParams: unknown) {
        return client
            .post(
                `/purchases/${purchaseId}/fax`,
                {},
                {
                    params: queryParams,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.sendFax: POST to /purchases/${purchaseId}/fax failed`,
                    cause,
                );
            });
    }

    static async htmlReceipt(purchaseId: string, queryParams: unknown) {
        return client
            .get(`/purchases/${purchaseId}/receipt`, {
                params: queryParams,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.htmlReceipt: GET to /purchases/${purchaseId}/receipt failed`,
                    cause,
                );
            });
    }

    static async refund(purchaseId: string, body: RefundParams) {
        return client
            .put(`/purchases/${purchaseId}/refund`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.refund: PUT to /purchases/${purchaseId}/refund failed`,
                    cause,
                );
            });
    }

    static async upcharge(purchaseId: string, body: UpchargeParams) {
        return client
            .put(`/purchases/${purchaseId}/upcharge`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.upcharge: PUT to /purchases/${purchaseId}/upcharge failed`,
                    cause,
                );
            });
    }

    static async updateStatus(purchaseId: string, params: UpdateStatusParams) {
        return client
            .put(`/purchases/${purchaseId}/status`, params)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.updateStatus: PUT to /purchases/${purchaseId}/status failed`,
                    cause,
                );
            });
    }

    static async getPurchaseReceipt(
        purchaseId: string,
        token: string,
    ): Promise<string> {
        return client
            .get(`/purchases/${purchaseId}/purchase-receipt`, {
                params: { token },
            })
            .then((res) => res.data.url)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchases.getPurchaseReceipt: GET to /purchases/${purchaseId}/purchase-receipt failed`,
                    cause,
                );
            });
    }
}

class Reports {
    static async getProductRecords(
        productId: string,
        params: {
            startDate: Date;
            endDate: Date;
            groupByDay: boolean;
        },
    ): Promise<AxiosResponse<ProductReport>> {
        return client
            .get(`/reports/product/${productId}`, { params })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getProductRecords: GET to /reports/product/${productId} failed`,
                    cause,
                );
            });
    }

    static async getEarnings(
        storeId: string,
        params: {
            startDate: Date;
        },
    ) {
        return client
            .get(`/reports/store/${storeId}/earnings`, { params })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getEarnings: GET to /reports/store/${storeId}/earnings failed`,
                    cause,
                );
            });
    }

    static async getGiftCardPurchaseStats(
        params: {
            storeIds: string;
            startDate?: string;
            endDate?: string;
        },
        abortController?: AbortController,
    ): Promise<AxiosResponse> {
        return client
            .get(`/rdb-reports/gift-card/purchase-stats`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesSummary: GET to /rdb-reports/gift-card/purchase-stats failed`,
                    cause,
                );
            });
    }

    static async getGiftCardBalances(
        params: {
            storeIds: string;
            startDate?: string;
            endDate?: string;
            cursorDate?: string;
            cursorId?: string;
            limit?: number;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/gift-card/balances`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getGiftCardBalances: GET to /rdb-reports/gift-card/balances failed`,
                    cause,
                );
            });
    }

    static async getGiftCardTransactions(
        params: {
            storeIds: string;
            startDate?: string;
            endDate?: string;
            cursorDate?: string;
            cursorId?: string;
            limit?: number;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/gift-card/transactions`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getGiftCardTransactions: GET to /rdb-reports/gift-card/transactions failed`,
                    cause,
                );
            });
    }

    static async getSalesSummary(
        params: {
            storeId: string;
            startDate: string;
            endDate: string;
            comparedToStartDate: string;
            comparedToEndDate: string;
            timezone: string;
            storeIds?: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortSignal?: AbortSignal,
    ): Promise<AxiosResponse<{ salesSummary: SummaryTilesData }>> {
        return client
            .get(`/rdb-reports/sales/summary`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortSignal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesSummary: GET to /rdb-reports/sales/summary failed`,
                    cause,
                );
            });
    }

    static async getSalesReport(
        params: {
            storeId: string;
            startDate: string;
            endDate: string;
            comparedToStartDate: string;
            comparedToEndDate: string;
            timezone: string;
            storeIds?: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortSignal?: AbortSignal,
    ): Promise<
        AxiosResponse<{
            salesReport: TableSummaryDataRow[] | undefined;
            salesReportComparedTo: TableSummaryDataRow[] | undefined;
            salesHourlyAggregateReport:
                | SalesHourlyAggregateReport[]
                | undefined;
            salesHourlyAggregateReportComparedTo:
                | SalesHourlyAggregateReport[]
                | undefined;
            salesDailyAggregateReport: SalesDailyAggregateReport[] | undefined;
            salesDailyAggregateReportComparedTo:
                | SalesDailyAggregateReport[]
                | undefined;
        }>
    > {
        return client
            .get(`/rdb-reports/sales/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortSignal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesReport: GET to /rdb-reports/sales/report failed`,
                    cause,
                );
            });
    }

    static async getSalesChannelsReport(
        params: {
            storeId: string;
            startDate: string;
            endDate: string;
            timezone: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/sales/channel-report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesChannelsReport: GET to /rdb-reports/sales/channel-report failed`,
                    cause,
                );
            });
    }

    static async getMenuItemInsights(
        params: {
            storeId: string;
            startDate: string;
            endDate: string;
            timezone: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
            paymentMethod?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/menu-insights/items`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getMenuItemInsights: GET to /rdb-reports/menu-insights/items failed`,
                    cause,
                );
            });
    }

    static async getMenuItemModifierInsights(
        params: {
            storeId: string;
            productId: string;

            startDate: string;
            endDate: string;
            timezone: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/menu-insights/items/modifiers`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getMenuItemModifierInsights: GET to /rdb-reports/menu-insights/items/modifiers failed`,
                    cause,
                );
            });
    }

    static async getSalesChannels(
        params: {
            storeId: string;
            storeIds?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/sales/channels`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesChannels: GET to /rdb-reports/sales/channels failed`,
                    cause,
                );
            });
    }

    static async getReportsFreshness(params: {
        storeId: string;
        type: string;
    }) {
        return client
            .get(`/rdb-reports/freshness`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getReportsFreshness: GET to /rdb-reports/freshness failed`,
                    cause,
                );
            });
    }

    static async getCustomerInsights(
        params: {
            storeId: string;
            startDate?: string;
            endDate: string;
            compareToStartDate?: string;
            compareToEndDate?: string;
            page?: number;
        },
        abortController?: AbortController,
    ) {
        return client
            .get<{
                customers: CustomerResponse[];
                aggregate: AggregateResponse;
            }>(`/rdb-reports/customer-insights`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getCustomerInsights: GET to /rdb-reports/customer-insights failed`,
                    cause,
                );
            });
    }

    static async getPromotionsReport(
        params: {
            storeId: string;
            startDate?: string;
            endDate: string;
            source: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/promotion/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getPromotionsReport: GET to /rdb-reports/promotion/report failed`,
                    cause,
                );
            });
    }

    static async getLocationSalesReport(
        params: {
            storeIds: string;
            startDate: string;
            endDate: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/location/sales/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getLocationSalesReport: GET to /rdb-reports/location/sales/report failed`,
                    cause,
                );
            });
    }

    static async getLocationMenuItemReport(
        params: {
            storeIds: string;
            startDate: string;
            endDate: string;

            fulfillment?: string;
            source?: string;
            channel?: string;
        },
        abortController?: AbortController,
    ) {
        return client
            .get(`/rdb-reports/location/menu/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortController?.signal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getLocationMenuItemReport: GET to /rdb-reports/location/menu/report failed`,
                    cause,
                );
            });
    }

    static async getSalesCohortReport(
        params: {
            storeId: string;
            startDate: string;
            endDate: string;
            comparedToStartDate: string;
            comparedToEndDate: string;
            timezone: string;
            channel: string;
            source: string;
            fulfillment: string;
        },
        abortSignal?: AbortSignal,
    ) {
        return client
            .get(`/rdb-reports/sales/summary-by-cohort`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
                signal: abortSignal,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesCohortReport: GET to /rdb-reports/sales/summary-by-cohort failed`,
                    cause,
                );
            });
    }

    static async getSalesByHour(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        timezone: string;
        channel: string;
        source: string;
        fulfillment: string;
    }): Promise<
        AxiosResponse<{
            salesReport: Array<{
                actualPayout: SalesMetric;
                customerToSnackpassFees: SalesMetric;
                expectedCash: SalesMetric;
                expectedPayout: SalesMetric;
                giftCardPurchases: SalesMetric;
                items: SalesMetric;
                netSales: SalesMetric;
                orders: SalesMetric;
                processingFees: SalesMetric;
                purchaseDiscount: SalesMetric;
                refundTotal: SalesMetric;
                snackpassFees: SalesMetric;
                storeToCustomerFeeTotal: SalesMetric;
                taxesRemittedForSnackpass: SalesMetric;
                taxesRemittedForStore: SalesMetric;
                taxesSnackpassOwes: SalesMetric;
                taxesYouOwe: SalesMetric;
                tips: SalesMetric;
                date?: string;
            }>;
        }>
    > {
        return client
            .get(`/rdb-reports/sales/sales-by-hour`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesByHour: GET to /rdb-reports/sales/sales-by-hour failed`,
                    cause,
                );
            });
    }
}

class Auth {
    static async sendVerification(phoneNumber: string) {
        return client
            .post("phoneAuthentication/sendVerification", {
                phoneNumber,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Auth.sendVerification: POST to phoneAuthentication/sendVerification failed`,
                    cause,
                );
            });
    }

    static async verify(phoneNumber: string, code: string, createUser = false) {
        return client
            .post("phoneAuthentication/verifyPhone", {
                phoneNumber,
                code,
                createUser,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Auth.verify: Post to phoneAuthentication/verifyPhone failed`,
                    cause,
                );
            });
    }

    static async verifyWithPhoneTakeover(
        phoneNumber: string,
        code: string,
        storeId: string,
    ) {
        return client.post(
            "phoneAuthentication/verify-phone-with-take-phone-number",
            {
                phoneNumber,
                code,
                storeId,
            },
        );
    }
}

class Inventory {
    static async create({
        storeId,
        name,
        sku,
        baseUnit,
        costPerUnitCents,
    }: {
        storeId: string;
        name: string;
        sku: string;
        baseUnit: InventoryUnit;
        costPerUnitCents: number;
    }) {
        return client
            .post(`/inventory/items`, {
                storeId,
                name,
                sku,
                baseUnit,
                costPerUnitCents,
                isHidden: false,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Inventory.create: POST to /inventory/items failed`,
                    cause,
                );
            });
    }

    static async update(
        id: string,
        {
            costPerUnitCents,
            name,
            sku,
            isHidden,
        }: {
            costPerUnitCents?: number;
            name?: string;
            sku?: string;
            isHidden?: boolean;
        },
    ) {
        return client.patch(`/inventory/items/${id}`, {
            costPerUnitCents,
            name,
            sku,
            isHidden,
        });
    }

    static async get(storeId: string) {
        return client.get(`/inventory/stores/${storeId}/items`);
    }

    static async getReports(
        storeId: string,
        startDate: string,
        endDate: string,
    ) {
        return client.get(
            `/inventory/stores/${storeId}/item-reports?startDate=${startDate}&endDate=${endDate}`,
        );
    }

    static async adjust(id: string, unitChange: number) {
        return client.put(`/inventory/items/${id}/adjust`, {
            unitChange,
        });
    }
}

export type HasBillingMethodsData = {
    hasExistingMethods: boolean;
    portalUrl?: string;
};

class Billing {
    static async getSaasAccount(
        storeId: string,
    ): Promise<{ data: { account: SaasAccount; success: boolean } }> {
        return client.get(`/billing/${storeId}/saas-account`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getSaasAccount: GET to /billing/${storeId}/saas-account failed`,
                cause,
            );
        });
    }

    static async getPortal(
        storeId: string,
    ): Promise<{ data: { portalUrl: string; success: boolean } }> {
        return client.get(`/billing/${storeId}/portal`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getPortal: GET to /billing/${storeId}/portal failed`,
                cause,
            );
        });
    }

    static async getOnboardingForm(
        storeId: string,
    ): Promise<{ data: { url: string; success: boolean } }> {
        return client
            .get(`/verifications/${storeId}/account/onboarding`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Billing.getOnboardingForm: GET to /billing/${storeId}/account/onboarding failed`,
                    cause,
                );
            });
    }

    static async getCheckout(
        storeId: string,
    ): Promise<{ data: { clientSecret: string; success: boolean } }> {
        return client.get(`/billing/${storeId}/checkout`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getCheckout: GET to /billing/${storeId}/checkout failed`,
                cause,
            );
        });
    }

    static async getHasBillingMethods(
        storeId: string,
    ): Promise<{ data: HasBillingMethodsData }> {
        return client
            .get(`/billing/${storeId}/payment-methods`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Billing.getHasBillingMethods: GET to /billing/${storeId}/payment-methods failed`,
                    cause,
                );
            });
    }
}

class Chains {
    static async get(chainId: string) {
        return client
            .get<{ chain: IChain }>(`/chains/${chainId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Chains.get: GET to /chains/${chainId} failed`,
                    cause,
                );
            });
    }
}

export type BaseStoreDeviceWithNetworkStatus = BaseStoreDevice & {
    latestNetworkStatus?: NetworkReportPeriod["status"];
};

type ListStoreDevicesResponse = {
    data: {
        devices: BaseStoreDeviceWithNetworkStatus[];
    };
};

export type SummarizedDevice = Pick<
    BaseStoreDevice,
    "id" | "name" | "storeId" | "serial" | "snackId" | "deviceType"
>;
type ListDevicesForInternalResponse = {
    data: {
        devices: SummarizedDevice[];
    };
};
type GetDeviceNetworkConnectivityReportResponse = {
    data: { report: NetworkReportPeriod[] };
};
type GetStoreDeviceResponse = { data: { device: StoreDevice } };
type GetPrepStationFieldsResponse = {
    data: {
        printers: PrinterWithPrepStation[];
        categories: IProductCategory[];
        products: IProduct[];
        productToCategoriesMap: { [productId: string]: string };
    };
};
type CheckValidIdResponse = { data: { valid: boolean } };
type CreateStoreDeviceResponse = { data: { device: BaseStoreDevice } };
type UpdateStoreDeviceResponse = {
    data: { device: StoreDevice; rebootSuccess?: boolean };
};
type DeleteStoreDeviceResponse = { data: { device: BaseStoreDevice } };
type RestartStoreDeviceResponse = { data: boolean };
class StoreDevices {
    static async createStoreDevice(params: {
        id: string; // snackId, or printerId for cash drawer
        deviceType: DeviceType;
        name: string;
        storeId: string;
        mdmProvider: MDMProvider | null;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createStoreDevice: POST to /devices/store failed`,
                cause,
            );
        });
    }

    static async createStripeTerminal(params: {
        deviceName: string;
        storeId: string;
        registrationCode: string;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store/stripe`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createStripeTerminal: POST to /devices/store/stripe failed`,
                cause,
            );
        });
    }

    static async createSnackOSDevice(params: {
        deviceName: string;
        storeId: string;
        registrationCode: string;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store/assign`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createSnackOSDevice: POST to /devices/store/assign failed`,
                cause,
            );
        });
    }

    static async listStoreDevices(
        storeId: string,
    ): Promise<ListStoreDevicesResponse> {
        return client
            .get(`/devices/store`, { params: { storeId } })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.listStoreDevices: GET to /devices/store failed`,
                    cause,
                );
            });
    }

    static async listDevicesForInternal(): Promise<ListDevicesForInternalResponse> {
        return client.get("/devices/store/internal").catch((cause) => {
            throw new ErrorWithCause(
                "api.StoreDevices.listDevicesForInternal: GET to /devices/store/internal failed",
                cause,
            );
        });
    }

    static async getStoreDevice(id: string): Promise<GetStoreDeviceResponse> {
        return client.get(`/devices/store/${id}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.getStoreDevice: GET to /devices/store/${id} failed`,
                cause,
            );
        });
    }

    static async getDeviceNetworkConnectivityReport(
        id: string,
        storeId: string,
        start: Date,
        end: Date,
    ): Promise<GetDeviceNetworkConnectivityReportResponse> {
        return client
            .get(`/devices/store/${id}/network-connectivity-report`, {
                params: { storeId, start, end },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.getDeviceNetworkConnectivityReport: GET to /devices/store/${id}/network-connectivity-report failed`,
                    cause,
                );
            });
    }

    static async getPrepStationFields(
        storeId: string,
    ): Promise<GetPrepStationFieldsResponse> {
        return client
            .get(`/devices/store/prep-stations/store/${storeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.getPrepStationFields: GET to /devices/store/prep-stations/store/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateStoreDevice(
        deviceId: string,
        updateParams: Record<string, unknown>,
    ): Promise<UpdateStoreDeviceResponse> {
        return client
            .put(`/devices/store/${deviceId}`, { ...updateParams })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.updateStoreDevice: PUT to /devices/store/${deviceId} failed`,
                    cause,
                );
            });
    }

    static async deleteStoreDevice(
        deviceId: string,
    ): Promise<DeleteStoreDeviceResponse> {
        return client.delete(`/devices/store/${deviceId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.deleteStoreDevice: DELETE to /devices/store/${deviceId} failed`,
                cause,
            );
        });
    }

    static async rebootStoreDevice(
        deviceId: string,
    ): Promise<DeleteStoreDeviceResponse> {
        return client
            .post(`/devices/store/${deviceId}/reboot`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.rebootStoreDevice: POST to /devices/store/${deviceId}/reboot failed`,
                    cause,
                );
            });
    }

    static async restartStoreDevice(
        deviceId: string,
    ): Promise<RestartStoreDeviceResponse> {
        return client
            .post(`/devices/store/${deviceId}/restart`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.restartStoreDevice: POST to /devices/store/${deviceId}/restart failed`,
                    cause,
                );
            });
    }

    static async setRotationState(
        deviceId: string,
        rotateState: RotationState,
    ): Promise<AxiosResponse<EsperCommandResponseType>> {
        return client
            .post(`/devices/store/${deviceId}/rotate`, {
                rotateState,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.setRotationState: POST to /devices/store/${deviceId}/rotate failed`,
                    cause,
                );
            });
    }

    // TODO: update to use /devices rather than /snacktv once it's available
    static async getEsperDeviceInfoBySerial(
        serial: string,
    ): Promise<AxiosResponse<EsperDeviceInfo>> {
        return client.get(`/snacktv/${serial}/device-info`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.getEsperDeviceInfoBySerial: GET to /snacktv/${serial}/device-info failed`,
                cause,
            );
        });
    }

    static async checkValidId(
        snackId: string,
        deviceType: string,
    ): Promise<CheckValidIdResponse> {
        return client
            .get(`/devices/store/check-valid-id/${snackId}/type/${deviceType}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.checkValidId: GET to /devices/store/check-valid-id/${snackId}/type/${deviceType} failed`,
                    cause,
                );
            });
    }
}

class Kiosks {
    static async getKiosksForStore(
        storeId: string,
    ): Promise<{ data: { devices: IKiosk[] } }> {
        return client.get(`/kiosks/store/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Kiosks.getKiosksForStore: GET to /kiosks/store/:storeId failed`,
                cause,
            );
        });
    }
}

class Printers {
    static async getPrinters(
        storeId: string,
    ): Promise<AxiosResponse<{ printers: Array<PrinterWithPrepStation> }>> {
        return client.get(`/printers?storeId=${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Printers.getPrinters: GET to /printers?storeId=${storeId} failed`,
                cause,
            );
        });
    }

    static async updatePrinter(
        printerId: string,
        printerData: unknown,
    ): Promise<AxiosResponse<{ printer: Printer }>> {
        return client
            .patch(`/printers/${printerId}`, printerData)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Printers.updatePrinter: PATCH to /printers/${printerId} failed`,
                    cause,
                );
            });
    }

    static async registerPrinter(
        storeId: string,
        serial: string,
    ): Promise<AxiosResponse<{ printer: Printer }>> {
        return client
            .post("/printers/register", { storeId, serial })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Printers.registerPrinter: POST to /printers/register failed`,
                    cause,
                );
            });
    }
}

class PrepStations {
    static async getPrepStations(
        storeId: string,
    ): Promise<AxiosResponse<{ prepStations: Array<PrepStation> }>> {
        return client
            .get(`/prep-stations?storeId=${storeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.getPrepStations: GET to /prep-stations?storeId=${storeId} failed`,
                    cause,
                );
            });
    }

    static async updatePrepStation(
        prepStationId: string,
        data: Partial<PrepStation & { printers: Array<string> }>,
    ): Promise<{ data: { prepStation: PrepStation } }> {
        return client
            .patch(`/prep-stations/${prepStationId}`, {
                ...data,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.updatePrepStations: PATCH to /prep-stations/${prepStationId} failed`,
                    cause,
                );
            });
    }

    static async createPrepStation(
        data: Partial<PrepStation & { printers: Array<string> }>,
    ): Promise<AxiosResponse<{ prepStation: PrepStation }>> {
        return client
            .post("/prep-stations", {
                ...data,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.createPrepStation: POST to /prep-stations failed`,
                    cause,
                );
            });
    }

    static async deletePrepStation(
        prepStationId: string,
    ): Promise<AxiosResponse<null>> {
        return client
            .delete(`/prep-stations/${prepStationId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.deletePrepStations: DELETE to /prep-stations/${prepStationId} failed`,
                    cause,
                );
            });
    }
}

class CashDrawers {
    static async getCashDrawers(
        storeId: string,
    ): Promise<AxiosResponse<{ cashDrawers: CashDrawerWithPrinter[] }>> {
        return client.get(`/cash-drawers/store/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.CashDrawers.getCashDrawers: GET to /cash-drawers/store/${storeId} failed`,
                cause,
            );
        });
    }

    static async createCashDrawer(
        storeId: string,
        data: Partial<CashDrawer>,
    ): Promise<AxiosResponse> {
        return client
            .post(`/cash-drawers/store/${storeId}`, data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.CashDrawers.createCashDrawer: POST to /cash-drawers/store/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateCashDrawer(
        cashDrawerId: string,
        data: Partial<CashDrawer>,
    ): Promise<AxiosResponse> {
        return client
            .patch(`/cash-drawers/${cashDrawerId}`, data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.CashDrawers.updateCashDrawer: PATCH to /cash-drawers/${cashDrawerId} failed`,
                    cause,
                );
            });
    }

    static async deleteCashDrawer(
        cashDrawerId: string,
    ): Promise<AxiosResponse> {
        return client.delete(`/cash-drawers/${cashDrawerId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.CashDrawers.deleteCashDrawer: DELETE to /cash-drawers/${cashDrawerId} failed`,
                cause,
            );
        });
    }
}

class Gifts {
    static async createStoreGift(params: {
        toUserId: string;
        storeId: string;
        message: string;
        rewardTemplate: {
            name: string;
            nameForStore: string;
            expirationDate: Date;
            productIds: string[];
            categories: string[];
            storewide: boolean;
            discount: {
                percentOff: number;
            };
        };
        hideOnClient?: boolean;
    }): Promise<AxiosResponse<IGift>> {
        return client.post<IGift>("/gifts/store", params).catch((cause) => {
            throw new ErrorWithCause(
                `api.Gifts.createStoreGift: POST to /gifts/store failed`,
                cause,
            );
        });
    }
}

type RequestIntegrationRequest = {
    storeId: string;
    name: string;
    message: string;
};

type RequestIntegrationResponse = {
    data: {
        success: boolean;
    };
};

type GetIntegrationResponse = {
    data: {
        integrations: {
            [key: string]: {
                enabled: boolean;
            };
        };
    };
} & RequestIntegrationResponse;

class IntegrationsRequest {
    static async requestIntegration(
        request: RequestIntegrationRequest,
    ): Promise<RequestIntegrationResponse> {
        return client
            .post(`/integrations/request/${request.storeId}`, {
                integration: request,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.IntegrationsRequest.requestIntegration: POST to /integrations/request/${request.storeId} failed`,
                    cause,
                );
            });
    }

    static async getIntegrations(
        storeId: string,
    ): Promise<GetIntegrationResponse> {
        return client.get(`/integrations/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.IntegrationsRequest.requestIntegration: GET to /integrations/${storeId} failed`,
                cause,
            );
        });
    }
}

class Deliverect {
    static async getChannelLinks({
        storeId,
    }: GetChannelLinksRequest): Promise<GetChannelLinksResponse> {
        return client.get(`/deliverect/channels/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.deliverect.getChannelLinks: GET to deliverect/channels/${storeId} failed`,
                cause,
            );
        });
    }
}

class MultiMenusApi {
    static inputMapper(menu: MultiMenu): MultiMenu {
        return {
            ...menu,
            menuOverrides: menu.menuOverrides && {
                products: menu.menuOverrides.products ?? {},
                addonGroups: menu.menuOverrides.addonGroups ?? {},
                addons: menu.menuOverrides.addons ?? {},
            },
        };
    }
    static async createMultiMenu(
        storeId: string,
        data: Partial<MultiMenu>,
    ): Promise<MultiMenu> {
        return client
            .post<{ menu: MultiMenu }>(`/stores/${storeId}/menus`, data)
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.createMultiMenu: POST to /stores/${storeId}/menus failed`,
                    cause,
                );
            });
    }
    static async getStoreMultiMenus(storeId: string): Promise<MultiMenu[]> {
        return client
            .get<{ menus: MultiMenu[] }>(`/stores/${storeId}/menus`)
            .then(({ data: { menus } }) => menus.map(MultiMenusApi.inputMapper))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.getStoreMultiMenus: GET to /stores/${storeId}/menus failed`,
                    cause,
                );
            });
    }
    static async updateMultiMenu(
        storeId: string,
        menuId: string,
        partialMultiMenu: Partial<MultiMenu>,
    ): Promise<MultiMenu> {
        return client
            .patch<{ menu: MultiMenu }>(
                `/stores/${storeId}/menus/${menuId}`,
                partialMultiMenu,
            )
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.updateMultiMenus: PATCH to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
    static async updateMultiMenuOverrides(
        storeId: string,
        menuId: string,
        params: {
            products?: Record<string, ProductOverrideFields | null>;
            addons?: Record<string, AddonOverrideFields | null>;
        },
    ): Promise<MultiMenu> {
        return client
            .patch<{ menu: MultiMenu }>(
                `/stores/${storeId}/menus/${menuId}/overrides`,
                params,
            )
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.updateMultiMenuOverrides: PATCH to /stores/${storeId}/menus/${menuId}/overrides failed`,
                    cause,
                );
            });
    }
    static async deleteMultiMenu(
        storeId: string,
        menuId: string,
    ): Promise<string> {
        return client
            .delete<MultiMenu>(`/stores/${storeId}/menus/${menuId}`)
            .then(() => menuId)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.deleteMultiMenu: DELETE to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
    static async checkConflicts(
        storeId: string,
        data: Omit<
            MultiMenu,
            | "id"
            | "name"
            | "enabled"
            | "storeId"
            | "categories"
            | "priceAdjustment"
            | "menuOverrides"
        > & { id?: string },
    ): Promise<{ menu: MultiMenu; conflictHours: IHoursSchema }[]> {
        return client
            .get<{
                conflicts: { menu: MultiMenu; conflictHours: IHoursSchema }[];
            }>(`/stores/${storeId}/menus/conflicts`, { params: data })
            .then(({ data: { conflicts } }) => conflicts)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.checkConflicts: GET to /stores/${storeId}/menus/conflicts failed`,
                    cause,
                );
            });
    }
    static async getMenu(storeId: string, menuId: string): Promise<MultiMenu> {
        return client
            .get<{ menu: MultiMenu }>(`/stores/${storeId}/menus/${menuId}`)
            .then(({ data }) => MultiMenusApi.inputMapper(data.menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.getMenu: GET to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
}

class Conversations {
    static async fetchToken(uuid: string): Promise<FetchTokenResponse> {
        return client
            .post(
                `/conversations/store/token`,
                { uuid },
                { headers: { Accept: "application/json" } },
            )
            .then((res) => res.data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Conversations.fetchToken: GET to /conversations/store/token failed`,
                    cause,
                );
            });
    }

    static async generateResponse(chatChannel: string): Promise<string | null> {
        return client
            .get("/conversations/suggested-response", {
                params: { chatChannel },
            })
            .then((res) => res.data.response)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Conversations.generateResponse: GET to /conversations/suggested-response failed`,
                    cause,
                );
            });
    }
}

type newUser = {
    name: string;
    phoneNumber?: string;
    email?: string;
    pointsBalance: number;
    birthday?: string;
};

class PunchCards {
    static async getPunchCardForUser(storeId: string, userId: string) {
        return client
            .get(`/punchcards`, {
                params: { storeId, userId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPunchCardForUser: GET to /punchcards failed`,
                    cause,
                );
            });
    }
    static async createPunchcard(storeId: string, user: newUser) {
        return client.post(`/punchcards`, { storeId, user }).catch((cause) => {
            throw new ErrorWithCause(
                `api.PunchCards.createPunchcard: POST to /punchcards failed`,
                cause,
            );
        });
    }
    static async update(punchcardId: string, updates: Object) {
        return client.put(`/punchcards/${punchcardId}`, updates);
    }
    static async getPaginatedPunchcards(
        storeId: string,
        pageSize: number,
        pageIndex: number,
        sorting: SortingState,
        search: string,
        query: string,
    ) {
        return client
            .post(`/punchcards/pagination`, {
                storeId,
                pageSize,
                pageIndex,
                sorting,
                search,
                query,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPaginatedPunchcards: POST to /punchcards/paginated failed`,
                    cause,
                );
            });
    }
    static async getStorePunchcardChanges(storeId: string) {
        return client
            .get(`/punchcards/store-changes`, {
                params: { storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPunchCardForUser: GET to /punchcards/store-changes failed`,
                    cause,
                );
            });
    }
}

const verificationsEndpoint = (
    storeId: string,
    route = "",
    type: "account" | "person" = "account",
) => `/verifications/${storeId}/${type}${route}`;

class Verifications {
    static async status(storeId: string): Promise<
        AxiosResponse<{
            status: StatusInfo;
            type: StatusType;
        }>
    > {
        return client
            .get(`/verifications/${storeId}/status`, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.status: GET to /verifications/:storeId/status failed`,
                    cause,
                );
            });
    }

    static async channel(
        storeId: string,
    ): VerificationsResponse<{ channel: PayoutChannels }> {
        const endpoint = `/verifications/${storeId}/payout/channel`;
        return client
            .get(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.channel: GET to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async create(storeId: string): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId);
        return client
            .post(endpoint, undefined, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.create: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async retrieve(storeId?: string): VerificationsResponse {
        return client
            .get(`/verifications/${storeId}/account`, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.retrieve: GET to /verifications/:storeId/account failed`,
                    cause,
                );
            });
    }

    static async update(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId);
        return client
            .patch(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.update: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async updateBusinessInformation(
        storeId: string,
        data: {
            name?: string;
            taxId?: string;
            merchantCategoryCode?: string;
            phone?: string;
        },
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/business-information`;
        return client
            .patch(endpoint, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.updateBusinessInformation: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async finish(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId, "/finish");
        return client
            .patch(endpoint, formData, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.finish: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async updatePerson(
        storeId: string,
        personId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(
            storeId,
            `/${personId}`,
            "person",
        );
        return client
            .patch(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.updatePerson: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async createPerson(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId, "", "person");
        return client
            .post(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.createPerson: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async removePerson(
        storeId: string,
        id: DecryptedPersonVerification["id"],
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/person/${id}`;
        return client
            .delete(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.removePerson: DELETE to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async setNewPrimary(
        storeId: string,
        id: DecryptedPersonVerification["id"],
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/person/${id}/new-primary`;
        return client
            .post(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.setNewPrimary: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async sendVerificationCode(storeId: string) {
        return client
            .post<void>(
                "/verifications/auth/send-verification-code",
                { storeId },
                { withCredentials: true },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/send-verification-code failed",
                    cause,
                );
            });
    }

    static async verifyCode(args: {
        storeId: string;
        passcode: string;
        ttlHours: number;
    }) {
        return client
            .post<void>("/verifications/auth/verify-code", args, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/verify-code failed",
                    cause,
                );
            });
    }

    static async checkAuthToken(storeId: string) {
        return client
            .get<void>(`/verifications/auth/${storeId}/check`, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/verify-code failed",
                    cause,
                );
            });
    }

    static async removeBankAccount(storeId: string) {
        return client
            .patch<void>(`/verifications/${storeId}/account/remove-bank`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/verifications/remove-bank failed",
                    cause,
                );
            });
    }

    static async updateAccountStatus(
        storeId: string,
        status: Statuses,
        reason: StatusReasons,
        adminNote?: string,
    ): VerificationsResponse {
        return client
            .patch(`/verifications/${storeId}/payouts/status`, {
                status,
                reason,
                adminNote,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/verifications/:storeId/payouts/status failed",
                    cause,
                );
            });
    }

    static async listTaxForms(storeId: string) {
        return client
            .get<{ forms: string[] }>(`/verifications/${storeId}/tax-forms`, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/verifications/:storeId/tax-forms failed",
                    cause,
                );
            });
    }

    static async downloadTaxForm(storeId: string, formId: string) {
        return client
            .get<Blob>(`/verifications/${storeId}/tax-forms/${formId}`, {
                responseType: "blob",
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/verifications/:storeId/tax-forms/:formId failed",
                    cause,
                );
            });
    }
}

class Twilio {
    static async createTwilioBrandRegistration(
        storeId: string,
        formData: object,
    ) {
        return client.post<{ status: TwilioBrandRegistrationStatus }>(
            `/twilio/brand/register`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }

    static async deleteTwilioBrandRegistration(storeId: string) {
        return client.delete(`/twilio/brand/${storeId}`);
    }

    static async fetchTwilioBrandRegistrationStatus(storeId: string) {
        return client.get<{
            status: TwilioBrandRegistrationStatus | null | undefined;
            displayName: string | null | undefined;
        }>(`/twilio/brand/status`, {
            params: { storeId },
            withCredentials: true,
        });
    }

    static async fetchTwilioBrandRegistrationChainStores(storeId: string) {
        return client.get<{ brands: SharableBrandRegistration[] }>(
            `/twilio/brand/shared/chain-stores`,
            {
                params: { storeId },
                withCredentials: true,
            },
        );
    }

    static async checkSharedTwilioBrand(
        storeId: string,
        formData: z.infer<typeof ShareTwilioBrandCheckSchema>,
    ) {
        return client.post<void>(
            `/twilio/brand/shared/check`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }

    static async createSharedTwilioBrand(
        storeId: string,
        formData: z.infer<typeof SharedTwilioBrandFormSchema>,
    ) {
        return client.post<{
            status: TwilioBrandRegistrationStatus;
            displayName: string | undefined;
            phoneNumber: string | undefined;
        }>(
            `/twilio/brand/shared/register`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }
}

class AdminUserInvite {
    static async getInvite(nonce: string) {
        return client.get<{ invite: UserInvite }>(`/admin-invite/${nonce}`);
    }

    static async getInvites(storeId: string) {
        return client.get<{ invites: UserInvite[] }>("/admin-invite/invites", {
            params: { storeId },
        });
    }

    static async respond(nonce: string, accept: boolean) {
        return client.post("/admin-invite/respond", {
            nonce,
            accept,
        });
    }

    static async cancelInvite(storeId: string, inviteId: string) {
        return client.delete(`/admin-invite/${inviteId}`, {
            params: { storeId },
        });
    }

    static async resendInvite(storeId: string, inviteId: string) {
        return client.post(`/admin-invite/resend`, {
            storeId,
            inviteId,
        });
    }

    static async createAccount(
        nonce: string,
        firstName: string,
        lastName: string,
        password: string,
    ) {
        return client.post("/admin-invite/accept-and-create-account", {
            nonce,
            firstName,
            lastName,
            password,
        });
    }

    static async upgradeAccountToAcceptInvite(
        nonce: string,
        email: string,
        storeId: string,
    ) {
        return client.post("/admin-invite/upgrade-account-to-accept", {
            nonce,
            email,
            storeId,
        });
    }
}

class Payouts {
    static async createPayout(
        id: string,
        input: {
            amountCents: number;
            description: string;
        },
    ) {
        return client
            .post<{ payoutId: string }>(`/payouts/${id}/one-off`, input)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/payouts/:id/one-off failed",
                    cause,
                );
            });
    }
}

const api = {
    admins: Admins,
    auth: Auth,
    billing: Billing,
    cashDrawers: CashDrawers,
    chains: Chains,
    gifts: Gifts,
    inventory: Inventory,
    logs: Logs,
    prepStations: PrepStations,
    printers: Printers,
    products: Products,
    promotions: Promotions,
    purchases: Purchases,
    reports: Reports,
    stores: Stores,
    storeDevices: StoreDevices,
    kiosk: Kiosks,
    tables: Tables,
    uploads: Uploads,
    users: Users,
    integrations: {
        request: IntegrationsRequest,
    },
    deliverect: Deliverect,
    multiMenus: MultiMenusApi,
    conversations: Conversations,
    punchCards: PunchCards,
    verifications: Verifications,
    twilio: Twilio,
    adminUserInvite: AdminUserInvite,
    payouts: Payouts,
};

export default api;
