import { Routes } from "#navigation/routes";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreNumUnreads } from "src/redux/slices";

const INBOX_UNREAD_ROUTES = [Routes.Guestbook, Routes.GuestbookConversations];

export function useUnread() {
    const unreadCount = useAppSelector(getActiveStoreNumUnreads);
    const getRouteUnread = (route: Routes) => {
        if (INBOX_UNREAD_ROUTES.includes(route)) {
            return unreadCount;
        }
        return undefined;
    };

    return {
        unreadCount,
        getRouteUnread,
    };
}
