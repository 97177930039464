import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { ReportsContextProvider } from "#app/providers/reports-context-provider";

import { getActiveStoreId } from "src/redux/selectors";
import { SidebarInset, SidebarProvider } from "src/@/components/ui/sidebar";
import { AppSidebar } from "#navigation/sidebar";
import { RouterDOM } from "#navigation/router-dom";
import { PubnubContextProvider } from "#app/providers/pubnub-context-provider";
import { NavigationHeader } from "#navigation/sidebar/navigation-header";

function AppLayout() {
    const activeStoreId = useSelector(getActiveStoreId);
    return (
        <BrowserRouter>
            <PubnubContextProvider>
                <RecoilRoot key={activeStoreId || "unknown"}>
                    {/* TODO: move reports context provider */}
                    <ReportsContextProvider>
                        <SidebarProvider>
                            <AppSidebar />
                            <SidebarInset>
                                <NavigationHeader />
                                <div className="p-4 sm:p-12 pt-0 sm:pt-4 pb-4 sm:pb-4">
                                    <RouterDOM />
                                </div>
                            </SidebarInset>
                        </SidebarProvider>
                    </ReportsContextProvider>
                </RecoilRoot>
            </PubnubContextProvider>
        </BrowserRouter>
    );
}

export default AppLayout;
