import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { isNull } from "lodash";
import { toast } from "sonner";

import { getActiveStoreClosedUntil } from "src/redux/selectors";

import { PauseOrdersModal } from "./pause-orders-modal/pause-orders-modal";
import { PauseOrdersButton } from "./pause-orders-button";
import { Action, useToggleOrders } from "./use-toggle-orders";

export const PauseOrders: FC = () => {
    const [modalShow, setModalShow] = useState(false);
    const { toggleOrders, isLoading } = useToggleOrders();

    const closedUntil = useSelector(getActiveStoreClosedUntil);
    const resumeOrders = async () => {
        await toggleOrders({ action: Action.Resume });
        toast("Orders have been resumed.");
    };
    const pauseOrders = () => setModalShow(true);

    const toggleAction = isNull(closedUntil) ? pauseOrders : resumeOrders;

    return (
        <>
            <PauseOrdersModal
                showModal={modalShow}
                setShowModal={setModalShow}
            />
            <PauseOrdersButton
                handleClick={toggleAction}
                isLoading={isLoading}
            />
        </>
    );
};
