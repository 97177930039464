import { usePromotionReports } from "./use-promotions-reports";

export interface PromotionChartData {
    name: string;
    redemptions: number;
    revenue: number;
}

export const usePromotionsChart = () => {
    const { promotions, isLoading } = usePromotionReports();

    return {
        promotions,
        isLoading,
    };
};
