import { LucideIcon } from "lucide-react";
import {
    MetricCard as BaseMetricCard,
    MetricChangeText,
} from "src/@/components/ui/metric-card";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { ComparisonPeriod } from "#dashboard/types";

interface DashboardMetricCardProps {
    title: string;
    value: string;
    changeValue?: number;
    icon: LucideIcon;
    isLoading?: boolean;
    to?: string;
}

export function MetricCard({
    title,
    value,
    changeValue,
    icon,
    isLoading,
    to,
}: DashboardMetricCardProps) {
    const { comparisonPeriod } = useDashboardContext();

    const getPeriodText = () => {
        if (comparisonPeriod) {
            switch (comparisonPeriod) {
                case ComparisonPeriod.PREVIOUS_DAY:
                    return "vs. previous day";
                case ComparisonPeriod.PREVIOUS_7_DAYS:
                    return "vs. previous 7 days";
                case ComparisonPeriod.PREVIOUS_30_DAYS:
                    return "vs. previous 30 days";
                default:
                    return "vs. previous period";
            }
        }
        return "";
    };

    const subtitle =
        changeValue !== undefined && changeValue !== null ? (
            <MetricChangeText
                value={changeValue}
                periodText={getPeriodText()}
                hideComparison={comparisonPeriod === ComparisonPeriod.NONE}
            />
        ) : (
            <MetricChangeText
                value={10}
                invisible
                periodText={getPeriodText()}
            />
        );

    return (
        <BaseMetricCard
            title={title}
            value={value}
            icon={icon}
            isLoading={isLoading}
            to={to}
            subtitle={subtitle}
        />
    );
}
