import { useMemo } from "react";
import { Package } from "lucide-react";
import { FulfillmentTypeEnum } from "@snackpass/snackpass-types";

import { useOrders } from "../../orders-context";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";

const FULFILLMENT_TYPES = [
    { label: "Pickup", value: FulfillmentTypeEnum.Pickup },
    { label: "Delivery", value: FulfillmentTypeEnum.Delivery },
    { label: "Dine In", value: FulfillmentTypeEnum.DineIn },
];

export const OrdersFulfillmentPicker = () => {
    const { fulfillments, setFulfillments } = useOrders();

    const selectedTypes = useMemo(
        () => new Set(fulfillments as string[]),
        [fulfillments],
    );

    return (
        <FilterSelect2
            title="Fulfillment"
            icon={Package}
            selectedValues={selectedTypes}
            options={FULFILLMENT_TYPES}
            onOptionSelected={function (value: string): void {
                if (selectedTypes.has(value)) {
                    selectedTypes.delete(value);
                } else {
                    selectedTypes.add(value);
                }
                setFulfillments(
                    Array.from(selectedTypes) as FulfillmentTypeEnum[],
                );
            }}
        />
    );
};
