import { createContext, useState, ReactNode } from "react";

interface SearchCommandBarContextType {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export const SearchCommandBarContext = createContext<
    SearchCommandBarContextType | undefined
>(undefined);

export function SearchCommandBarProvider({
    children,
}: {
    children: ReactNode;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    return (
        <SearchCommandBarContext.Provider value={{ isOpen, onOpen, onClose }}>
            {children}
        </SearchCommandBarContext.Provider>
    );
}
