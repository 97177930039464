import {
    mkConfig,
    download,
    asString,
    generateCsv,
    ConfigOptions,
} from "export-to-csv";
import { SetRequired } from "type-fest";

type ExportToCsvOptions = SetRequired<
    Pick<
        ConfigOptions,
        | "filename"
        | "fieldSeparator"
        | "columnHeaders"
        | "title"
        | "showTitle"
        | "useKeysAsHeaders"
    >,
    "filename"
>;

/**
 * Mimic the behavior of previous versions of `export-to-csv`,
 * functional api doesn't make much sense for our use case.
 * This class will also call into the `PartnerAppJsApi` to support
 * opening the csv files.
 */
export class ExportToCsv {
    #config: Required<ConfigOptions>;
    #download: ReturnType<typeof download>;
    #generateCsv: ReturnType<typeof generateCsv>;

    constructor(options: ExportToCsvOptions) {
        this.#config = mkConfig({ ...options, useBom: false });
        this.#download = download(this.#config);
        this.#generateCsv = generateCsv(this.#config);
    }

    generateCsv(data: any[]) {
        const csv = this.#generateCsv(data);
        this.#download(csv);
        window.PartnerAppJsApi?.openTextFile(
            asString(csv),
            "text/csv",
            `${this.#config.filename}.csv`,
        );
    }
}
