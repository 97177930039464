import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// This is legacy code and will be removed once MuL Menus is fully implemented
export const activeProductCategorySlice = createSlice({
    name: "activeProductCategory",
    initialState: "",
    reducers: {
        setActiveProductCategory: (_, action: PayloadAction<string>) =>
            action.payload,
    },
});

export const { setActiveProductCategory } = activeProductCategorySlice.actions;
