import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetSliceState } from "src/core/domain";

export const activeStoreIdSlice = createSlice({
    name: "activeStoreId",
    initialState: "",
    reducers: {
        setActiveStoreIdSliceId: (__, action: PayloadAction<string>) =>
            action.payload,
    },
});

export const { setActiveStoreIdSliceId } = activeStoreIdSlice.actions;

type State = GetSliceState<typeof activeStoreIdSlice>;
export const { getActiveStoreIdSliceId } = {
    getActiveStoreIdSliceId: (state: State) => state.activeStoreId,
};
