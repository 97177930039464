import { Routes as AppRoutes } from "#navigation/routes";
import { WhatsNew } from "#whatsNew";
import Orders from "#orders";
import NoAccessPage from "#utility-pages/no-access-page";
import NotFoundPage from "#utility-pages/not-found-page";
import { SignOut } from "#sign-out";

export const directRoutes = [
    { path: AppRoutes.WhatsNew, Component: WhatsNew },
    { path: AppRoutes.BetaOrders, Component: Orders },
    { path: AppRoutes.NotAllowed, Component: NoAccessPage },
    { path: AppRoutes.NotFound, Component: NotFoundPage },
    { path: AppRoutes.Logout, Component: SignOut },
];

export const lazyRoutes = [
    // Core
    {
        path: AppRoutes.MyAccountTeams,
        import: async () => import("#my-teams"),
    },

    {
        path: AppRoutes.SettingsAccount,
        import: async () => import("#settings/components/account-details"),
    },
    {
        path: AppRoutes.OrdersDetails,
        import: async () => import("#order-details"),
    },
    {
        path: AppRoutes.PurchaseDetails,
        import: async () => import("#order-details"),
    },
    // Reports
    {
        path: AppRoutes.ReportsOverview,
        import: async () => import("#reports/overview"),
    },
    {
        path: AppRoutes.ReportsSalesSummary,
        import: async () => import("#reports/sales-summary"),
    },
    {
        path: AppRoutes.ReportsSalesChannels,
        import: async () => import("#reports/sales-channels"),
    },
    {
        path: AppRoutes.ReportsMenuItemInsights,
        import: async () => import("#reports/menu-item-insights"),
    },
    {
        path: AppRoutes.ReportsMenuCategoryInsights,
        import: async () => import("#reports/menu-category-insights"),
    },
    {
        path: AppRoutes.ReportsLocationSales,
        import: async () => import("#reports/location-sales"),
    },
    {
        path: AppRoutes.ReportsPromotions,
        import: async () => import("#reports/promotions"),
    },
    {
        path: AppRoutes.ReportsLocationMenuCategories,
        import: async () => import("#reports/location-menu-categories"),
    },
    {
        path: AppRoutes.ReportsLocationMenuItems,
        import: async () => import("#reports/location-menu-items"),
    },
    {
        path: AppRoutes.ReportsCustomerDirectoryInsights,
        import: async () =>
            import(
                "#reports/customer-directory-insights/CustomerPurchaseReport"
            ),
    },

    // Financial Reports
    {
        path: AppRoutes.FinancialReportsCash,
        import: async () => import("#financial-reports/screens/cash-report"),
    },
    {
        path: AppRoutes.FinancialReportsEssentials,
        import: async () => import("#essentials-report"),
    },
    {
        path: AppRoutes.FinancialReportsPayoutHistory,
        import: async () =>
            import("#financial-reports/screens/payout-history/payout-history"),
    },

    // Gift Cards
    {
        path: AppRoutes.ReportsGiftCardPurchases,
        import: async () => import("#reports/gift-card-purchases"),
    },
    {
        path: AppRoutes.ReportsGiftCardBalances,
        import: async () => import("#reports/gift-card-balances"),
    },
    {
        path: AppRoutes.ReportsGiftCardTransactions,
        import: async () => import("#reports/gift-card-transactions"),
    },
    {
        path: AppRoutes.ReportsGiftCardLiabilities,
        import: async () => import("#reports/gift-card-liabilities"),
    },
    {
        path: AppRoutes.SettingsIntegrations,
        import: async () => import("#settings-integrations"),
    },

    // Guestbook
    {
        path: AppRoutes.GuestbookConversations,
        import: async () => import("#conversations"),
    },
    {
        path: AppRoutes.GuestbookCampaignsSMS,
        import: async () => import("#sms-campaigns/SMSCampaignsPage"),
    },
    {
        path: AppRoutes.GuestbookCampaignsSMSDetails,
        import: async () => import("#sms-campaigns"),
    },
    {
        path: AppRoutes.GuestbookCampaignsPush,
        import: async () => import("#push-campaigns"),
    },
    {
        path: AppRoutes.CreatePushCampaign,
        import: async () => import("#push-campaigns/pages/create"),
    },
    {
        path: AppRoutes.GuestbookAuditLog,
        import: async () => import("#audit-log"),
    },
    {
        path: AppRoutes.GuestbookCustomers,
        import: async () => import("#reports/customer-directory-insights"),
    },

    // Employee Management
    {
        path: AppRoutes.EmployeeTimeCards,
        import: async () => import("#employees/employee-time-cards"),
    },
    {
        path: AppRoutes.Tipping,
        import: async () => import("#tip-reporting"),
    },
    {
        path: AppRoutes.MinuteToMinute,
        import: async () => import("#minute-to-minute"),
    },

    // Menu & Inventory
    {
        path: AppRoutes.MenuInventoryManager,
        import: async () => import("#inventory"),
    },
    {
        path: AppRoutes.Menu,
        import: async () => import("#menu-editor/mobile-friendly/items"),
    },
    {
        path: AppRoutes.MenuEditor,
        import: async () => import("#menu-editor/mobile-friendly/items"),
    },
    {
        path: `${AppRoutes.NEW_MENU}`,
        import: async () => import("#menu-manager/screens/NewMenuScreen"),
    },
    {
        path: `${AppRoutes.EDIT_MENU}`,
        import: async () => import("#menu-manager/screens/EditMenuScreen"),
    },
    {
        path: `${AppRoutes.MenuManager}`,
        import: async () => import("#menu-manager/screens/MenuTableScreen"),
    },

    // Settings
    {
        path: AppRoutes.SettingsQrCode,
        import: async () => import("#qr-code"),
    },
    {
        path: AppRoutes.SettingsPermissions,
        import: async () =>
            import("#settings/settings-permissions/admins-table"),
    },
    {
        path: AppRoutes.SettingsTax,
        import: async () => import("#settings/settings-tax"),
    },
    {
        path: AppRoutes.SettingsOnlineOrders,
        import: async () => import("#settings/settings-online-orders"),
    },
    {
        path: AppRoutes.SettingsCatering,
        import: async () => import("#settings-catering"),
    },
    {
        path: AppRoutes.SettingsProducts,
        import: async () => import("#settings/settings-products"),
    },
    {
        path: AppRoutes.SettingsBusinessInfo,
        import: async () => import("#settings/settings-business-info"),
    },
    {
        path: AppRoutes.SettingsPlatforms,
        import: async () => import("#settings/settings-platforms"),
    },
    {
        path: AppRoutes.SettingsTipping,
        import: async () => import("#settings-tipping"),
    },
    {
        path: AppRoutes.SettingsOrders,
        import: async () => import("#settings/settings-orders"),
    },
    {
        path: AppRoutes.SettingsLegal,
        import: async () => import("#settings/settings-legal"),
    },
    {
        path: AppRoutes.SettingsBrandAndAppearance,
        import: async () => import("#settings/settings-brand-and-appearance"),
    },
    {
        path: AppRoutes.SettingsAllOrders,
        import: async () => import("#settings/settings-all-orders"),
    },
    {
        path: AppRoutes.SettingsBackOfHouse,
        import: async () => import("#settings/settings-back-of-house"),
    },
    {
        path: AppRoutes.SettingsGiftCard,
        import: async () => import("#settings/settings-gift-card"),
    },
    {
        path: AppRoutes.SettingsEmployees,
        import: async () =>
            import("#time-and-attendance/settings/AttendanceSettings"),
    },
    {
        path: AppRoutes.InternalFees,
        import: async () => import("#settings/settings-fees"),
    },
    {
        path: AppRoutes.InternalSeniorSupport,
        import: async () => import("#settings/settings-senior-support"),
    },
    {
        path: AppRoutes.InternalDevices,
        import: async () => import("#settings/settings-internal-devices"),
    },
    {
        path: AppRoutes.InternalSettings,
        import: async () => import("#settings/settings-internal"),
    },

    // Infrastructure
    {
        path: AppRoutes.Devices,
        import: async () => import("#devices"),
    },
    {
        path: AppRoutes.PrepStations,
        import: async () => import("#prep-stations/prep-stations"),
    },
    {
        path: AppRoutes.SettingsStoreTablesLayout,
        import: async () => import("#table-editor/components/TablesEditor"),
    },

    // Billing
    {
        path: AppRoutes.Billing,
        import: async () => import("#billing"),
    },
    {
        path: AppRoutes.Payouts,
        import: async () => import("#payouts"),
    },
    {
        path: AppRoutes.PayoutsSchedule,
        import: async () => import("#payouts/components/PolicyManagement"),
    },
    {
        path: AppRoutes.Invoices,
        import: async () => import("#invoices"),
    },
    {
        path: AppRoutes.TaxForms,
        import: async () => import("#tax-forms"),
    },

    // Promotions
    {
        path: AppRoutes.CreatePromotionGeneric,
        import: async () =>
            import("#promotion/forms/GenericPromotionFormSwitch"),
    },
    {
        path: AppRoutes.CreatePromotionReward,
        import: async () => import("#promotion/forms/reward"),
    },
    {
        path: AppRoutes.CreatePromotionHappyHour,
        import: async () => import("#promotion/forms/happy-hour"),
    },
    {
        path: AppRoutes.CreatePromotionBogo,
        import: async () => import("#promotion/forms/bogo"),
    },
    {
        path: AppRoutes.CreatePromotionDealDrop,
        import: async () => import("#promotion/forms/deal-drop"),
    },
    {
        path: AppRoutes.CreatePromotionStudents,
        import: async () => import("#promotion/forms/audience-specific"),
    },
    {
        path: AppRoutes.CreatePromotionFirstTime,
        import: async () => import("#promotion/forms/audience-specific"),
    },
    {
        path: AppRoutes.CreatePromotionPromoCode,
        import: async () => import("#promotion/forms/promo-code"),
    },
    {
        path: AppRoutes.CreatePromotionGiftCard,
        import: async () => import("#promotion/forms/GiftCardPromotionForm"),
    },
    {
        path: AppRoutes.Promotion,
        import: async () => import("#promotion"),
    },

    // Multi Menus
    {
        path: AppRoutes.MultiMenus,
        import: async () =>
            import("#menu-editor/multi-menus/screens/menus-desktop"),
    },
    {
        path: AppRoutes.MultiMenusCreate,
        import: async () =>
            import(
                "#menu-editor/multi-menus/screens/multi-menu-settings/multi-menu-creation"
            ),
    },
    {
        path: AppRoutes.MultiMenusSettings,
        import: async () =>
            import(
                "#menu-editor/multi-menus/screens/multi-menu-settings/multi-menu-edition"
            ),
    },
    {
        path: AppRoutes.MultiMenusOutline,
        import: async () =>
            import("#menu-editor/multi-menus/screens/menu-overview-desktop"),
    },
    {
        path: AppRoutes.MultiMenusEdit,
        import: async () =>
            import("#menu-editor/multi-menus/screens/select-items"),
    },
    {
        path: AppRoutes.NewUserAcceptInvite,
        import: async () => import("#accept-invite"),
    },
];
