import { useSelector } from "react-redux";
import { Turtle } from "lucide-react";

import { getUser } from "src/redux/selectors";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";

const NotFoundPage = () => {
    const user = useSelector(getUser);
    const userIdentifier = user?.email || user?.number || "No user";

    return (
        <Alert className="mx-auto mt-8 w-fit">
            <Turtle className="h-4 w-4" />
            <AlertTitle>Page not found.</AlertTitle>
            <AlertDescription className="mt-2">
                <div>
                    Please contact your store administrator if you need further
                    assistance.
                </div>
                <div>You are logged in as: {userIdentifier}</div>
            </AlertDescription>
        </Alert>
    );
};

export default NotFoundPage;
