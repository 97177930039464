import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight, LucideIcon } from "lucide-react";
import Fuse from "fuse.js";

import {
    CommandDialog,
    CommandItem,
    CommandList,
    CommandEmpty,
    CommandInput,
    CommandGroup,
} from "src/@/components/ui/command";
import { useCreateSidebar } from "#navigation/sidebar/hooks/use-create-sidebar";
import { RouteItem, RouteOnClick } from "#navigation/types";
import { Routes } from "#navigation/routes";
import { useSelector } from "react-redux";
import { getActiveStoreSlug } from "src/redux/selectors";
import { useSearchCommandBar } from "./use-search-command-bar";

type Match = {
    before: string;
    match: string;
    after: string;
};

type Matches = {
    titleMatch?: Match;
    keywordMatch?: {
        keyword: string;
        match: Match;
    };
};

type FuseRouteItem = RouteItem & {
    searchableText: string;
};

export function SearchCommandBar() {
    const [query, setQuery] = useState<string>("");
    const navigate = useNavigate();
    const { sections } = useCreateSidebar();
    const slug = useSelector(getActiveStoreSlug);
    const { isOpen, onClose } = useSearchCommandBar();

    const handleItemClick = (route: RouteItem) => {
        if (route.onClick) {
            if (route.onClick === RouteOnClick.GlobalSearch) {
                onClose();
            } else if (route.onClick === RouteOnClick.Intercom) {
                window.Intercom("show");
            }
        } else if (route.url) {
            if (route.externalUrl) {
                if (typeof route.externalUrl === "function") {
                    window.open(route.externalUrl(slug || ""), "_blank");
                } else {
                    window.open(route.externalUrl, "_blank");
                }
            } else {
                navigate(route.url);
            }
        }
        onClose();
    };

    const allRoutes = useMemo(
        () =>
            findLeafRoutes(sections.flatMap((section) => section.items)).filter(
                (route) => route.url !== Routes.Search,
            ),
        [sections],
    );

    const fuse = useMemo(() => {
        const searchableRoutes: FuseRouteItem[] = allRoutes.map((route) => ({
            ...route,
            searchableText: [
                route.title,
                route.title.toLowerCase(),
                ...(route.searchKeywords || []),
                ...(route.searchKeywords?.map((k) => k.toLowerCase()) || []),
            ].join(" "),
        }));

        return new Fuse(searchableRoutes, {
            keys: ["searchableText"],
            threshold: 0.3,
            distance: 100,
            ignoreLocation: true,
            minMatchCharLength: 2,
            isCaseSensitive: false,
            useExtendedSearch: true,
        });
    }, [allRoutes]);

    const filteredRoutes = useMemo(() => {
        if (!query) return allRoutes;

        const lowerQuery = query.toLowerCase();
        const results = fuse.search(lowerQuery);
        return results.map((result) => result.item);
    }, [query, fuse, allRoutes]);

    const getHighlightedText = (
        text: string,
        query: string,
    ): React.ReactNode => {
        if (!query) return text;

        const regex = new RegExp(
            `(${query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
            "gi",
        );
        const parts = text.split(regex);

        return parts.map((part, index) =>
            regex.test(part) ? (
                <span key={index} className="font-semibold text-neutral-900">
                    {part}
                </span>
            ) : (
                part
            ),
        );
    };

    return (
        <CommandDialog open={isOpen} onOpenChange={onClose}>
            <CommandInput
                value={query}
                onValueChange={setQuery}
                placeholder="Type a command or search..."
            />
            <CommandList className="max-h-[70vh]">
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                    {filteredRoutes.map((route, index) => (
                        <CommandItem
                            key={`${route.url}-${index}`}
                            onSelect={() => handleItemClick(route)}
                            className="flex items-center gap-3 px-4 py-2.5"
                            value={`${route.title} ${route.title.toLowerCase()} ${
                                route.searchKeywords?.join(" ") || ""
                            }`}
                        >
                            <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-md border border-neutral-200 bg-white">
                                {route.icon && (
                                    <route.icon className="h-4 w-4 text-neutral-500" />
                                )}
                            </div>

                            <div className="flex flex-1 flex-col">
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">
                                        {query
                                            ? getHighlightedText(
                                                  route.title,
                                                  query,
                                              )
                                            : route.title}
                                    </span>
                                    {route.isBeta && (
                                        <span className="rounded bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                                            Beta
                                        </span>
                                    )}
                                    {route.externalUrl && (
                                        <ChevronRight className="h-3 w-3 text-neutral-400" />
                                    )}
                                </div>

                                {query.length > 0 && route.searchKeywords && (
                                    <span className="text-sm text-neutral-500">
                                        {route.searchKeywords
                                            .filter((keyword) =>
                                                keyword
                                                    .toLowerCase()
                                                    .includes(
                                                        query.toLowerCase(),
                                                    ),
                                            )
                                            .map((keyword, idx) => (
                                                <span
                                                    key={idx}
                                                    className="mr-2"
                                                >
                                                    {getHighlightedText(
                                                        keyword,
                                                        query,
                                                    )}
                                                </span>
                                            ))}
                                    </span>
                                )}
                            </div>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </CommandDialog>
    );
}

function findLeafRoutes(
    routes: RouteItem[],
    prefix = "",
    parentIcon?: LucideIcon,
): RouteItem[] {
    return routes.flatMap((route) => {
        const children = route.items;

        if (children && children.length > 0) {
            return findLeafRoutes(
                children,
                prefix + route.title + " → ",
                route.icon || parentIcon,
            );
        } else {
            return [
                {
                    ...route,
                    title: prefix + route.title,
                    icon: (route.icon || parentIcon) as LucideIcon,
                },
            ];
        }
    });
}
