import { PauseOrders } from "#dashboard/components/pause-orders/pause-orders";
import { ReloadButton } from "#dashboard/components/reload";
import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";

import { OrdersDateSelector } from "./components/date-selector";
import { OrderSalesSummary } from "./components/summary";
import { OrderHistory } from "./components/order-history";
import { OrdersChannelPicker } from "./components/pickers/channel-picker";
import { OrdersProviderPicker } from "./components/pickers/provider-picker";
import { OrdersPaymentTypePicker } from "./components/pickers/payment-type-picker";
import { OrdersFulfillmentPicker } from "./components/pickers/fulfillment-picker";
import { OrdersRefundPicker } from "./components/pickers/refund-picker";
import { OrdersProvider } from "./orders-context";
import { Page } from "src/@/components/ui/page";
import { Button } from "src/@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "lucide-react";
import { MAX_DATE_RANGE } from "#orders/use-query-mongo-orders";

const OrdersLayout = () => {
    const navigate = useNavigate();

    return (
        <OrdersProvider>
            <Page
                className="max-w-full space-y-8"
                title="Orders"
                description="Real-time daily report of your sales. View and manage your orders."
            >
                <div className="space-y-4">
                    <div className="flex items-center space-x-2">
                        <PauseOrders />
                        <ReloadButton />
                    </div>
                    <div className="flex items-center space-x-2 overflow-auto whitespace-nowrap py-1">
                        <OrdersDateSelector />
                        <OrdersChannelPicker />
                        <OrdersFulfillmentPicker />
                        <OrdersPaymentTypePicker />
                        <OrdersProviderPicker />
                        <OrdersRefundPicker />
                    </div>
                </div>
                <OrderSalesSummary />
                <Alert className="max-w-2xl">
                    <AlertDescription className="flex items-center justify-between gap-4">
                        <span>
                            Need historical data? Visit Sales Reports for sales
                            data greater than {MAX_DATE_RANGE} days.
                        </span>
                        <Button
                            variant="outline"
                            size="sm"
                            className="font-medium"
                            onClick={() =>
                                navigate(Routes.FinancialReportsEssentials)
                            }
                        >
                            Sales Reports
                            <ChevronRightIcon className="h-4 w-4" />
                        </Button>
                    </AlertDescription>
                </Alert>
                <OrderHistory />
            </Page>
        </OrdersProvider>
    );
};

export default OrdersLayout;
