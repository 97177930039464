interface SectionHeaderProps {
    title: string;
    className?: string;
}

export const SectionHeader = ({
    title,
    className = "",
}: SectionHeaderProps) => {
    return (
        <div className={`border-b py-2 my-2 ${className}`}>
            <h2 className="text-lg font-semibold">{title}</h2>
        </div>
    );
};
