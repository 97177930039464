import { Navigate } from "react-router-dom";
import { Suspense } from "react";
import { Routes as AppRoutes } from "../routes";
import { useRoutePermissions } from "#navigation/sidebar/hooks/use-route-permissions";
import { Spinner } from "src/@/components/ui/spinner";

interface ProtectedRouteProps {
    route: AppRoutes;
    element: React.ReactElement;
    fallbackPath?: AppRoutes;
}

export function ProtectedRoute({
    route,
    element,
    fallbackPath = AppRoutes.NotAllowed,
}: ProtectedRouteProps) {
    const { hasRoutePermission } = useRoutePermissions();

    return !hasRoutePermission(route) ? (
        <Navigate to={fallbackPath} replace />
    ) : (
        <Suspense
            fallback={
                <div className="flex justify-center items-center h-[50vh] w-full">
                    <Spinner size="lg" className="h-6 w-6" />
                </div>
            }
        >
            {element}
        </Suspense>
    );
}
