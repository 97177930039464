import api from "src/api/rest";

/**
 * Fetch the products for a given store using the legacy products endpoint.
 * XXX: For the moment, this is just a wrapper around our old stores/:id/products endpoint
 * In the near future, we will be pulling from a different endpoint / collection.
 */
export async function fetchStoreProducts(storeID: string) {
    const result = await api.stores.getProducts(storeID);
    return result.data;
}
