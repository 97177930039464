/**
 * Saas Account
 */
export type SaasAccount = {
    id: string;
    storeId: string;
    type: "stripe";
    customerId: string;
    activeAt: Date;
    subscriptionValidation: SubscriptionValidationSetting;
    onboardingPaymentMethodStatus: PaymentMethodStatus;
    createdAt: string;
    updatedAt: string;
};

export enum SubscriptionValidationSetting {
    default = "default",
    disabled = "disabled",
    serviceCharge = "serviceCharge",
    skipMultiple = "skipMultiple",
}

export enum PaymentMethodStatus {
    none = "none",
    methodAdded = "method_added", // card, bank, or microdeposit confirmed
    microdepositPending = "microdeposit_pending",
    microdepositFailed = "microdeposit_failed",
}
