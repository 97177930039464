import { ChevronRight } from "lucide-react";
import { useState } from "react";

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import {
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
} from "src/@/components/ui/sidebar";
import { NavButtonContent } from "./nav-button-content";
import { isUrlActive } from "../utils";
import { useLocation } from "react-router-dom";
import { RouteItem } from "../../types";
import { NavButton } from "#navigation/sidebar/components/nav-button";

interface NavMenuSubItemsProps {
    items: RouteItem[];
    onItemClick?: (itemPath: string | null) => void;
}

export function NavMenuSubItems({ items, onItemClick }: NavMenuSubItemsProps) {
    const location = useLocation();
    const pathname = location.pathname;
    const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>(
        {},
    );

    const shouldExpandForPath = (items: RouteItem[]): boolean => {
        return items.some((item: RouteItem): boolean => {
            if (isUrlActive(pathname, item.url || "")) {
                return true;
            }
            if (item.items) {
                return shouldExpandForPath(item.items);
            }
            return false;
        });
    };

    const handleToggle = (itemUrl: string) => {
        setExpandedItems((prev) => ({
            ...prev,
            [itemUrl]: !prev[itemUrl],
        }));
        if (onItemClick) {
            onItemClick(itemUrl);
        }
    };

    return (
        <>
            {items.map((item) => (
                <SidebarMenuSubItem key={item.url}>
                    {item.items ? (
                        <Collapsible
                            open={
                                expandedItems[item.url || ""] ||
                                shouldExpandForPath(item.items)
                            }
                            className="group/sub-item w-full"
                        >
                            <CollapsibleTrigger
                                asChild
                                onClick={() => handleToggle(item.url || "")}
                            >
                                <SidebarMenuSubButton className="cursor-pointer select-none">
                                    <NavButtonContent item={item} />
                                    <ChevronRight className="ml-auto size-4 shrink-0 text-muted-foreground transition-transform duration-200 group-data-[state=open]/sub-item:rotate-90" />
                                </SidebarMenuSubButton>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="overflow-hidden transition-all">
                                <SidebarMenuSub>
                                    <NavMenuSubItems
                                        items={item.items}
                                        onItemClick={onItemClick}
                                    />
                                </SidebarMenuSub>
                            </CollapsibleContent>
                        </Collapsible>
                    ) : (
                        <NavButton item={item} />
                    )}
                </SidebarMenuSubItem>
            ))}
        </>
    );
}
