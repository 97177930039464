import React from "react";
import { Link } from "react-router-dom";

import { Button } from "src/@/components/ui/button";

type ButtonProps = {
    text: string;
    value: string;
};

export const OrderHistoryDetailsButton = ({ text, value }: ButtonProps) => (
    <Button className="no-underline" asChild variant={"link"}>
        <Link className="text-neutral-950" to={`/purchases/${value}`}>
            {text}
        </Link>
    </Button>
);
