import { Moment } from "moment";

export enum CurrentPeriod {
    TODAY = "Today",
    YESTERDAY = "Yesterday",
    LAST7DAYS = "Last 7 Days",
    LAST30DAYS = "Last 30 Days",
    LAST90DAYS = "Last 90 Days",
    LAST365DAYS = "Last 365 Days",
    CUSTOM = "Custom",
}

export type Range = {
    startDate: Moment;
    endDate: Moment;
};

export enum ComparisonPeriod {
    NONE = "none",
    PREVIOUS_DAY = "previous_day",
    THIS_DAY_LAST_WEEK = "this_day_last_week",
    THIS_DAY_LAST_MONTH = "this_day_last_month",
    THIS_DAY_LAST_YEAR = "this_day_last_year",
    PREVIOUS_7_DAYS = "previous_7_days",
    PREVIOUS_30_DAYS = "previous_30_days",
    PREVIOUS_90_DAYS = "previous_90_days",
    THIS_WEEK_LAST_YEAR = "this_week_last_year",
    THIS_MONTH_LAST_YEAR = "this_month_last_year",
    THIS_90_DAYS_LAST_YEAR = "this_90_days_last_year",
    PREVIOUS_365_DAYS = "previous_365_days",
    CUSTOM = "custom",
}

export enum Granularity {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}
