import React, { FC } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "src/@/components/ui/alert-dialog";

const CacheBustingModal: FC<InjectedProps> = ({ show, handleHide }) => {
    const onClickReload = () => window.location.reload();

    return (
        <AlertDialog open={show} onOpenChange={handleHide}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Dashboard Update</AlertDialogTitle>
                    <AlertDialogDescription>
                        A new update is available. Would you like to refresh the
                        page?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={handleHide}>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onClickReload}>
                        Refresh
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default connectModal({ name: "CacheBustingModal" })(CacheBustingModal);
