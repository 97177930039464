import { Routes } from "#navigation/routes";
import { ChevronRight, CircleUserRound, LogOut, Users } from "lucide-react";
import { Link } from "react-router-dom";

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "src/@/components/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";
import {
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from "src/@/components/ui/sidebar";
import { useActiveUser } from "src/redux/selectors";

export function NavUser() {
    const { isMobile } = useSidebar();
    const user = useActiveUser();
    return (
        <SidebarMenu className="mb-0">
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground select-none"
                        >
                            <Avatar className="h-8 w-8 rounded-lg overflow-hidden">
                                <AvatarImage
                                    src={user?.profilePicUrl ?? ""}
                                    alt={user?.name ?? ""}
                                    className="object-cover"
                                />
                                <AvatarFallback className="rounded-lg">
                                    {user?.name
                                        ?.split(" ")
                                        .map((n) => n[0])
                                        .join("") ?? ""}
                                </AvatarFallback>
                            </Avatar>
                            <div className="grid flex-1 text-left text-sm leading-tight select-none">
                                <span className="truncate font-semibold">
                                    {user?.name ?? ""}
                                </span>
                                <span className="truncate text-xs">
                                    {user?.email ?? ""}
                                </span>
                            </div>
                            <ChevronRight className="ml-auto size-4" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="w-[--radix-dropdown-menu-trigger-width] min-w-56"
                        side={isMobile ? "bottom" : "right"}
                        align="end"
                        sideOffset={8}
                    >
                        <DropdownMenuLabel className="font-normal">
                            <div className="flex items-center gap-2">
                                <Avatar className="size-8 shrink-0 rounded-md">
                                    <AvatarImage
                                        src={user?.profilePicUrl ?? ""}
                                        alt={user?.name ?? ""}
                                        className="object-cover"
                                    />
                                    <AvatarFallback className="rounded-md text-xs">
                                        {user?.name
                                            ?.split(" ")
                                            .map((n) => n[0])
                                            .join("") ?? ""}
                                    </AvatarFallback>
                                </Avatar>
                                <div className="grid flex-1 text-left">
                                    <span className="truncate text-sm font-medium leading-none">
                                        {user?.name ?? ""}
                                    </span>
                                    <span className="truncate text-xs text-muted-foreground">
                                        {user?.email ?? ""}
                                    </span>
                                </div>
                            </div>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem
                                className="cursor-pointer"
                                asChild
                            >
                                <Link
                                    to={Routes.SettingsAccount}
                                    className="flex items-center w-full text-foreground"
                                >
                                    <CircleUserRound className="mr-2 size-4" />
                                    <span>Profile</span>
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="cursor-pointer"
                                asChild
                            >
                                <Link
                                    to={Routes.MyAccountTeams}
                                    className="flex items-center w-full text-foreground"
                                >
                                    <Users className="mr-2 size-4" />
                                    <span>My Teams</span>
                                </Link>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="cursor-pointer" asChild>
                            <Link
                                to={Routes.Logout}
                                className="flex items-center w-full text-foreground"
                            >
                                <LogOut className="mr-2 size-4" />
                                <span>Sign Out</span>
                            </Link>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
