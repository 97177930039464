import axios from "axios";

import config from "#config";
import { firebaseAuth } from "#app/firebase";

const client = axios.create({
    baseURL: config.apiUrl,
    timeout: 30 * 1000,
});

client.interceptors.request.use(async (config) => {
    const token = await firebaseAuth.currentUser?.getIdToken();
    if (token) {
        config.headers = {
            ...(config.headers ?? {}),
            Authorization: `Bearer ${token}`,
        };
    }
    return config;
});

export { client };
