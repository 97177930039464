/**
 * Assertion function to check the condition.
 *
 * @example
 *
 * ```tsx
 * invariant(a === b, "a equals b"); //=> throws "Invariant Violation: a equals b" if a != b
 * ```
 */
export function invariant(
    condition: unknown,
    message: string,
): asserts condition {
    if (!condition) {
        throw new InvariantViolationError(message);
    }
}

/**
 * Threw by `invariant` helper when the invariant is violated.
 */
export class InvariantViolationError extends Error {
    // The framesToPop property is used in React to remove the topmost frame
    // from the stack trace when an error is thrown. We don't care about our own frame.
    framesToPop = 1;

    constructor(message: string) {
        super(`Precondition failed: ${message}`);
        Object.setPrototypeOf(this, InvariantViolationError.prototype);
    }
}
