import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "@snackpass/snackpass-types";

export type UserState = (IUser & { intercomHash?: string }) | null;

const userInitialState = null as UserState;

export const userSlice = createSlice({
    name: "user",
    initialState: userInitialState,
    reducers: {
        setUser: (_, action: PayloadAction<UserState>) => action.payload,
        clearUser: () => null,
    },
});

export const { setUser, clearUser } = userSlice.actions;
