import moment, { Moment } from "moment-timezone";
import { useEffect, useRef, useState } from "react";

import { DefinedDates } from "#date-picker/lib";
import { genHash } from "#core/utils";

import { useStoreTZAdjustedTimeRange } from "../use-store-tz-adjusted-time-range";
import { useActiveStoreTimezone } from "../use-store-timezone";

import { calculatePreviousCustom, calculatePreviousDefined } from "./lib";

type CalculatedDates = {
    calculatedDates: {
        adjStartDate: moment.Moment;
        adjEndDate: moment.Moment;
        adjPreviousStartDate: moment.Moment;
        adjPreviousEndDate: moment.Moment;
    };
    hash: string;
};

/**
 * Accepts the assumed global start and end date and a description of the duration,
 * it then returns the necessary start and end dates both current and previous
 * adjusted to the store's timezone
 */
export const usePreviousDates = (
    globalDuration: DefinedDates,
    globalStartDate: Moment | null,
    globalEndDate: Moment | null,
): CalculatedDates => {
    const tz = useActiveStoreTimezone();
    const DEFAULT_START = moment().tz(tz).subtract(6, "day");
    const DEFAULT_END = moment().tz(tz);

    const {
        startDate: adjStartDate,
        endDate: adjEndDate,
        hash: adjGlobalDatesHash,
    } = useStoreTZAdjustedTimeRange({
        startDate: globalStartDate || DEFAULT_START,
        endDate: globalEndDate || DEFAULT_END,
    });

    const calculatedDates = useRef<CalculatedDates>();
    const [previousStartDate, setPreviousStartDate] = useState(DEFAULT_START);
    const [previousEndDate, setPreviousEndDate] = useState(DEFAULT_END);

    const {
        startDate: adjPreviousStartDate,
        endDate: adjPreviousEndDate,
        hash: adjPrevHash,
    } = useStoreTZAdjustedTimeRange({
        startDate: previousStartDate,
        endDate: previousEndDate,
    });

    useEffect(() => {
        if (globalDuration === DefinedDates.CUSTOM) {
            const { previousEndDate, previousStartDate } =
                calculatePreviousCustom({
                    relativeEndDate: adjEndDate,
                    relativeStartDate: adjStartDate,
                });
            setPreviousEndDate(previousEndDate);
            setPreviousStartDate(previousStartDate);
        } else {
            const { previousEndDate, previousStartDate } =
                calculatePreviousDefined({
                    globalDuration: globalDuration,
                    relativeEndDate: adjEndDate,
                });
            setPreviousEndDate(previousEndDate);
            setPreviousStartDate(previousStartDate);
        }
    }, [adjGlobalDatesHash]);

    // i.e. only update the return if previous data changes
    // previous will always update after current so it ensures that
    // we are only returning once both have updated; this is susceptible to
    // if the previous date does not change but the current does. ideally this
    // would not be possible
    const previousHash = useRef<string>();
    if (previousHash.current !== adjPrevHash || !calculatedDates.current) {
        previousHash.current = adjPrevHash;
        calculatedDates.current = {
            calculatedDates: {
                /** global end date adjusted to the store's timezone */
                adjEndDate,
                /** global start date adjusted to the store's timezone */
                adjStartDate,
                adjPreviousEndDate,
                adjPreviousStartDate,
            },
            hash: genHash(
                adjEndDate.unix(),
                adjStartDate.unix(),
                adjPreviousEndDate.unix(),
                adjPreviousStartDate.unix(),
            ),
        };
        return calculatedDates.current;
    }

    return calculatedDates.current;
};
