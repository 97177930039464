import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const mfaPrecheckSlice = createSlice({
    name: "mfaPrecheck",
    initialState: {
        value: false,
    },
    reducers: {
        setMfaPrecheck: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const { setMfaPrecheck } = mfaPrecheckSlice.actions;
