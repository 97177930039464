import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/redux/store";

export const selectAuthState = (state: RootState) => state.auth;

export const selectNeedsToReauthenticate = createSelector(
    [selectAuthState],
    (authState) => authState.needsToReauthenticate,
);
