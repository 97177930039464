import { useCallback, useMemo, useState } from "react";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";
import moment from "moment";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { DateRangePicker } from "src/@/components/DateRangePicker";
import { ComparisonPeriod } from "#dashboard/types";
import {
    getComparisonRange,
    getComparisonOptions,
    NO_COMPARISON_OPTION,
} from "#dashboard/utils/date-range-utils";

export const ComparisonPicker = () => {
    const {
        currentPeriod,
        comparisonPeriod,
        currentPeriodStartEnd,
        setComparison,
        clearComparison,
        comparisonPeriodStartEnd,
    } = useDashboardContext();

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [customDates, setCustomDates] = useState<{ from: Date; to: Date }>({
        from: new Date(),
        to: new Date(),
    });

    const options = useMemo(
        () => getComparisonOptions(currentPeriod),
        [currentPeriod],
    );

    const handleComparisonChange = useCallback(
        (value: string) => {
            if (
                !currentPeriodStartEnd?.startDate ||
                !currentPeriodStartEnd?.endDate
            )
                return;

            const option = options.find(
                (opt: { label: string; value: ComparisonPeriod }) =>
                    opt.label === value,
            );
            if (!option) return;

            if (option.value === ComparisonPeriod.NONE) {
                clearComparison();
                return;
            }

            if (option.value === comparisonPeriod) {
                clearComparison();
                return;
            }

            if (option.value === ComparisonPeriod.CUSTOM) {
                setIsDatePickerOpen(true);
                return;
            }

            const comparisonRange = getComparisonRange(
                currentPeriodStartEnd,
                option.value,
            );
            setComparison(option.value, comparisonRange);
        },
        [
            options,
            currentPeriodStartEnd,
            setComparison,
            clearComparison,
            comparisonPeriod,
        ],
    );

    const handleDateChange = useCallback(
        (value: { from?: Date; to?: Date } | undefined) => {
            if (!value?.from || !value?.to) {
                return;
            }

            setCustomDates({
                from: value.from,
                to: value.to,
            });

            const customStart = moment(value.from);
            const customEnd = moment(value.to);

            setComparison(ComparisonPeriod.CUSTOM, {
                startDate: customStart.startOf("day"),
                endDate: customEnd.endOf("day"),
            });
        },
        [setComparison],
    );

    const formatDate = (date: moment.Moment) => {
        return date.format("M/D/YY");
    };

    const getSelectedLabel = () => {
        if (!comparisonPeriod || comparisonPeriod === ComparisonPeriod.NONE) {
            return NO_COMPARISON_OPTION.label;
        }
        return (
            options.find(
                (opt: { label: string; value: ComparisonPeriod }) =>
                    opt.value === comparisonPeriod,
            )?.label ?? NO_COMPARISON_OPTION.label
        );
    };

    const getButtonText = () => {
        return getSelectedLabel();
    };

    const getSubtitle = () => {
        if (!comparisonPeriodStartEnd) return "";
        return `${formatDate(comparisonPeriodStartEnd.startDate)} - ${formatDate(comparisonPeriodStartEnd.endDate)}`;
    };

    if (options.length === 0) return null;

    return (
        <>
            <FilterSelect
                customButton={
                    <Button variant="outline" size="sm">
                        vs.{" "}
                        <span className="hidden md:block">
                            {getButtonText()}
                        </span>
                    </Button>
                }
                title={getButtonText()}
                subtitle={getSubtitle()}
                selectedValues={new Set([getSelectedLabel()])}
                options={options.map(
                    (opt: { label: string; value: ComparisonPeriod }) => ({
                        label: opt.label,
                        value: opt.label,
                    }),
                )}
                onOptionSelected={handleComparisonChange}
            />
            <DateRangePicker
                selected={customDates}
                onSelect={(value) => {
                    if (!value) {
                        handleDateChange({
                            from: customDates.from,
                            to: customDates.from,
                        });
                    } else if (!value?.to) {
                        handleDateChange({ from: value.from, to: value.from });
                    } else {
                        const { from, to } = value;
                        handleDateChange({ from, to });
                    }
                }}
                open={isDatePickerOpen}
                onOpenChange={setIsDatePickerOpen}
                disableFutureDates
                className="invisible absolute"
            />
        </>
    );
};
