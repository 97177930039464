import {
    PaymentProvider,
    PurchaseReportData,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import { match } from "ts-pattern";

import { ReactComponent as StatusCircle } from "src/assets/icons/status-circle.svg";

type Props = { purchaseReportData?: PurchaseReportData };

const unpaidComponent = <span className="text-red-medium">Unpaid</span>;

const StatusTextSpan = ({ purchaseReportData }: Props) => {
    const status = purchaseReportData?.status;
    const isUnpaid = purchaseReportData
        ? purchaseReportData.purchase?.paymentProviderId ===
          PaymentProvider.unpaid
        : false;

    const whatToShow = isUnpaid ? unpaidComponent : null;

    return match(status)
        .with(PurchaseStatus.canceled, () =>
            isUnpaid ? (
                <span>Canceled {unpaidComponent}</span>
            ) : (
                <span>Refunded</span>
            ),
        )
        .with(PurchaseStatus.completed, () => (
            <span>Completed {whatToShow}</span>
        ))
        .with(PurchaseStatus.started, () => <span>Started {whatToShow}</span>)
        .with(PurchaseStatus.received, () => <span>Received {whatToShow}</span>)
        .with(undefined, () => <></>)
        .exhaustive();
};

const calculateStatus = ({ purchaseReportData }: Props) => {
    const status = purchaseReportData?.status;

    const fillClassName = match(status)
        .with(PurchaseStatus.canceled, () => "fill-red-medium")
        .with(PurchaseStatus.completed, () => "fill-green-medium")
        .with(PurchaseStatus.started, () => "fill-yellow-medium")
        .with(PurchaseStatus.received, () => "fill-success-light")
        .with(undefined, () => "")
        .exhaustive();

    return (
        <>
            <StatusCircle className={fillClassName} />
            <StatusTextSpan purchaseReportData={purchaseReportData} />
        </>
    );
};

export const Status = ({ purchaseReportData }: Props) => {
    const statusIndicator = calculateStatus({ purchaseReportData });
    return (
        <div className="flex items-center space-x-2 overflow-auto">
            {statusIndicator}
        </div>
    );
};
