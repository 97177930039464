import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type InventoryItem = {
    storeId: string;
    name: string;
    sku: string;
    costPerUnit: {
        currency: "USD";
        amount: number;
    };
    baseUnit: string;
    isHidden: boolean;
    id: string;
};

export type InventoryReport = {
    inventoryItem: InventoryItem;
    unitsOnHand: number;
    consumedAmount: number;
    totalCost: number;
    totalCostValue: string;
};

export type InventoryState = {
    reports: InventoryReport[];
    items: InventoryItem[];
    startDate: Date | null;
    endDate: Date | null;
    isFetching: boolean;
    topUpOnHand: Record<string, number>;
};

const inventoryInitialState: InventoryState = {
    reports: [],
    items: [],
    startDate: null,
    endDate: null,
    isFetching: false,
    topUpOnHand: {},
};

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: inventoryInitialState,
    reducers: {
        setInventoryItems: (state, action: PayloadAction<InventoryItem[]>) => {
            state.items = action.payload;
        },
        setInventoryReports: (
            state,
            action: PayloadAction<InventoryReport[]>,
        ) => {
            state.reports = action.payload;
        },
        setInventoryStartDate: (state, action: PayloadAction<Date>) => {
            state.startDate = action.payload;
        },
        setInventoryEndDate: (state, action: PayloadAction<Date>) => {
            state.endDate = action.payload;
        },
        setInventoryIsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        setTopUpOnHand: (
            state,
            action: PayloadAction<{ topUp: number; item: string }>,
        ) => {
            state.topUpOnHand[action.payload.item] = action.payload.topUp;
        },
        clearTopUpOnHand: (state) => {
            state.topUpOnHand = {};
        },
    },
});

export const {
    setInventoryItems,
    setInventoryReports,
    setInventoryStartDate,
    setInventoryEndDate,
    setInventoryIsFetching,
    setTopUpOnHand,
    clearTopUpOnHand,
} = inventorySlice.actions;
