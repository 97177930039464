import { IStore } from "@snackpass/snackpass-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ActiveStoreState = IStore | null;

const activeStoreInitialState: ActiveStoreState = null;

export const activeStoreSlice = createSlice({
    name: "activeStore",
    initialState: activeStoreInitialState as ActiveStoreState, // XXX: TS is evaluating to null if not cast
    reducers: {
        setActiveStore: (_, action: PayloadAction<IStore>) => action.payload,
    },
});

export const { setActiveStore } = activeStoreSlice.actions;
