import { gql } from "@apollo/client";

export const CreateCampaign = gql`
    mutation CreateStoreCampaign($storeId: ID!, $data: CreateCampaignInput!) {
        createCampaign(storeId: $storeId, data: $data) {
            id
        }
    }
`;

export const CreateShiftByAdmin = gql`
    mutation CreateShiftByAdmin(
        $storeId: ID!
        $employeeId: ID!
        $payPeriodId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        createShiftByAdmin(
            storeId: $storeId
            employeeId: $employeeId
            payPeriodId: $payPeriodId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;

export const UpdateShiftByAdmin = gql`
    mutation UpdateShiftByAdmin(
        $storeId: ID!
        $shiftId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        updateShiftByAdmin(
            storeId: $storeId
            shiftId: $shiftId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;

export const DeleteShiftByAdmin = gql`
    mutation DeleteShiftByAdmin($storeId: ID!, $shiftId: ID!) {
        deleteShiftByAdmin(storeId: $storeId, shiftId: $shiftId) {
            id
        }
    }
`;
