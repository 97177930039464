import { cn } from "src/@/lib/utils";

function Skeleton({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn(
                "rounded-md animate-shimmer bg-gradient-to-r from-transparent via-neutral-400/40 to-transparent dark:via-neutral-200/20 bg-[length:1200px_100%]",
                className,
            )}
            role="progressbar"
            {...props}
        />
    );
}

export { Skeleton };
