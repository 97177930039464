import { Link } from "react-router-dom";
import { LucideIcon, Loader2, TrendingUp } from "lucide-react";
import { Card, CardContent } from "./card";
import { ReactNode } from "react";

interface MetricCardProps {
    title: string;
    value: string;
    icon: LucideIcon;
    isLoading?: boolean;
    to?: string;
    subtitle?: ReactNode;
}

interface MetricChangeTextProps {
    value: number;
    periodText?: string;
    invisible?: boolean;
    hideComparison?: boolean;
}

export const MetricChangeText = ({
    value,
    periodText,
    invisible,
    hideComparison = false,
}: MetricChangeTextProps) => {
    const isPositive = value > 0;

    return (
        <p
            className={`flex items-center gap-1 text-xs text-muted-foreground ${
                invisible || hideComparison ? "invisible" : ""
            }`}
        >
            {isPositive ? "+" : ""}
            {value}% {periodText}{" "}
            {isPositive && <TrendingUp className="h-3 w-3 text-emerald-500" />}
        </p>
    );
};

const MetricCardContent = ({
    title,
    value,
    subtitle,
    icon: Icon,
    isLoading = false,
}: MetricCardProps) => (
    <CardContent className="p-6">
        <div className="flex items-start justify-between">
            <div className="space-y-1">
                <h3 className="text-sm font-medium text-muted-foreground">
                    {title}
                </h3>
                {isLoading ? (
                    <div className="flex items-center space-x-2">
                        <Loader2 className="h-4 w-4 animate-spin" />
                    </div>
                ) : (
                    <>
                        <div className="text-2xl font-bold">{value}</div>
                        {subtitle}
                    </>
                )}
            </div>
            <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
    </CardContent>
);

export function MetricCard(props: MetricCardProps) {
    const { to, ...contentProps } = props;

    if (to) {
        return (
            <Link to={to} className="block no-underline">
                <Card className="cursor-pointer transition-shadow duration-200 hover:shadow-md">
                    <MetricCardContent {...contentProps} />
                </Card>
            </Link>
        );
    }

    return (
        <Card>
            <MetricCardContent {...contentProps} />
        </Card>
    );
}
