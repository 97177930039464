import React, { CSSProperties } from "react";
import { Tag as AntTag } from "antd";
import { SystemColors } from "@snackpass/design-system";

type Props = {
    type?: TagType;
    text: string | number | any;
    style?: CSSProperties;
    onClick?: any;
};

type TagType = "beta" | "warning" | "error" | "success";

const TAG_TYPE_TO_COLOR: { [type in TagType]: string } = {
    beta: SystemColors.v1.white,
    warning: SystemColors.v1.black,
    error: SystemColors.v1.white,
    success: SystemColors.v1.white,
};

const typeToBackgroundColor: { [type in TagType]: string } = {
    beta: SystemColors.v1.candy50,
    warning: SystemColors.v1.macaroni40,
    error: SystemColors.v1.melon50,
    success: SystemColors.v1.parsley50,
};

export default function Tag({ style, text, type, onClick }: Props) {
    if (!text) {
        return null;
    }

    return (
        <>
            <AntTag
                onClick={onClick}
                style={{
                    border: "none",
                    fontWeight: "bold",
                    padding: "4px 7px",
                    fontSize: 12,
                    cursor: "pointer",
                    borderRadius: 20,
                    marginRight: 0,
                    color: type
                        ? TAG_TYPE_TO_COLOR[type]
                        : SystemColors.v1.gray80,
                    backgroundColor: type
                        ? typeToBackgroundColor[type]
                        : "#efefef",
                    ...style,
                }}
            >
                {text}
            </AntTag>
        </>
    );
}
