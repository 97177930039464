import { MenuService, legacyMenuClient } from "@snackpass/menus-sdk";

import config from "#config";
import { client } from "src/api/rest/client";

export type StoreMenuService = ReturnType<typeof menuService>;

let _menuServiceCache: { [k: string]: StoreMenuService } = {};

export const menuService = MenuService(
    legacyMenuClient(client, {
        channel: "@snackpass/restaurant",
        host: config.baseHost,
    }),
);

/**
 * Provides a menu service for a given storeID.
 * This function caches the menu service based on the storeID.
 *
 * ONLY USE THIS FUNCTION IF YOU NEED THE MENU SERVICE OUTSIDE OF THE MENU SERVICE PROVIDER.
 */
export function provideMenuService(storeID: string): StoreMenuService {
    if (!_menuServiceCache[storeID]) {
        // Clear cache if the storeID.
        _menuServiceCache = {};

        _menuServiceCache[storeID] = menuService(storeID);
    }

    return _menuServiceCache[storeID];
}
