import { RouteItem, RouteOnClick } from "../../types";
import { SidebarMenuSubButton } from "src/@/components/ui/sidebar";
import { NavButtonContent } from "./nav-button-content";
import { isUrlActive } from "#navigation/sidebar/utils";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActiveStoreSlug } from "src/redux/selectors";
import { Link } from "react-router-dom";
import { useSearchCommandBar } from "#search-command-bar/use-search-command-bar";

export function NavButton({ item }: { item: RouteItem }) {
    const slug = useSelector(getActiveStoreSlug);
    const location = useLocation();
    const { onOpen } = useSearchCommandBar();
    const pathname = location.pathname;
    const isActive = isUrlActive(pathname, item.url || "");

    if (item.externalUrl) {
        const externalUrl =
            typeof item.externalUrl === "function"
                ? item.externalUrl(slug || "")
                : item.externalUrl;

        return (
            <SidebarMenuSubButton asChild isActive={isActive}>
                <a href={externalUrl} target="_blank" rel="noopener noreferrer">
                    <NavButtonContent item={item} />
                </a>
            </SidebarMenuSubButton>
        );
    }
    const handleItemClick = (item: RouteItem) => {
        if (item.onClick) {
            if (item.onClick === RouteOnClick.GlobalSearch) {
                onOpen();
            } else if (item.onClick === RouteOnClick.Intercom) {
                window.Intercom("show");
            }
        }
    };

    return (
        <SidebarMenuSubButton
            asChild
            isActive={isActive}
            onClick={() => handleItemClick(item)}
        >
            <Link to={item.url || ""}>
                <NavButtonContent item={item} />
            </Link>
        </SidebarMenuSubButton>
    );
}
