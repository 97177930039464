import { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { useDashboardContext } from "./use-dashboard-context";
import { logAndSendError } from "src/utils/errors";
import moment from "moment";
import { Granularity } from "#dashboard/types";

interface SalesReportResponseData {
    storeId: string;
    startDate: string;
    endDate: string;
    comparedToStartDate: string;
    comparedToEndDate: string;
    salesReport: SalesReport[];
    salesReportComparedTo: SalesReport[];
    salesHourlyAggregateReport: HourlyAggregateReport[];
    salesHourlyAggregateReportComparedTo: HourlyAggregateReport[];
    salesDailyAggregateReport: DailyAggregateReport[];
    salesDailyAggregateReportComparedTo: DailyAggregateReport[];
    success: boolean;
}

interface SalesReport {
    date: string; //   "date": "2024-10-29",
    netSales: SalesDetails;
    orders: SalesDetails;
    tips: SalesDetails;
    customerToSnackpassFees: SalesDetails;
    processingFees: SalesDetails;
    snackpassFees: SalesDetails;
    taxesRemittedForSnackpass: SalesDetails;
    taxesRemittedForStore: SalesDetails;
    taxesSnackpassOwes: SalesDetails;
    taxesYouOwe: SalesDetails;
    actualPayout: SalesDetails;
    expectedCash: SalesDetails;
    expectedPayout: SalesDetails;
    items: SalesDetails;
    refundTotal: SalesDetails;
    purchaseDiscount: SalesDetails;
    storeToCustomerFeeTotal: SalesDetails;
    giftCardPurchases: SalesDetails;
}

interface SalesDetails {
    total: number;
    snackpass: number;
    thirdParty: number;
}

interface HourlyAggregateReport {
    hour: string;
    netSales: number;
    orders: number;
}

interface DailyAggregateReport {
    day:
        | "Monday"
        | "Tuesday"
        | "Wednesday"
        | "Thursday"
        | "Friday"
        | "Saturday"
        | "Sunday"; //        "day": "Monday",
    netSales: number;
    orders: number;
}

interface HourlySalesData {
    time:
        | "12:00:00 AM"
        | "1:00:00 AM"
        | "2:00:00 AM"
        | "3:00:00 AM"
        | "4:00:00 AM"
        | "5:00:00 AM"
        | "6:00:00 AM"
        | "7:00:00 AM"
        | "8:00:00 AM"
        | "9:00:00 AM"
        | "10:00:00 AM"
        | "11:00:00 AM"
        | "12:00:00 PM"
        | "1:00:00 PM"
        | "2:00:00 PM"
        | "3:00:00 PM"
        | "4:00:00 PM"
        | "5:00:00 PM"
        | "6:00:00 PM"
        | "7:00:00 PM"
        | "8:00:00 PM"
        | "9:00:00 PM"
        | "10:00:00 PM"
        | "11:00:00 PM"; //  "hour": "16:00:00",
    current: number;
    previous: number;
}

export const useSalesBigquery = () => {
    const {
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
        channels,
        providers,
        paymentMethods,
        granularity,
    } = useDashboardContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [hourlyData, setHourlyData] = useState<HourlySalesData[]>([]);
    const [reportData, setReportData] =
        useState<SalesReportResponseData | null>(null);

    const store = useSelector(getActiveStore);

    const fetchSalesData = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const params = {
                storeId: store._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                comparedToStartDate:
                    comparisonPeriodStartEnd?.startDate?.format("YYYY-MM-DD") ||
                    "",
                comparedToEndDate:
                    comparisonPeriodStartEnd?.endDate?.format("YYYY-MM-DD") ||
                    "",
                timezone: getStoreTimezone(store),
                channel: JSON.stringify(providers),
                source: JSON.stringify(channels),
                paymentProviders: JSON.stringify(paymentMethods),
            };

            const response = await api.reports.getSalesReport(params);

            // @ts-ignore
            const data = response.data as SalesReportResponseData;
            setReportData(data);

            const currentData = data.salesHourlyAggregateReport || [];
            const compareData = data.salesHourlyAggregateReportComparedTo || [];

            const transformedHourlyData = Array.from(
                { length: 24 },
                (_, idx) => {
                    const hour = idx;
                    const timeStr = moment()
                        .startOf("day")
                        .add(hour, "hours")
                        .format("h A") as HourlySalesData["time"];

                    return {
                        time: timeStr,
                        current: currentData[idx]?.netSales || 0,
                        previous: compareData[idx]?.netSales || 0,
                    };
                },
            );

            setHourlyData(transformedHourlyData);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error("Error fetching sales data:", err);
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
        channels,
        providers,
        paymentMethods,
    ]);

    useEffect(() => {
        fetchSalesData();

        return;
    }, [fetchSalesData]);

    // Transform the data to match useSalesMongo shape
    const report = {
        netSales:
            reportData?.salesReport?.reduce(
                (sum, day) => sum + (day.netSales?.total || 0),
                0,
            ) || 0,
        count:
            reportData?.salesReport?.reduce(
                (sum, day) => sum + (day.orders?.total || 0),
                0,
            ) || 0,
    };

    const lastNetSales =
        reportData?.salesReportComparedTo?.reduce(
            (sum, day) => sum + (day.netSales?.total || 0),
            0,
        ) || 0;

    const lastCount =
        reportData?.salesReportComparedTo?.reduce(
            (sum, day) => sum + (day.orders?.total || 0),
            0,
        ) || 0;

    // Calculate percentage changes
    const calculatePercentageChange = (current: number, previous: number) => {
        if (!previous) return 0;
        return Math.round(((current - previous) / previous) * 100);
    };

    const netSalesChange = calculatePercentageChange(
        report.netSales,
        lastNetSales,
    );

    const ordersChange = calculatePercentageChange(report.count, lastCount);

    const currentAvgTicket = !report.netSales
        ? 0
        : report.netSales / report.count;
    const previousAvgTicket = !lastNetSales ? 0 : lastNetSales / lastCount;
    const avgTicketChange = calculatePercentageChange(
        currentAvgTicket,
        previousAvgTicket,
    );

    // Helper function to group data by granularity
    const groupDataByGranularity = useCallback(
        (salesData: SalesReport[]) => {
            if (!salesData?.length) return [];

            const groupedData = new Map<
                string,
                { total: number; date: moment.Moment }
            >();

            salesData.forEach((day) => {
                const date = moment(day.date);
                let key: string;
                let groupDate: moment.Moment;

                switch (granularity) {
                    case Granularity.WEEK:
                        groupDate = date.clone().startOf("week");
                        key = groupDate.format("YYYY-MM-DD");
                        break;
                    case Granularity.MONTH:
                        groupDate = date.clone().startOf("month");
                        key = groupDate.format("YYYY-MM");
                        break;
                    case Granularity.YEAR:
                        groupDate = date.clone().startOf("year");
                        key = groupDate.format("YYYY");
                        break;
                    case Granularity.DAY:
                    default:
                        groupDate = date;
                        key = date.format("YYYY-MM-DD");
                        break;
                }

                if (!groupedData.has(key)) {
                    groupedData.set(key, { total: 0, date: groupDate });
                }

                const current = groupedData.get(key)!;
                current.total += day.netSales.total;
            });

            return Array.from(groupedData.entries())
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .map(([_, value]) => value);
        },
        [granularity],
    );

    // Transform daily aggregate data for chart with granularity support
    const chartData = useMemo(() => {
        const currentPeriodData = groupDataByGranularity(
            reportData?.salesReport || [],
        );
        const comparisonPeriodData = groupDataByGranularity(
            reportData?.salesReportComparedTo || [],
        );

        return currentPeriodData.map((current, index) => {
            let periodLabel: string;

            switch (granularity) {
                case Granularity.WEEK:
                    periodLabel = `Week of ${current.date.format("MMM D")}`;
                    break;
                case Granularity.MONTH:
                    periodLabel = current.date.format("MMM YYYY");
                    break;
                case Granularity.YEAR:
                    periodLabel = current.date.format("YYYY");
                    break;
                case Granularity.DAY:
                default:
                    periodLabel = current.date.format("MMM D");
                    break;
            }

            return {
                period: periodLabel,
                current: current.total,
                previous: comparisonPeriodData[index]?.total || 0,
            };
        });
    }, [
        reportData?.salesReport,
        reportData?.salesReportComparedTo,
        groupDataByGranularity,
    ]);

    return {
        report,
        isLoading,
        error: error ? true : false,
        salesData: reportData?.salesDailyAggregateReport || [],
        lastReport: reportData?.salesReportComparedTo?.[0],
        lastNetSales,
        netSalesChange,
        lastCount,
        ordersChange,
        currentAvgTicket,
        previousAvgTicket,
        avgTicketChange,
        chartData,
        hourlyData,
        refetch: fetchSalesData,
    };
};
